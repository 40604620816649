<template>
<div class="row">
    <div class="col-12">
        <div class="card">
            <!-- Card header -->
            <div class="card-header pb-0">
                <div class="d-lg-flex">
                <div>
                    <h5 class="mb-0">All Student Resource</h5>
                    <p class="text-sm mb-0">
                    Student Resource
                    </p>
                </div>
                </div>
            </div>
            <div class="card-body px-0 pb-0">
                <div class="table-responsive">
                    <div class="dataTable-wrapper dataTable-loading no-footer sortable searchable fixed-columns">
                        <div class="dataTable-top">
                            <div class="dataTable-dropdown">
                                <label><select class="dataTable-selector" v-model="paginationState.size">
                                    <option :value="5">5</option>
                                    <option :value="10">10</option>
                                    <option :value="15">15</option>
                                    <option :value="20">20</option>
                                    <option :value="25">25</option></select>
                                     entries per page</label>
                            </div>
                            <div class="dataTable-search">
                                <input class="dataTable-input mx-4" placeholder="Search..." type="text" v-model="paginationState.keyword">
                                <button type="button" class="btn btn-outline-primary btn-sm mb-0" @click="search">
                                Search
                                </button>
                            </div>
                        </div>
                        <div class="dataTable-container">
                            <table class="table table-flush" id="school-list">
                                <thead class="thead-light">
                                    <tr>
                                    <th>Resource Name</th>
                                    <th>Student</th>
                                    <th>Teacher</th>
                                    <th>Resource Type</th>
                                    <th>score</th>
                                    <th>finish Rate</th>
                                    <th>Action</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    <tr v-for="item in list"  v-bind:key="item">
                                    <td>
                                        <div class="d-flex">
                                        <!-- <div class="form-check my-auto">
                                            <input class="form-check-input" type="checkbox" id="customCheck1" checked>
                                        </div> -->
                                        <!-- <img class="w-10 ms-3" v-bind:src="item.avatar" alt="hoodie"> -->
                                        <h6 class="ms-3 my-auto">{{item.resourceName}}</h6>
                                        </div>
                                    </td>
                                    <td class="text-sm">{{item.studentName}}</td>
                                    <td class="text-sm">{{item.creator.firstName + " " + item.creator.lastName}}</td>
                                    <td class="text-sm text-capitalize">{{item.resourceType == 'normal' ? "Material" : item.resourceType}}</td>
                                    <td class="text-sm">{{getScore(item.score)}}</td>
                                    <td class="text-sm">{{item.finishRate}}</td>
                                    <td class="text-sm">
                                        <a :href="'#/studentResource/' + item.id" data-bs-toggle="tooltip" data-bs-original-title="Preview student resource">
                                        <i class="fas fa-eye text-secondary"></i>
                                        </a>
                                        
                                        <a href="javascript:;" data-bs-toggle="tooltip" class="mx-2" v-b-modal.modalDeleteConfirm data-bs-original-title="Remove student resource" @click="setDeleteId(item.id)">
                                        <i class="fas fa-trash text-secondary"></i>
                                        </a>
                                    </td>
                                    </tr>
                                    <tr v-if="total == 0">
                                        <td class="dataTables-empty" colspan="7">No entries found</td>
                                    </tr>
                                </tbody>
                            </table>
                        </div>
                        <div class="dataTable-bottom" v-if="total > paginationState.size">
                        <div class="dataTable-info">
                            Showing {{(paginationState.page - 1) * paginationState.size + 1}} to {{paginationState.page * paginationState.size > total ? total : paginationState.page * paginationState.size}} of {{total}} entries
                        </div>
                        <nav class="dataTable-pagination">

                        <b-pagination
                        v-model="paginationState.page"
                        :total-rows="total"
                        :per-page="parseInt(paginationState.size)"
                        aria-controls="my-table"
                    ></b-pagination>
                        </nav>
                    </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
  <b-modal id="modalDeleteConfirm" centered @ok="deleteStudentResource(deleteId)">Please confirm whether to delete the student resource.</b-modal>
</div>
</template>

<script>
import { Dashboard } from '@/apiClient/Dashboard'
import { onMounted,ref,getCurrentInstance,watch,reactive } from 'vue';
import bus from '@/plugins/bus';
import json from '@/plugins/category.json'
let requestHeader;
export default {
    name:"studentResource",
    data() {
        return {
          deleteId:0,
          json:json
        }
    },
    methods:{
      deleteStudentResource(id){
        let api = new Dashboard();
        let params = { id: id};
        api.removeStudentResourceByIdUsingPost(params,requestHeader).then((response) => {
            if(response.status == 200 && response.data != null){
              if(response.data.code == 0){
                bus.emit('success', response.data.message);
              }
              else{
                bus.emit('error', response.data.message);
              }
            }
        }).catch((error) => { console.log(error); });
      },
      setDeleteId(id){
        this.deleteId = id;
      },
      reload(){
        this.$router.go()
      },
      getScore(key){
        if(key == null || key > 15){
            return "N/A"
        }
        return this.json.studentResourceScore[key + ""];
      }
    },
    setup(){
        const list=ref();
        const total = ref(0);
        const paginationState = reactive({
            size: 10,
            page: 1,
            keyword:""
        });
        requestHeader = getCurrentInstance().appContext.config.globalProperties.RequestParams
        const search = () =>{
            if(paginationState.page == 1){
                call();
            }
            else{
                paginationState.page = 1;
            }
        }
        const call = () =>{
            bus.emit('load',true);
            let api = new Dashboard();
            let params = { keyword:paginationState.keyword,page:paginationState.page - 1,size:paginationState.size };
            api.getAllStudentResourceUsingGet(params,requestHeader).then((response) => {
                if(response.status == 200 && response.data != null){
                    if(response.data.code == 0){
                        list.value= response.data?.data?.content;
                        total.value = parseInt(response.data?.data.totalElements)
                    }
                    else{
                        bus.emit('error', response.data.message);
                    }
                }
            }).catch((error) => { bus.emit('error', error); }).finally(()=>{ bus.emit('load', false); });
        }
        onMounted(()=>{
            call();
        });
        watch(()=> paginationState.page,()=>{
            call();
        },
        {
            deep:true
        });
         watch(()=> [paginationState.size],()=>{
            paginationState.page = 1;
            call();
        },
        {
            deep:true
        });
        return{
            paginationState,
            list,
            total,
            search
        }
    }
}
</script>