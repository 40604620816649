<template>
    <div class="container-fluid py-4">
      <div class="row">
        <div class="col-12">
          <div class="card">
            <div class="card-body">
              <h5 class="mb-4">School Details</h5>
              <div class="row">
                <div class="col-lg-12 mx-auto">
                  <div class="row align-items-center">
                    <div class="col-sm-auto col-4">
                      <div>
                        <img v-if="school.logo" :src="school.logo + '?size=80x80'" class="avatar rounded-circle avatar-xl position-relative">
                        <span v-else-if="!school.logo" class="bg-primary rounded-circle avatar text-uppercase"><span class="text-light">{{getWord(school.schoolName)}}</span></span>
                      </div>
                    </div>
                    <div class="col-sm-auto col-8 my-auto">
                      <div class="h-100">
                          <h5 class="mb-1 font-weight-bolder">
                          {{school.schoolName}}
                          </h5>
                      </div>
                    </div>
                    <div class="row mt-4">
                        
                        <div class="col-lg-12 mt-lg-0 mt-2" v-html="school.schoolDescription">
                        </div>
                    </div>
                  </div>
                </div>
                <!-- <div class="col-lg-5 mx-auto">
                  <h3 class="mt-lg-0 mt-4">{{school.schoolName}}</h3>
                  <label class="mt-4">Description</label>
                    <div class="row mt-4">
                        <div class="col-lg-12 mt-lg-0 mt-2" v-html="school.schoolDescription">
                        </div>
                    </div>
                </div> -->
              </div>
              <div class="row mt-5">
                <div class="col-12">
                  <h5 class="ms-3">Instructors List</h5>
                  <div class="table table-responsive">
                    <table class="table align-items-center mb-0">
                      <thead>
                        <tr>
                          <th class="text-uppercase text-secondary text-xxs font-weight-bolder opacity-7">name</th>
                          <th class="text-uppercase text-secondary text-xxs font-weight-bolder opacity-7 ps-2">email</th>
                          <th class="text-uppercase text-secondary text-xxs font-weight-bolder opacity-7 ps-2">Profession</th>
                          <th class="text-uppercase text-secondary text-xxs font-weight-bolder opacity-7 ps-2">Phone</th>
                          <th class="text-uppercase text-secondary text-xxs font-weight-bolder opacity-7 ps-2">Country</th>
                        </tr>
                      </thead>
                      <tbody>
                        <tr v-for="item in teachers" v-bind:key="item">
                          <td>
                            <div class="d-flex px-2 py-1">
                              <div>
                                <img v-bind:src="item.avatar || require('@/assets/img/favicon.png')" class="avatar avatar-md me-3" alt="table image">
                              </div>
                              <div class="d-flex flex-column justify-content-center">
                                <h6 class="mb-0 text-sm">{{item.fullNameOrAccountName}}</h6>
                              </div>
                            </div>
                          </td>
                          <td>
                            <p class="text-sm text-secondary mb-0">{{item.email}}</p>
                          </td>
                          <td>
                            <p class="text-sm text-secondary mb-0">{{item.profession}}</p>
                          </td>
                          <td>
                            <p class="text-sm text-secondary mb-0">{{item.phone}}</p>
                          </td>
                          <td>
                            <p class="text-sm text-secondary mb-0">{{item.country}}</p>
                          </td>
                        </tr>
                      </tbody>
                    </table>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
</template>
<script>
import { Dashboard } from '@/apiClient/Dashboard'
import { defineComponent,onMounted,ref,getCurrentInstance } from 'vue';
import { useRouter } from "vue-router";
import bus from '@/plugins/bus';

let requestParams;
let schoolId;
export default defineComponent({
  name: 'schoolDetail',
  methods: {
      getWord(f){
        f = f ? f : "";
        let arr = f.split(' ');
        let first = arr ? arr[0].slice(0,1) : "";
        let last = arr[1] ? arr[1].slice(0,1) : "";
        return first + last;
      },
  },
  setup(){
    const school=ref({});
    const teachers = ref();
    requestParams = getCurrentInstance().appContext.config.globalProperties.RequestParams;
    const router = useRouter();
    schoolId = router.currentRoute.value.params.id;
    onMounted(()=>{
      let api = new Dashboard();
      let params = { schoolId: schoolId };

      api.getSchoolByIdUsingGet(params,requestParams).then(rep=>{
        if(rep.status == 200 && rep.data != null){
          if(rep.data.code == 0){
            let data = rep.data.data;
            school.value = data;
          }
          else{
            bus.emit('error', rep.data.message);
          }
        }
      }).catch(function (error) {
          bus.emit('error', error);
      });

      
      api.getTeachersBySchoolIdUsingGet(params,requestParams).then(rep=>{
        if(rep.status == 200 && rep.data != null){
          if(rep.data.code == 0){
            let data = rep.data.data;
            teachers.value = data.content;
          }
          else{
            bus.emit('error', rep.data.message);
          }
        }
      }).catch(function (error) {
          bus.emit('error', error);
      });
      
  })
    return{
      school,
      teachers
    }
 
  }
});
</script>