<template>
    <div>
      <div class="row">
        <div class="col-lg-12">
          <div class="row">
            <div class="col-lg-4 col-md-6 col-12">
              <b-card no-body class="mb-4">
                <b-card-body body-class="p3">
                   <b-row>
                     <b-col md="8">
                      <div class="numbers">
                       <p class="text-sm mb-0 text-uppercase font-weight-bold">Today's new courses</p>
                        <h5 class="font-weight-bolder">
                          +{{coursesTodayList.totalElements}}
                        </h5>
                      </div>
                     </b-col>
                     <b-col class="text-end">
                      <div class="icon icon-shape bg-gradient-danger shadow-danger text-center rounded-circle">
                        <i class="ni ni-money-coins text-lg opacity-10" aria-hidden="true"></i>
                      </div>
                     </b-col>
                   </b-row>
                </b-card-body>
              </b-card>
            </div>
            <div class="col-lg-4 col-md-6 col-12">
              <b-card no-body class="mb-4">
                <b-card-body body-class="p3">
                   <b-row>
                     <b-col md="8">
                      <div class="numbers">
                       <p class="text-sm mb-0 text-uppercase font-weight-bold">Today's new learnspace courses</p>
                        <h5 class="font-weight-bolder">
                          +{{newLearnSpaceClassesTodayList.totalElements}}
                        </h5>
                      </div>
                     </b-col>
                     <b-col class="text-end">
                      <div class="icon icon-shape bg-gradient-danger shadow-danger text-center rounded-circle">
                        <i class="ni ni-world text-lg opacity-10" aria-hidden="true"></i>
                      </div>
                     </b-col>
                   </b-row>
                </b-card-body>
              </b-card>
            </div>
            <div class="col-lg-4 col-md-6 col-12">
              <b-card no-body class="mb-4">
                <b-card-body body-class="p3">
                   <b-row>
                     <b-col md="8">
                      <div class="numbers">
                       <p class="text-sm mb-0 text-uppercase font-weight-bold">Week's new courses</p>
                        <h5 class="font-weight-bolder">
                          +{{coursesWeekList.totalElements}}
                        </h5>
                      </div>
                     </b-col>
                     <b-col class="text-end">
                      <div class="icon icon-shape bg-gradient-danger shadow-danger text-center rounded-circle">
                        <i class="ni ni-paper-diploma text-lg opacity-10" aria-hidden="true"></i>
                      </div>
                     </b-col>
                   </b-row>
                </b-card-body>
              </b-card>
            </div>
          </div>
        </div>
      </div>

      <div class="row mt-4">
        <div class="col-lg-4 col-md-6 mb-4 mb-lg-0">
          <b-card no-body class="h-100">
            <b-card-header>
              <b-row>
                <b-col md="10">
                  <h5 class="mb-0 text-capitalize">Today active teachers</h5>
                </b-col>
                <b-col md="2">
                  <b-badge><a href="#/activeTeacher" target="_blank">{{teacherList?.totalElements}}</a></b-badge>
                </b-col>
              </b-row>
            </b-card-header>
            <b-card-body>
              <b-avatar-group class="avatar-group">
                <b-link  :href="'#/userProfile/' + item.id"  v-for="item in teacherList?.content"  v-bind:key="item.id" :title="item.fullNameOrAccountName">
                 <img v-if="item.avatar" alt="Image placeholder" :src="item.avatar + '?size=80x80'" class="avatar rounded-circle">
                  <span v-if="!item.avatar" class="b-avatar bg-primary rounded-circle avatar text-uppercase"><span class="b-avatar-text text-light">{{getWord(item.fullNameOrAccountName)}}</span></span>
                </b-link>
              </b-avatar-group>
            </b-card-body>
          </b-card>
        </div>
        <div class="col-lg-4 col-md-6 mb-4 mb-lg-0">
          <b-card no-body class="h-100">
            <b-card-header>
              <b-row>
                <b-col md="10">
                  <h5 class="mb-0 text-capitalize">Today active students</h5>
                </b-col>
                <b-col md="2">
                  <b-badge><a href="#/activeStudent" target="_blank">{{studentList?.totalElements}}</a></b-badge>
                </b-col>
              </b-row>
            </b-card-header>
            <b-card-body>
              <b-avatar-group class="avatar-group">
                <b-link  :href="'#/userProfile/' + item.id"  v-for="item in studentList?.content"  v-bind:key="item.id" :title="item.fullNameOrAccountName">
                 <img v-if="item.avatar" alt="Image placeholder" :src="item.avatar + '?size=80x80'" class="avatar rounded-circle">
                  <span v-if="!item.avatar" class="b-avatar bg-primary rounded-circle avatar text-uppercase"><span class="b-avatar-text text-light">{{getWord(item.fullNameOrAccountName)}}</span></span>
                </b-link>
              </b-avatar-group>
            </b-card-body>
          </b-card>
        </div>
        <div class="col-lg-4 col-md-6 mb-4 mb-lg-0">
          <b-card no-body class="h-100">
            <b-card-header>
              <b-row>
                <b-col md="10">
                  <h5 class="mb-0 text-capitalize">Week signup students</h5>
                </b-col>
                <b-col md="2">
                  <b-badge><a href="#/weekSignupStudent" target="_blank">{{studentWeekSignupList?.totalElements}}</a></b-badge>
                </b-col>
              </b-row>
            </b-card-header>
            <b-card-body>
              <b-avatar-group class="avatar-group">
                <b-link  :href="'#/userProfile/' + item.id"  v-for="item in studentWeekSignupList?.content"  v-bind:key="item.id" :title="item.fullNameOrAccountName">
                 <img v-if="item.avatar" alt="Image placeholder" :src="item.avatar + '?size=80x80'" class="avatar rounded-circle">
                  <span v-if="!item.avatar" class="b-avatar bg-primary rounded-circle avatar text-uppercase"><span class="b-avatar-text text-light">{{getWord(item.fullNameOrAccountName)}}</span></span>
                </b-link>
              </b-avatar-group>
            </b-card-body>
          </b-card>
        </div>
      </div>
      <div class="row mt-4">
        <div class="col-lg-4 col-md-6 mb-4 mb-lg-0">
          <b-card no-body class="h-100">
            <b-card-header>
              <b-row>
                <b-col md="10">
                  <h5 class="mb-0 text-capitalize">Today signup students</h5>
                </b-col>
                <b-col md="2">
                  <b-badge><a href="#/signupStudent" target="_blank">{{studentTodaySignupList?.totalElements}}</a></b-badge>
                </b-col>
              </b-row>
            </b-card-header>
            <b-card-body>
              <b-avatar-group class="avatar-group">
                <b-link  :href="'#/userProfile/' + item.id"  v-for="item in studentTodaySignupList?.content"  v-bind:key="item.id" :title="item.fullNameOrAccountName">
                 <img v-if="item.avatar" alt="Image placeholder" :src="item.avatar + '?size=80x80'" class="avatar rounded-circle">
                  <span v-if="!item.avatar" class="b-avatar bg-primary rounded-circle avatar text-uppercase"><span class="b-avatar-text text-light">{{getWord(item.fullNameOrAccountName)}}</span></span>
                </b-link>
              </b-avatar-group>
            </b-card-body>
          </b-card>
        </div>
        <div class="col-lg-4 col-md-6 mb-4 mb-lg-0">
          <b-card no-body class="h-100">
            <b-card-header>
              <b-row>
                <b-col md="10">
                  <h5 class="mb-0 text-capitalize">Today signup teachers</h5>
                </b-col>
                <b-col md="2">
                  <b-badge><a href="#/signupTeacher" target="_blank">{{teacherTodaySignupList?.totalElements}}</a></b-badge>
                </b-col>
              </b-row>
            </b-card-header>
            <b-card-body>
              <b-avatar-group class="avatar-group">
                <b-link  :href="'#/userProfile/' + item.id"  v-for="item in teacherTodaySignupList?.content"  v-bind:key="item.id" :title="item.fullNameOrAccountName">
                 <img v-if="item.avatar" alt="Image placeholder" :src="item.avatar + '?size=80x80'" class="avatar rounded-circle">
                  <span v-if="!item.avatar" class="b-avatar bg-primary rounded-circle avatar text-uppercase"><span class="b-avatar-text text-light">{{getWord(item.fullNameOrAccountName)}}</span></span>
                </b-link>
              </b-avatar-group>
            </b-card-body>
          </b-card>
        </div>
        <div class="col-lg-4 col-md-6 mb-4 mb-lg-0">
          <b-card no-body class="h-100">
            <b-card-header>
              <b-row>
                <b-col md="10">
                  <h5 class="mb-0 text-capitalize">Week signup teachers</h5>
                </b-col>
                <b-col md="2">
                  <b-badge><a href="#/weekSignupTeacher" target="_blank">{{teacherWeekSignupList?.totalElements}}</a></b-badge>
                </b-col>
              </b-row>
            </b-card-header>
            <b-card-body>
              <b-avatar-group class="avatar-group">
                    <b-link  :href="'#/userProfile/' + item.id"  v-for="item in teacherWeekSignupList?.content"  v-bind:key="item.id" :title="item.fullNameOrAccountName">
                        <img v-if="item.avatar" alt="Image placeholder" :src="item.avatar + '?size=80x80'" class="avatar rounded-circle">
                        <span v-if="!item.avatar" class="b-avatar bg-primary rounded-circle avatar text-uppercase"><span class="b-avatar-text text-light">{{getWord(item.fullNameOrAccountName)}}</span></span>
                    </b-link>
                </b-avatar-group>
            </b-card-body>
          </b-card>
        </div>
      </div>
      
    </div>
  
</template>
<script>
import { Dashboard } from '@/apiClient/Dashboard'
import BCard from 'bootstrap-vue-3/src/components/BCard/BCard.vue';
import { defineComponent,onMounted,ref,getCurrentInstance } from 'vue';
import bus from '@/plugins/bus';

let requestParams;

export default defineComponent({
  components: { BCard },
  name: "dashboardView",
  methods:{
    getWord(f){
      f = f ? f : "";
      let arr = f.split(' ');
      let first = arr ? arr[0].slice(0,1) : "";
      let last = arr[1] ? arr[1].slice(0,1) : "";
      return first + last;
    }
  },
  setup(){
    const teacherList=ref();
    const studentList=ref();
    const studentTodaySignupList=ref();
    const studentWeekSignupList=ref();
    const teacherTodaySignupList=ref();
    const teacherWeekSignupList=ref();
    const coursesTodayList=ref({});
    const coursesWeekList=ref({});
    const newLearnSpaceClassesTodayList=ref({});
    requestParams = getCurrentInstance().appContext.config.globalProperties.RequestParams;
    onMounted(()=>{
      let api = new Dashboard();
      let params = {size:20,page:0};
      api.getActiveStudentTodayUsingPost(params,requestParams).then(rep=>{
        if(rep.status == 200 && rep.data != null){
          if(rep.data.code == 0){
            let data = rep.data.data;
            studentList.value=data;
          }
        }
        else{
          console.log('login failed');
        }
      }).catch(function (error) {
          bus.emit('error', error);
      });
      
      api.getActiveTeacherTodayUsingPost(params,requestParams).then(rep=>{
        if(rep.status == 200 && rep.data != null){
          if(rep.data.code == 0){
            let data = rep.data.data;
            teacherList.value= data;
          }
        }
        else{
          console.log('login failed');
        }
      }).catch(function (error) {
          bus.emit('error', error);
      });

      
      api.getSignUpStudentTodayUsingPost(params,requestParams).then(rep=>{
        if(rep.status == 200 && rep.data != null){
          if(rep.data.code == 0){
            let data = rep.data?.data;
            studentTodaySignupList.value=data;
          }
        }
        else{
          console.log('login failed');
        }
      }).catch(function (error) {
          bus.emit('error', error);
      });

      
      api.getSignUpStudentWeekUsingPost(params,requestParams).then(rep=>{
        if(rep.status == 200 && rep.data != null){
          if(rep.data.code == 0){
            let data = rep.data?.data;
            studentWeekSignupList.value=data;
          }
        }
        else{
          console.log('login failed');
        }
      }).catch(function (error) {
          bus.emit('error', error);
      });
      api.getSignUpTeacherTodayUsingPost(params,requestParams).then(rep=>{
        if(rep.status == 200 && rep.data != null){
          if(rep.data.code == 0){
            let data = rep.data.data;
            teacherTodaySignupList.value=data;
          }
        }
        else{
          console.log('login failed');
        }
      }).catch(function (error) {
          bus.emit('error', error);
      });
      api.getSignUpTeacherWeekUsingPost(params,requestParams).then(rep=>{
        if(rep.status == 200 && rep.data != null){
          if(rep.data.code == 0){
            let data = rep.data.data;
            teacherWeekSignupList.value=data;
          }
        }
        else{
          console.log('login failed');
        }
      }).catch(function (error) {
          bus.emit('error', error);
      });

      
      api.getNewCoursesTodayUsingGet(params,requestParams).then(rep=>{
        if(rep.status == 200 && rep.data != null){
          if(rep.data.code == 0){
            let data = rep.data.data;
                  coursesTodayList.value=data;
          }
        }
        else{
          console.log('login failed');
        }
      }).catch(function (error) {
          bus.emit('error', error);
      });

      
      api.getNewCoursesWeekUsingGet(params,requestParams).then(rep=>{
        if(rep.status == 200 && rep.data != null){
          if(rep.data.code == 0){
            let data = rep.data?.data;
                  coursesWeekList.value=data;
          }
        }
        else{
          console.log('login failed');
        }
      }).catch(function (error) {
          bus.emit('error', error);
      });

      api.getNewLearnSpaceClassesByTodayUsingGet(params,requestParams).then(rep=>{
        if(rep.status == 200 && rep.data != null){
          if(rep.data.code == 0){
            let data = rep.data?.data;
            newLearnSpaceClassesTodayList.value=data;
          }
        }
        else{
          console.log('login failed');
        }
      }).catch(function (error) {
          bus.emit('error', error);
      });
    })
    return{
      teacherList,
      studentList,
      studentTodaySignupList,
      studentWeekSignupList,
      teacherTodaySignupList,
      teacherWeekSignupList,
      coursesTodayList,
      coursesWeekList,
      newLearnSpaceClassesTodayList
    }
  }
});
</script>
<style></style>

