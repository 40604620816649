<template>
<div class="row">
    <div class="col-12">
        <div class="card">
            <!-- Card header -->
            <div class="card-header pb-0">
                <div class="d-lg-flex">
                <div>
                    <h5 class="mb-0">All School</h5>
                    <p class="text-sm mb-0">
                    School list
                    </p>
                </div>
                </div>
            </div>
            <div class="card-body px-0 pb-0">
                <div class="table-responsive">
                    <div class="dataTable-wrapper dataTable-loading no-footer sortable searchable fixed-columns">
                        <div class="dataTable-top">
                            <div class="dataTable-dropdown">
                                <label><select class="dataTable-selector" v-model="paginationState.size">
                                    <option :value="5">5</option>
                                    <option :value="10">10</option>
                                    <option :value="15">15</option>
                                    <option :value="20">20</option>
                                    <option :value="25">25</option></select>
                                     entries per page</label>
                            </div>
                            <div class="dataTable-search">
                                <input class="dataTable-input mx-4" placeholder="Search..." type="text" v-model="paginationState.keyword">
                                <button type="button" class="btn btn-outline-primary btn-sm mb-0" @click="search">
                                Search
                                </button>
                            </div>
                        </div>
                        <div class="dataTable-container">
                            <table class="table table-flush" id="school-list">
                                <thead class="thead-light">
                                    <tr>
                                    <th>School Name</th>
                                    <th>School analysis</th>
                                    <th>Private School</th>
                                    <th>Custom Domain</th>
                                    <th>create Time</th>
                                    <th>Deleted</th>
                                    <th>Action</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    <tr v-for="item in list"  v-bind:key="item">
                                    <td>
                                        <div class="d-flex">
                                        <!-- <div class="form-check my-auto">
                                            <input class="form-check-input" type="checkbox" id="customCheck1" checked>
                                        </div> -->
                                        <!-- <img class="w-10 ms-3" v-bind:src="item.avatar" alt="hoodie"> -->
                                        <h6 class="ms-3 my-auto">{{item.schoolName}}</h6>
                                        </div>
                                    </td>
                                    <td class="text-sm" v-html="getSchoolAnalysis(item.statisticsData)"></td>
                                    <td class="text-sm">{{item.privateSchool}}</td>
                                    <td class="text-sm">{{item.customDomain}}</td>
                                    <td class="text-sm">{{item.createTime}}</td>
                                    <td>
                                        <span class="badge badge-sm" :class="item.deleted ? 'badge-danger' : 'badge-success'">{{item.deleted ? "Yes" : "No"}}</span>
                                    </td>
                                    <td class="text-sm">
                                        <a :href="'#/school/' + item.id" data-bs-toggle="tooltip" data-bs-original-title="Preview school">
                                        <i class="fas fa-eye text-secondary"></i>
                                        </a>
                                        <a :href="'#/school/analysis/' + item.id" class="mx-3" data-bs-toggle="tooltip" data-bs-original-title="Preview school">
                                        <i class="ni ni-chart-bar-32"></i>
                                        </a>
                                        <a :href="'#/schoolOwner/' + item.creator" data-bs-toggle="tooltip" data-bs-original-title="Preview owner">
                                        <i class="fas fa-user-edit text-secondary"></i>
                                        </a>
                                        <a href="javascript:;" data-bs-toggle="tooltip" class="mx-3" v-b-modal.modalDeleteConfirm data-bs-original-title="Remove school" @click="setDeleteId(item.id)">
                                        <i class="fas fa-trash text-secondary"></i>
                                        </a>
                                    </td>
                                    </tr>
                                    <tr v-if="total == 0">
                                        <td class="dataTables-empty" colspan="7">No entries found</td>
                                    </tr>
                                </tbody>
                            </table>
                        </div>
                        <div class="dataTable-bottom" v-if="total > paginationState.size">
                        <div class="dataTable-info">
                            Showing {{(paginationState.page - 1) * paginationState.size + 1}} to {{paginationState.page * paginationState.size > total ? total : paginationState.page * paginationState.size}} of {{total}} entries
                        </div>
                        <nav class="dataTable-pagination">

                        <b-pagination
                        v-model="paginationState.page"
                        :total-rows="total"
                        :per-page="parseInt(paginationState.size)"
                        aria-controls="my-table"
                    ></b-pagination>
                        </nav>
                    </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
  <b-modal id="modalDeleteConfirm" centered @ok="deleteSchool(deleteId)">Please confirm whether to delete the school.</b-modal>
</div>
</template>

<script>
import { Dashboard } from '@/apiClient/Dashboard'
import { onMounted,ref,getCurrentInstance,watch,reactive } from 'vue';
import bus from '@/plugins/bus';
let requestHeader;
export default {
    name:"schoolList",
    data() {
        return {
          deleteId:0
        }
    },
    methods:{
        getSchoolAnalysis(statisticsData){
            if(!statisticsData) return "";
            var storage = "Used storage: " + this.formatSize(statisticsData.usedStorage);
            var video = "Video duration (watched / Total): " + this.formatToHours(statisticsData.playedDuration) + " / " + this.formatToHours(statisticsData.duration);
            var cost = "AWS Cost: " + (statisticsData.playedDuration != 0 ? (statisticsData.playedDuration * 0.35 * 0.085 / 3600).toFixed(2) + " USD" : "0");
            return storage + "<br/>" + video + "<br/>" + cost;
        },
      formatToHours(seconds){
        if (isNaN(seconds)) {
            return '';
        }
        let result = parseInt(seconds)
        let symbols = ['s', 'm', 'h'];
        let h = result / 3600;
        if (h >= 1) {
           return h.toFixed(2) + ' ' + symbols[2]
        }
        let m = Math.floor((result / 60 % 60));
        let s = Math.floor((result % 60));
        let res = '';
        if(m !== 0) res += `${m}${symbols[1]} `;
        res += `${s}${symbols[0]}`;
        return res;
      },
       formatSeconds(value) {
        let result = parseInt(value)
        //let d = Math.floor(result / (3600 * 24));
        let h = Math.floor(result / 3600);
        let m = Math.floor((result / 60 % 60));
        let s = Math.floor((result % 60));
        let res = '';
        //if(d !== 0) res += `${d}d `;
        if(h !== 0) res += `${h}h `;
        if(m !== 0) res += `${m}min `;
        res += `${s}s`;
        return res;
      },
      formatSize(bytes) {
        if (isNaN(bytes)) {
            return '';
        }
        let symbols = ['B', 'KB', 'MB', 'GB', 'TB', 'PB', 'EB', 'ZB', 'YB'];
        let exp = Math.floor(Math.log(bytes)/Math.log(2));
        if (exp < 1) {
            exp = 0;
        }
        let i = Math.floor(exp / 10);
        bytes = bytes / Math.pow(2, 10 * i);

        if (bytes.toString().length > bytes.toFixed(2).toString().length) {
            bytes = bytes.toFixed(2);
        }
        return bytes + ' ' + symbols[i];
      },
      deleteSchool(id){
        let api = new Dashboard();
        let params = { schoolId: id};
        api.removeSchoolBySchoolIdUsingPost(params,requestHeader).then((response) => {
            if(response.status == 200 && response.data != null){
              if(response.data.code == 0){
                bus.emit('success', response.data.message);
              }
              else{
                bus.emit('error', response.data.message);
              }
            }
        }).catch((error) => { bus.emit('error',error); });
      },
      setDeleteId(id){
        this.deleteId = id;
      },
      reload(){
        this.$router.go()
      }
    },
    setup(){
        const list=ref();
        const total = ref(0);
        const paginationState = reactive({
            size: 10,
            page: 1,
            keyword:""
        });
        requestHeader = getCurrentInstance().appContext.config.globalProperties.RequestParams
        const search = () =>{
            if(paginationState.page == 1){
                call();
            }
            else{
                paginationState.page = 1;
            }
        }
        const call = () =>{
            bus.emit('load',true);
            let api = new Dashboard();
            let params = { keyword:paginationState.keyword,page:paginationState.page - 1,size:paginationState.size };
            api.getAllSchoolByPageUsingGet(params,requestHeader).then((response) => {
                if(response.status == 200 && response.data != null){
                    if(response.data.code == 0){
                        list.value= response.data?.data?.content;
                        total.value = parseInt(response.data?.data.totalElements)
                    }
                    else{
                        bus.emit('error', response.data.message);
                    }
                }
            }).catch((error) => { bus.emit('error', error); }).finally(()=>{ bus.emit('load', false); });
        }
        onMounted(()=>{
            call();
        });
        watch(()=> paginationState.page,()=>{
            call();
        },
        {
            deep:true
        });
         watch(()=> [paginationState.size],()=>{
            if(paginationState.page == 1)
                call();
            else
                paginationState.page = 1;
        },
        {
            deep:true
        });
        return{
            paginationState,
            list,
            total,
            search
        }
    }
}
</script>