<template>
<div class="row">
    <div class="col-12">
        <div class="card">
            <!-- Card header -->
            <div class="card-header pb-0">
                <div class="d-lg-flex">
                <div>
                    <h5 class="mb-0">Top 10 Courses order by students enrolled via marketplace</h5>
                    <p class="text-sm mb-0">
                    Course list
                    </p>
                </div>
                </div>
            </div>
            <div class="card-body px-0 pb-0">
                <div class="table-responsive">
                    <div class="dataTable-wrapper dataTable-loading no-footer sortable searchable fixed-columns">
                        <!-- <div class="dataTable-top">
                            <div class="dataTable-dropdown">
                                <label><select class="dataTable-selector" v-model="paginationState.size">
                                    <option :value="5">5</option>
                                    <option :value="10">10</option>
                                    <option :value="15">15</option>
                                    <option :value="20">20</option>
                                    <option :value="25">25</option></select>
                                     entries per page</label>
                            </div>
                            <div class="dataTable-search">
                                <input class="dataTable-input" placeholder="Search..." type="text" v-model="paginationState.keyword">
                            </div>
                        </div> -->
                        <div class="dataTable-container">
                            <table class="table table-flush" id="class-list">
                                <thead class="thead-light">
                                    <tr>
                                    <th>Class Name</th>
                                    <th>School</th>
                                    <th>Teacher</th>
                                    <th>marketplace Students Count</th>
                                    <th>class Schedule Type</th>
                                    <th>Clazz Status</th>
                                    <th>Action</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    <tr v-for="item in list"  v-bind:key="item">
                                    <td>
                                        <div class="d-flex">
                                        <!-- <div class="form-check my-auto">
                                            <input class="form-check-input" type="checkbox" id="customCheck1" checked>
                                        </div> -->
                                        <!-- <img class="w-10 ms-3" v-bind:src="item.avatar" alt="hoodie"> -->
                                        <h6 class="ms-3 my-auto" :title="item.name">{{ item.name.length > 40 ? item.name.slice(0,40) + '...' : item.name}}</h6>
                                        </div>
                                    </td>
                                    <td class="text-sm">{{item.schoolName}}</td>
                                    <td class="text-sm">{{item.teacher?.firstName && item.teacher?.lastName ? item.teacher?.firstName + " " + item.teacher?.lastName : item.teacher?.accountName}}</td>
                                    <td class="text-sm">{{item.enrollStudents }}</td>
                                    <td class="text-sm">{{item.classScheduleType}}</td>
                                    <td>
                                        <span class="badge badge-sm" :class="item.clazzStatus != 'ongoing' ? 'badge-danger' : 'badge-success'">{{item.clazzStatus}}</span>
                                    </td>
                                    <td class="text-sm">
                                        <a :href="'#/course/' + item.id" data-bs-toggle="tooltip" data-bs-original-title="Preview Class">
                                        <i class="fas fa-eye text-secondary"></i>
                                        </a>
                                    </td>
                                    </tr>
                                </tbody>
                            </table>
                        </div>
                        <!-- <div class="dataTable-bottom" v-if="total > paginationState.size">
                        <div class="dataTable-info">
                            Showing {{(paginationState.page - 1) * paginationState.size + 1}} to {{paginationState.page * paginationState.size > total ? total : paginationState.page * paginationState.size}} of {{total}} entries
                        </div>
                        <nav class="dataTable-pagination">

                        <pagination
                        v-model="paginationState.page"
                        :total-rows="total"
                        :per-page="parseInt(paginationState.size)"
                        aria-controls="my-table"
                    ></pagination>
                        </nav>
                    </div> -->
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>
</template>

<script>
import { Dashboard } from '@/apiClient/Dashboard'
import { onMounted,ref,getCurrentInstance,watch,reactive } from 'vue';
import bus from '@/plugins/bus';
export default {
    name:"topEnrolledClassList",
    methods:{
    },
    setup(){
        const list=ref();
        const total = ref(0);
        const paginationState = reactive({
            size: 10,
            page: 1,
            keyword:""
        });
        const requestHeader = getCurrentInstance().appContext.config.globalProperties.RequestParams
        const call = () =>{
            bus.emit('load',true);
            let api = new Dashboard();
            let params = { keyword:paginationState.keyword,page:paginationState.page - 1,size:paginationState.size };
            api.getTopStudentEnrolledLearnSpaceClassesPageUsingGet(params,requestHeader).then((response) => {
                if(response.status == 200 && response.data != null){
                    if(response.data.code == 0){
                        list.value= response.data?.data;
                        total.value = parseInt(response.data?.data.totalElements)
                    }
                    else{
                        bus.emit('error', response.data.message);
                    }
                }
            }).catch((error) => { bus.emit('error', error); }).finally(()=>{ bus.emit('load', false); });
        }
        onMounted(()=>{
            call();
        });
        watch(()=> paginationState.page,()=>{
            call();
        },
        {
            deep:true
        });
         watch(()=> [paginationState.size,paginationState.keyword],()=>{
            paginationState.page = 1;
            call();
        },
        {
            deep:true
        });
        return{
            paginationState,
            list,
            total
        }
    }
}
</script>