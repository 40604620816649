<template>
  <div class="dashboard g-sidenav-show bg-gray-100">
    <div class="min-height-300 bg-primary position-absolute w-100"></div>
    <side-bar>
    </side-bar>
     <main class="main-content position-relative border-radius-lg ">
      <dashboard-navbar></dashboard-navbar>
      <div class="container-fluid py-4">
        
        <b-overlay
          id="overlay-background"
          :show="showLoading"
          rounded="sm"
        >
        <!-- your content here -->
        <router-view></router-view>
        </b-overlay>
        <content-footer></content-footer>
      </div>

    </main>
  </div>
</template>
<script>
import SideBar from "../components/SidebarPlugin/SideBar.vue";
import DashboardNavbar from "./DashboardNavbar.vue";
import {ref,onBeforeUnmount} from 'vue';
import ContentFooter from "./ContentFooter.vue";
import bus from '@/plugins/bus';


export default {
  components: {
    DashboardNavbar,
    ContentFooter,
    SideBar
  },
  data() {
    return {
    };
  },
  methods: {
    
  },
  setup(){
    const showLoading = ref(false);
    const load = (value) => {
      showLoading.value = value;
    }


    // 启用监听
    bus.on('load', load);
    // 在组件卸载之前移除监听
    onBeforeUnmount( () => {
      bus.off('load', load);
    })
    
    return{
      showLoading
    }
  },
  mounted() {
      let fontAwesome = document.createElement('script')
      fontAwesome.setAttribute('src', 'https://kit.fontawesome.com/42d5adcbca.js')
      document.head.appendChild(fontAwesome)
      let popper = document.createElement('script')
      popper.setAttribute('src', 'https://demos.creative-tim.com/argon-dashboard-pro/assets/js/core/popper.min.js')
      let bootstrap = document.createElement('script')
      bootstrap.setAttribute('src', 'https://demos.creative-tim.com/argon-dashboard-pro/assets/js/core/bootstrap.min.js')
      let perfect = document.createElement('script')
      perfect.setAttribute('src', 'https://demos.creative-tim.com/argon-dashboard-pro/assets/js/plugins/perfect-scrollbar.min.js')
      let scrollbar = document.createElement('script')
      scrollbar.setAttribute('src', 'https://demos.creative-tim.com/argon-dashboard-pro/assets/js/plugins/smooth-scrollbar.min.js')
      let table = document.createElement('script')
      table.setAttribute('src', 'https://demos.creative-tim.com/argon-dashboard-pro/assets/js/plugins/datatables.js')
      let dragula = document.createElement('script')
      dragula.setAttribute('src', 'https://demos.creative-tim.com/argon-dashboard-pro/assets/js/plugins/dragula/dragula.min.js')
      let jkanban = document.createElement('script')
      jkanban.setAttribute('src', 'https://demos.creative-tim.com/argon-dashboard-pro/assets/js/plugins/jkanban/jkanban.js')
      let leaflet = document.createElement('script')
      leaflet.setAttribute('src', 'https://demos.creative-tim.com/argon-dashboard-pro/assets/js/plugins/leaflet.js')
      let nouislider = document.createElement('script')
      nouislider.setAttribute('src', 'https://demos.creative-tim.com/argon-dashboard-pro/assets/js/plugins/nouislider.min.js')
      let buttons = document.createElement('script')
      buttons.setAttribute('src', 'https://buttons.github.io/buttons.js')
      let argon = document.createElement('script')
      argon.setAttribute('src', 'https://demos.creative-tim.com/argon-dashboard-pro/assets/js/argon-dashboard.min.js?v=2.0.1')
      
      document.body.appendChild(popper)
      document.body.appendChild(bootstrap)
      document.body.appendChild(perfect)
      document.body.appendChild(scrollbar)
      document.body.appendChild(table)
      document.body.appendChild(dragula)
      document.body.appendChild(jkanban)
      document.body.appendChild(leaflet)
      document.body.appendChild(nouislider)
      document.body.appendChild(buttons)
      document.body.appendChild(argon)
    },
};
</script>
<style>
@import url('https://fonts.googleapis.com/css?family=Open+Sans:300,400,600,700');
</style>
