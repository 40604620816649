<template>
<main class="main-content  mt-0">
    <section>
      <div class="page-header min-vh-100">
        <div class="container">
          <div class="row">
            <div class="col-xl-4 col-lg-5 col-md-7 d-flex flex-column mx-lg-0 mx-auto">
              <div class="card card-plain">
                <div class="card-header pb-0 text-start">
                  <h4 class="font-weight-bolder">Sign In</h4>
                  <p class="mb-0">Enter your email and password to sign in</p>
                </div>
                <div class="card-body">
                  <form role="form" :model="loginFormState" >
                    <div class="mb-3">
                      <input type="email" class="form-control form-control-lg" placeholder="Email" aria-label="Email" v-model="loginFormState.email">
                    </div>
                    <div class="mb-3">
                      <input type="password" class="form-control form-control-lg" placeholder="Password" aria-label="Password" v-model="loginFormState.password">
                    </div>
                    <div class="form-check form-switch">
                      <input class="form-check-input" type="checkbox" id="rememberMe">
                      <label class="form-check-label" for="rememberMe">Remember me</label>
                    </div>
                    <div class="text-center">
                      <button type="button" class="btn btn-lg btn-primary btn-lg w-100 mt-4 mb-0" @click="handleLogin">Sign in</button>
                    </div>
                  </form>
                </div>
                <!-- <div class="card-footer text-center pt-0 px-lg-2 px-1">
                  <p class="mb-4 text-sm mx-auto">
                    Don't have an account?
                    <a href="javascript:;" class="text-primary text-gradient font-weight-bold">Sign up</a>
                  </p>
                </div> -->
              </div>
            </div>
            <div class="col-6 d-lg-flex d-none h-100 my-auto pe-0 position-absolute top-0 end-0 text-center justify-content-center flex-column">
              <div class="position-relative bg-gradient-primary h-100 m-3 px-7 border-radius-lg d-flex flex-column justify-content-center overflow-hidden" style="background-image: url('https://raw.githubusercontent.com/creativetimofficial/public-assets/master/argon-dashboard-pro/assets/img/signin-ill.jpg');
          background-size: cover;">
                <span class="mask bg-gradient-primary opacity-6"></span>
                <h4 class="mt-5 text-white font-weight-bolder position-relative">"Attention is the new currency"</h4>
                <p class="text-white position-relative">The more effortless the writing looks, the more effort the writer actually put into the process.</p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  <b-modal v-model="loginFormState.modalShow" ok-only centered>{{loginFormState.message}}</b-modal>
  </main>
</template>
<script>
import { Dashboard } from '../apiClient/Dashboard'
import { getCurrentInstance, reactive, ref } from "vue";
import { useStore } from "vuex";

export default {
  name: "loginView",
   setup() {
        const store = useStore();
        const loginFormRef = ref();

        const loginFormState = reactive({
            email: "",
            password: "",
            message:'',
            modalShow: false
        });
        const requestHeader = getCurrentInstance().appContext.config.globalProperties.RequestParams
        const handleLogin = () => {
                loginFormState.loading = true;

                let params = { accountName: loginFormState.email, password: loginFormState.password };
                let api = new Dashboard();
                api.loginUsingPost(params,requestHeader).then(rep =>{
                  if(rep.status == 200 && rep.data != null){
                    if(rep.data.code == 0 && rep.data.data !== null){
                        let data = rep.data.data;
                        localStorage.setItem("token", data?.token || '');
                        localStorage.setItem("userId", data?.userId?.toString() || '');
                        localStorage.setItem("Flag", "isLogin");
                        localStorage.setItem("auth", data?.authorities);
                        store.dispatch("userLogin", true);
                        loginFormState.loading = false;
                        window.location.href = "/";
                    }
                    else{
                      loginFormState.modalShow = true;
                      loginFormState.message = rep.data.message;
                    }
                  }
                }).catch(function (error) {
                    if (error) {
                        loginFormState.modalShow = true;
                        loginFormState.message = error;
                    }
                });
        };

        return { loginFormRef, loginFormState, handleLogin };
    }
};
</script>
<style></style>
