<template>
<div class="row">
    <div class="col-12">
        <div class="card">
            <!-- Card header -->
            <div class="card-header pb-0">
                <div class="d-lg-flex">
                <div>
                    <h5 class="mb-0">All payment code</h5>
                    <p class="text-sm mb-0">
                    Payment code list {{userDetail?.id}}
                    </p>
                </div>
                <div class="ms-auto my-auto mt-lg-0 mt-4">
                  <div class="ms-auto my-auto">
                    <button type="button" class="btn btn-outline-primary btn-sm mb-0" data-bs-toggle="modal" data-bs-target="#addUser">
                      Add payment code
                    </button>
                    <div class="modal fade" id="addUser" tabindex="-1" aria-hidden="true">
                      <div class="modal-dialog mt-lg-10">
                        <div class="modal-content">
                          <div class="modal-header">
                            <h5 class="modal-title" id="ModalLabel">Add payment code</h5>
                            <i class="fas fa-plus ms-3"></i>
                            <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
                          </div>
                          <div class="modal-body">
                            <p>You can enter payment code.</p>
                            
                            <b-row class="my-1">
                                <b-col sm="3" class=" my-auto text-end">
                                    <label class="my-auto">* select user:</label>
                                </b-col>
                                <b-col sm="9">
                                    <user-select v-bind:append_to_body="false" v-model="paymentCode.userId"></user-select>
                                    <b-form-input v-model="paymentCode.userId"
                                        hidden
                                        :state="userState"
                                        aria-describedby="user-feedback">
                                    </b-form-input>
                                    <b-form-invalid-feedback id="user-feedback">
                                        Please select a user
                                    </b-form-invalid-feedback>
                                </b-col>
                            </b-row>
                            <b-row class="my-1">
                                <b-col sm="3" class=" my-auto text-end">
                                    <label>* amount:</label>
                                </b-col>
                                <b-col sm="9">
                                    <b-form-input v-model="paymentCode.amount" type="number" class="form-control" :state="amountState" 
                                                            aria-describedby="amount-feedback" placeholder="please enter amount"></b-form-input>
                                    <b-form-invalid-feedback id="amount-feedback">
                                        The payment code value should be an integer between 50 to 10000.
                                    </b-form-invalid-feedback>
                                </b-col>
                            </b-row>
                            <b-row class="my-1">
                                <b-col sm="3" class=" my-auto text-end">
                                    <label>* quantity:</label>
                                </b-col>
                                <b-col sm="9">
                                    <b-form-input v-model="paymentCode.quantity" type="number" class="form-control" :state="quantityState" 
                                                            aria-describedby="quantity-feedback" placeholder="please enter quantity"></b-form-input>
                                    <b-form-invalid-feedback id="quantity-feedback">
                                       The payment code numbers should be an integer between 10 and 1000
                                    </b-form-invalid-feedback>
                                </b-col>
                            </b-row>
                            <b-row class="my-1">
                                <b-col sm="3" class=" my-auto text-end">
                                    <label class="my-auto">* currency:</label>
                                </b-col>
                                <b-col sm="9">
                                    <b-form-select v-model="paymentCode.currency" :options="json.currency" class="form-control" :state="currencyState" 
                                                            aria-describedby="currency-feedback" ></b-form-select>
                                    <b-form-invalid-feedback id="currency-feedback">
                                       Please select a currency
                                    </b-form-invalid-feedback>
                                </b-col>
                            </b-row>
                          </div>
                          <div class="modal-footer">
                            <button type="button" class="btn bg-gradient-secondary btn-sm" data-bs-dismiss="modal">Cancel</button>
                            <button type="button" class="btn bg-gradient-primary btn-sm" data-bs-dismiss="modal" @click="addPaymentCode">Add</button>
                          </div>
                        </div>
                      </div>
                    </div>
                    <!-- <button class="btn btn-outline-primary btn-sm export mb-0 mt-sm-0 mt-1" data-type="csv" type="button" name="button">Export</button> -->
                  </div>
                </div>
                </div>
            </div>
            <div class="card-body px-0 pb-0">
                <div class="table-responsive">
                    <div class="dataTable-wrapper dataTable-loading no-footer sortable searchable fixed-columns">
                        <div class="dataTable-top">
                            <div class="dataTable-dropdown">
                                <label><select class="dataTable-selector" v-model="paginationState.size">
                                    <option :value="5">5</option>
                                    <option :value="10">10</option>
                                    <option :value="15">15</option>
                                    <option :value="20">20</option>
                                    <option :value="25">25</option></select>
                                     entries per page</label>
                            </div>
                            <div class="dataTable-dropdown mx-4">
                                        <label><select class="dataTable-selector" v-model="paginationState.status">
                                            <option value="">Is used?</option>
                                            <option value="1">Yes</option>
                                            <option value="0">No</option>
                                        </select></label>
                            </div>
                            <div class="dataTable-dropdown mx-4 col-sm-3">
                                <user-select v-bind:append_to_body="true" v-model="paginationState.userId"></user-select>
                            </div>
                                        
                            <div class="dataTable-search">
                                <input class="dataTable-input mx-4" placeholder="Search..." type="text" v-model="paginationState.code">
                                <button type="button" class="btn btn-outline-primary btn-sm mb-0" @click="search">
                                Search
                                </button>
                            </div>
                        </div>
                        <div class="dataTable-container">
                            <table class="table table-flush" id="school-list">
                                <thead class="thead-light">
                                    <tr>
                                      <th>code</th>
                                      <th>User Name</th>
                                      <th>account name</th>
                                      <th>amount</th>
                                      <th>create Date</th>
                                      <th>used</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    <tr v-for="item in list"  v-bind:key="item">
                                        <td class="text-sm">{{item.code}}</td>
                                        <td class="text-sm">{{item.userName}}</td>
                                        <td class="text-sm">{{item.accountName}}</td>
                                        <td class="text-sm">{{item.currency + " " +(item.amount / 100).toFixed(2)}}</td>
                                        <td class="text-sm">{{item.createTime}}</td>
                                        <td>
                                            <span class="badge badge-sm" :class="!item.used ? 'badge-danger' : 'badge-success'">{{item.used ? "Yes" : "No"}}</span>
                                        </td>
                                    </tr>
                                    <tr v-if="total == 0">
                                        <td class="dataTables-empty" colspan="6">No entries found</td>
                                    </tr>
                                </tbody>
                            </table>
                        </div>
                        <div class="dataTable-bottom" v-if="total > paginationState.size">
                            <div class="dataTable-info">
                                Showing {{(paginationState.page - 1) * paginationState.size + 1}} to {{paginationState.page * paginationState.size > total ? total : paginationState.page * paginationState.size}} of {{total}} entries
                            </div>
                            <nav class="dataTable-pagination">
                                <b-pagination
                                    v-model="paginationState.page"
                                    :total-rows="total"
                                    :per-page="parseInt(paginationState.size)"
                                    aria-controls="my-table"
                                ></b-pagination>
                            </nav>
                        </div>
                        <div class="dataTable-bottom" v-else>
                            <div class="dataTable-info"></div>
                            <nav class="dataTable-pagination"></nav>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>
</template>

<script>
import { Dashboard } from '@/apiClient/Dashboard'
import { onMounted,ref,getCurrentInstance,watch,reactive } from 'vue';
import bus from '@/plugins/bus';
import userSelect from '@/components/UserSelect'
import json from '@/plugins/category.json'

let requestHeader;
export default {
    name:"paymentCodeList",
    components:{userSelect},
    data() {
        return {
            json:json,
          paymentCode:{
              userId:null,
              quantity:0,
              amount:0,
              currency:""
          }
        }
    },
    computed: {
      amountState() {
        const r = /^\+?[1-9][0-9]*$/;
        if (!r.test(this.paymentCode.amount) ) {
            return false;
        }
        return this.paymentCode.amount < 50 || this.paymentCode.amount > 10000 ? false : true
      },
      quantityState() {
        const r = /^\+?[1-9][0-9]*$/;
        if (!r.test(this.paymentCode.quantity) ) {
            return false;
        }
        return this.paymentCode.quantity < 10 || this.paymentCode.quantity > 1000 ? false : true
      },
      currencyState() {
        return this.paymentCode.currency == "" ? false : true
      },
      userState() {
        return this.paymentCode.userId == "" || this.paymentCode.userId == null ? false : true
      }
    },
    methods:{
      addPaymentCode(){
        if(this.paymentCode.userId == null){
            bus.emit('error', 'The user cannot be empty,please select a user');
            return;
        }
        const r = /^\+?[1-9][0-9]*$/;
        if (!r.test(this.paymentCode.amount) ) {
            bus.emit('error', 'The payment code value should be an integer between 50 to 10000 EGP.');
            return;
        }
        if (!r.test(this.paymentCode.quantity) ) {
            bus.emit('error', 'The payment code numbers should be an integer between 10 and 1000');
            return;
        }
        if (this.paymentCode.currency == "") {
            bus.emit('error', 'The currency cannot be empty,Please select a currency');
            return;
        }
        let api = new Dashboard();
        api.createPaymentCodeUsingPost(this.paymentCode,requestHeader).then(rep =>{
          if(rep.status == 200 && rep.data != null){
            if(rep.data.code == 0){
              bus.emit('success', rep.data.message);
            }
            else{
              bus.emit('error', rep.data.message);
            }
          }
          else{
            bus.emit('error', rep.data.message);
          }
        }).catch(function (error) {
          bus.emit('error', error);
        });
      },
      reload(){
        this.$router.go()
      }
    },
    setup(){
        const list=ref();
        const total = ref(0);
        const paginationState = reactive({
            size: 10,
            page: 1,
            status:"",
            userId:null,
            code:''
        });
        requestHeader = getCurrentInstance().appContext.config.globalProperties.RequestParams
        const search = () =>{
            if(paginationState.page == 1){
                call();
            }
            else{
                paginationState.page = 1;
            }
        }
        const call = () =>{
            bus.emit('load', true);
            let api = new Dashboard();
            let params = { userId:paginationState.userId,code:paginationState.code,status:paginationState.status,page:paginationState.page - 1,size:paginationState.size };
            api.getPaymentCodsUsingGet(params,requestHeader).then((response) => {
                if(response.status == 200 && response.data != null){
                    if(response.data.code == 0){
                        list.value= response.data?.data?.content;
                        total.value = parseInt(response.data?.data.totalElements)
                    }
                    else{
                      bus.emit('error', response.data.message);
                    }
                }
            }).catch((error) => { bus.emit('error', error); }).finally(()=>{ bus.emit('load', false); });
        }
        onMounted(()=>{
            call();
        });
        watch(()=> paginationState.page,()=>{
            call();
        },
        {
            deep:true
        });
         watch(()=> [paginationState.size,paginationState.userId,paginationState.status],()=>{
            paginationState.page = 1;
            call();
        },
        {
            deep:true
        });
        return{
            paginationState,
            list,
            total,
            search
        }
    }
}
</script>