import { createApp } from 'vue'
import App from './App.vue'
import BootstrapVue from 'bootstrap-vue-3'
import './registerServiceWorker'
import router from './router'
import store from './store'
import '@/assets/css/nucleo-icons.css'
import '@/assets/css/nucleo-svg.css'
import '@/assets/css/argon-dashboard.css?v=2.0.1'

const app = createApp(App)
//app.config.globalProperties. = {baseURL:process.env.VUE_APP_API_URL}
app.config.globalProperties.RequestParams = {
    headers: {
        Authorization: 'Bearer ' + localStorage.getItem("token")
    },
    baseURL:process.env.VUE_APP_API_URL,
    appURL:process.env.VUE_APP_URL
}
app.use(store).use(router).use(BootstrapVue).mount('#app')
