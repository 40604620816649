<template>
<footer class="footer pt-3  ">
        <div class="container-fluid">
          <div class="row align-items-center justify-content-lg-between">
            <div class="col-lg-6 mb-lg-0 mb-4">
              <div class="copyright text-center text-sm text-muted text-lg-start">
                © All Rights Reserved. oktopi 2021.
              </div>
            </div>
            <div class="col-lg-6">
              <ul class="nav nav-footer justify-content-center justify-content-lg-end">
                <li class="nav-item">
                  <a href="https://oktopi.ai/en/" class="nav-link text-muted" target="_blank">Oktopi</a>
                </li>
                <li class="nav-item">
                  <a href="https://oktopi.ai/faqs/" class="nav-link text-muted" target="_blank">FAQ</a>
                </li>
              </ul>
            </div>
          </div>
        </div>
        <b-modal v-model="modalShow" ok-only centered title="You have a new notification"><span v-html="message"></span></b-modal>
        <b-modal v-model="modalError" ok-only centered title="Some error"><span v-html="msg"></span></b-modal>
        <b-modal v-model="modalSuccess" ok-only centered title="Success" @ok="reload()"><span v-html="msg"></span></b-modal>
        <b-modal v-model="modalNoPermission" ok-only centered title="No permission" @ok="login()">you do not have permission.</b-modal>
        <b-modal v-model="modalShowTips" ok-only centered title="Tips"><span v-html="msg"></span></b-modal>
      </footer>
</template>
<script>
import SockJS from "sockjs-client";
import Stomp from "webstomp-client";
import { getCurrentInstance, ref,onBeforeUnmount} from 'vue';
import bus from '@/plugins/bus';
let baseUrl = "";
export default {
  data() {
    return {
      message:'',
      modalShow: false
    }
  },
  setup() {
    const modalError = ref(false);
    const modalSuccess = ref(false);
    const modalNoPermission = ref(false);
    const modalShowTips = ref(false);
    const msg = ref({});
    baseUrl = getCurrentInstance().appContext.config.globalProperties.RequestParams.baseURL;
    const apiError = (errorMsg) => {
      if(errorMsg.response){
        if(errorMsg.response.status === 401){
          modalNoPermission.value = true;
        }
        if(errorMsg.response.status === 403){
          msg.value = "you do not have permission.";
          modalError.value = true;
        }
        else{
          msg.value = errorMsg;
          modalError.value = true;
        }
      }
      else{
        msg.value = errorMsg;
        modalError.value = true;
      }
    }
    const apiSuccess = (message) => {
      msg.value = message;
      modalSuccess.value = true;
    }

    const tips = (message) => {
      msg.value = message;
      modalShowTips.value = true;
    }

    // 启用监听
    bus.on('error', apiError);
    bus.on('success', apiSuccess);
    bus.on('tips', tips);
    // 在组件卸载之前移除监听
    onBeforeUnmount( () => {
      bus.off('error', apiError);
      bus.off('success', apiSuccess);
      bus.off('tips', tips);
    })
    return{
      modalError,
      modalSuccess,
      modalNoPermission,
      modalShowTips,
      msg
    }
  },
  created(){
    this.socket = new SockJS(baseUrl + "/notification/webSocket");
    //this.socket = new SockJS("http://localhost:12800/notification/webSocket");
    this.stompClient = Stomp.over(this.socket, { debug: false });
    this.stompClient.connect(
      {},
      () => {
        this.connected = true;
        this.stompClient.subscribe("/user/" + localStorage.getItem("userId") + "/dashboard", tick => {
          let data =  JSON.parse(tick.body);
          this.message = "<a target=\"_blank\" href=\"#/publishApplication/" + data.event?.id + "\">new publish to market place class application need you audit</a>";
          this.modalShow = true;
        });
        this.stompClient.subscribe("/user/" + localStorage.getItem("userId") + "/dashboard/rolesUpdate", tick => {
          let data =  JSON.parse(tick.body);
          localStorage.setItem("auth", data.event);
        });
      },
      error => {
        console.log(error);
        this.connected = false;
      }
    );
  },
  methods: {
      reload(){
        this.$router.go()
      },
      login(){
        localStorage.clear();
        this.$router.go()
      }
  }
}
</script>
<style></style>
