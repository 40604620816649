<template>
    <div class="container-fluid my-5 py-2">
      <div class="row">
        <div class="col-lg-12 mt-lg-0 mt-4">
            <!-- Card Profile -->
            <b-card no-body class="mb-4">
                <b-card-header>
                    <div class="row">
                        <div class="col-sm-auto col-4">
                          <img v-if="school.logo + '?size=80x80'" :src="school.logo" class="avatar rounded-circle avatar-xl position-relative">
                          <span v-else-if="!school.logo" class="bg-primary rounded-circle avatar text-uppercase"><span class="text-light">{{getWord(school.schoolName)}}</span></span>
                        </div>
                        <div class="col-sm-auto col-8 my-auto">
                            <p class="text-sm mb-0 text-uppercase font-weight-bold">school name</p>
                            <h5 class="font-weight-bolder">
                                {{school.schoolName}}
                            </h5>
                        </div>
                    </div>
                </b-card-header>
                <b-card-body body-class="p3">
                    <b-row v-if="statisticData">
                        <b-col md="4">
                            <div class="numbers">
                                <p class="text-sm mb-0 text-uppercase font-weight-bold">Used storage</p>
                                <h6>
                                    {{formatSize(statisticData.usedStorage)}}
                                </h6>
                            </div>
                        </b-col>
                        <b-col md="4" class="text-center">
                            <div class="numbers">
                                <p class="text-sm mb-0 text-uppercase font-weight-bold">video duration (watched / Total)</p>
                                <h6>
                                    {{ formatToHours(statisticData.playedDuration) + " / " + formatToHours(statisticData.duration)}}
                                </h6>
                            </div>
                        </b-col>
                        <b-col class="text-end">
                            <div class="numbers">
                                <p class="text-sm mb-0 text-uppercase font-weight-bold">AWS Cost</p>
                                <h6>
                                    {{statisticData.playedDuration != 0 ? (statisticData.playedDuration * 0.35 * 0.085 / 3600).toFixed(2) + " USD" : "0"}}
                                </h6>
                            </div>
                        </b-col>
                    </b-row>
                    <b-row>
                        <b-col md="4">
                            <div class="numbers">
                                <p class="text-sm mb-0 text-uppercase font-weight-bold">Student count</p>
                                <h6>
                                    {{school.studentCount}}
                                </h6>
                            </div>
                        </b-col>
                        <b-col md="4" class="text-center">
                            <div class="numbers">
                                <p class="text-sm mb-0 text-uppercase font-weight-bold">Enrolled count</p>
                                <h6>
                                    {{ school.enrolledCount}}
                                </h6>
                            </div>
                        </b-col>
                    </b-row>
                    <b-row v-if="statisticData.lineDatas?.length > 0">
                        <b-col>
                            <ChartExample :size="{ width, height: 420 }" :data="lineData" />
                        </b-col>
                    </b-row>
                </b-card-body>
                <b-card-footer>
                </b-card-footer>
            </b-card>
        </div>
      </div>
      
      <div class="row mt-1" v-if="statisticData.classes">
        <div class="col-lg-3 col-md-6 col-12 mt-4 mt-lg-0 cursor-pointer" v-for="clazz in statisticData.classes" v-bind:key="clazz">
            <a v-b-modal.modal-xl @click="setClassId(clazz.id)">
                <div class="card mt-4">
                    <div class="card-body p-3">
                        <div class="row">
                            <div class="col-sm-4 col-4">
                            <img v-if="clazz.logo" :src="clazz.logo + '?size=80x80'" class="avatar rounded-circle avatar-xl position-relative">
                            <span v-else-if="!clazz.logo" class="bg-primary rounded-circle avatar text-uppercase"><span class="text-light">{{getWord(clazz.clazzName)}}</span></span>
                            </div>
                            <div class="col-sm-8 col-8 text-end">
                                <p class="text-sm mb-0 text-uppercase font-weight-bold">class name</p>
                                <h5 class="font-weight-bolder" :title="clazz.clazzName">
                                    {{clazz.clazzName.length > 14 ? clazz.clazzName.slice(0,14) + '...' : clazz.clazzName}}
                                </h5>
                            </div>
                        </div>
                        <div class="row mt-4">
                            <div class="col-sm-5 col-5">
                                <p class="text-sm mb-0 text-uppercase font-weight-bold">Used storage</p>
                                <h5 class="font-weight-bolder">
                                    {{formatSize(clazz.statisticsFileClass?.usedStorage)}}
                                </h5>
                            </div>
                            <div class="col-sm-7 col-7 text-end">
                                <p class="text-sm mb-0 text-uppercase font-weight-bold">Total video duration</p>
                                <h5 class="font-weight-bolder">
                                    {{formatToHours(clazz.statisticsFileClass?.duration)}}
                                </h5>
                            </div>
                        </div>
                        <div class="row mt-4">
                            <div class="col-sm-5 col-5">
                                <p class="text-sm mb-0 text-uppercase font-weight-bold">AWS Cost</p>
                                <h5 class="font-weight-bolder">
                                    {{clazz.statisticsFileClass?.playedDuration != 0 ? (clazz.statisticsFileClass?.playedDuration * 0.35 * 0.085 / 3600).toFixed(2) + " USD" : "0"}}
                                </h5>
                            </div>
                            <div class="col-sm-7 col-7 text-end">
                                <p class="text-sm mb-0 text-uppercase font-weight-bold">Watched duration</p>
                                <h5 class="font-weight-bolder">
                                    {{formatToHours(clazz.statisticsFileClass?.playedDuration)}}
                                </h5>
                            </div>
                        </div>
                        <div class="row mt-4">
                            <div class="col-sm-5 col-5">
                                <p class="text-sm mb-0 text-uppercase font-weight-bold">Student count</p>
                                <h5 class="font-weight-bolder">
                                    {{clazz.studentsCount}}
                                </h5>
                            </div>
                            <div class="col-sm-7 col-7 text-end">
                                <p class="text-sm mb-0 text-uppercase font-weight-bold">Enrolled count</p>
                                <h5 class="font-weight-bolder">
                                    {{clazz.learnSpaceStudentCount}}
                                </h5>
                            </div>
                        </div>
                    </div>
                </div>
            </a>
        </div>
      </div>
      <b-modal id="modal-xl" size="xl" title="Class files list">
            <b-card no-body>
                <b-card-body>
                    <b-table-simple responsive class=" align-items-center mb-0">
                        <b-thead>
                            <b-tr>
                                <b-th class="text-uppercase text-secondary text-xxs font-weight-bolder opacity-7 ps-2">type</b-th>
                                <b-th class="text-uppercase text-secondary text-xxs font-weight-bolder opacity-7 ps-2">file name</b-th>
                                <b-th class="text-uppercase text-secondary text-xxs font-weight-bolder opacity-7 ps-2">duration time</b-th>
                                <b-th class="text-uppercase text-secondary text-xxs font-weight-bolder opacity-7 ps-2">Size</b-th>
                            </b-tr>
                        </b-thead>
                        <b-tbody>
                            <b-tr v-for="item in resources" v-bind:key="item">
                                <b-td><p class="text-sm text-secondary mb-0">{{item.genieType}}</p></b-td>
                                <b-td><p class="text-sm text-secondary mb-0"><a :href="getUrl(item.viewUrl,item.url)" target="_blank">{{ item.fileName == "" || item.fileName == null ? item.fileMd5 : item.fileName}}</a></p></b-td>
                                <b-td><p class="text-sm text-secondary mb-0">{{formatSeconds(item.duration)}}</p></b-td>
                                <b-td><p class="text-sm text-secondary mb-0">{{formatSize(item.size)}}</p></b-td>
                            </b-tr>
                            <tr v-if="total == 0">
                                <td class="dataTables-empty" colspan="6">No entries found</td>
                            </tr>
                        </b-tbody>
                    </b-table-simple>
                </b-card-body>
                <div class="dataTable-bottom" v-if="total > paginationState.size">
                        <div class="dataTable-info">
                            Showing {{(paginationState.page - 1) * paginationState.size + 1}} to {{paginationState.page * paginationState.size > total ? total : paginationState.page * paginationState.size}} of {{total}} entries
                        </div>
                        <nav class="dataTable-pagination">

                        <b-pagination
                        v-model="paginationState.page"
                        :total-rows="total"
                        :per-page="parseInt(paginationState.size)"
                        aria-controls="my-table"
                    ></b-pagination>
                        </nav>
                </div>
            </b-card>
      </b-modal>
    </div>
</template>
<script>
import { Dashboard } from '@/apiClient/Dashboard'
import BCard from 'bootstrap-vue-3/src/components/BCard/BCard.vue';
import { defineComponent,onMounted,ref,getCurrentInstance,watch,reactive } from 'vue';
import { useRouter } from "vue-router";
import bus from '@/plugins/bus';
import ChartExample from "@/components/ChartExample.vue";

let requestParams;
let schoolId;
//let creatorId;
export default defineComponent({
  name: 'schoolAnalysis',
  components: { BCard,ChartExample },
  methods: {
      getUrl(viewUrl,url){
        if(viewUrl) return viewUrl;
        return url;
      },
      getWord(f){
        f = f ? f : "";
        let arr = f.split(' ');
        let first = arr ? arr[0].slice(0,1) : "";
        let last = arr[1] ? arr[1].slice(0,1) : "";
        return first + last;
      },
      formatToHours(seconds){
        if (isNaN(seconds)) {
            return '';
        }
        let result = parseInt(seconds)
        let symbols = ['s', 'm', 'h'];
        let h = result / 3600;
        if (h >= 1) {
           return h.toFixed(2) + ' ' + symbols[2]
        }
        let m = Math.floor((result / 60 % 60));
        let s = Math.floor((result % 60));
        let res = '';
        if(m !== 0) res += `${m}${symbols[1]} `;
        res += `${s}${symbols[0]}`;
        return res;
      },
      formatSeconds(value) {
        let result = parseInt(value)
        //let d = Math.floor(result / (3600 * 24));
        let h = Math.floor(result / 3600);
        let m = Math.floor((result / 60 % 60));
        let s = Math.floor((result % 60));
        let res = '';
        //if(d !== 0) res += `${d}d `;
        if(h !== 0) res += `${h}h `;
        if(m !== 0) res += `${m}min `;
        res += `${s}s`;
        return res;
      },
      formatSize(bytes) {
        if (isNaN(bytes)) {
            return '';
        }
        let symbols = ['B', 'KB', 'MB', 'GB', 'TB', 'PB', 'EB', 'ZB', 'YB'];
        let exp = Math.floor(Math.log(bytes)/Math.log(2));
        if (exp < 1) {
            exp = 0;
        }
        let i = Math.floor(exp / 10);
        bytes = bytes / Math.pow(2, 10 * i);

        if (bytes.toString().length > bytes.toFixed(2).toString().length) {
            bytes = bytes.toFixed(2);
        }
        return bytes + ' ' + symbols[i];
      }
  },
  setup(){
    const school=ref({});
    const statisticData = ref({});
    const router = useRouter();
    const classId = ref(0);
    const lineData = ref([]);
    schoolId = router.currentRoute.value.params.id;
    const total = ref(0);
    const paginationState = reactive({
        size: 10,
        page: 1,
        keyword:""
    });
    const resources = ref();
    requestParams = getCurrentInstance().appContext.config.globalProperties.RequestParams;

    const setClassId = (id) =>{
      classId.value = id;
    }
    const call = () =>{
      let api = new Dashboard();
      let params = { classId: classId.value,page:paginationState.page - 1,size:paginationState.size };
      api.getClassFilesUsingGet(params,requestParams).then(rep=>{
        if(rep.status == 200 && rep.data != null){
            if(rep.data.code == 0){
                let data = rep.data.data;
                resources.value = data.content;
                total.value = parseInt(rep.data?.data.totalElements);
            }
            else{
                bus.emit('error', rep.data.message);
            }
        }
      }).catch(function (error) {
          bus.emit('error',error);
      });
    }
    onMounted(()=>{
      let api = new Dashboard();
      let params = { schoolId: schoolId };

      api.getSchoolByIdUsingGet(params,requestParams).then(rep=>{
        if(rep.status == 200 && rep.data != null){
          if(rep.data.code == 0){
            let data = rep.data.data;
            school.value = data;
            //creatorId = school.value.creator;
            call();
          }
          else{
            bus.emit('error', rep.data.message);
          }
        }
      }).catch(function (error) {
          bus.emit('error', error);
      });
      api.getSchoolAnalysisByIdUsingGet(params,requestParams).then(rep=>{
        if(rep.status == 200 && rep.data != null){
          if(rep.data.code == 0){
            let data = rep.data.data;
            statisticData.value = data;
            lineData.value = data.lineDatas;
          }
          else{
            bus.emit('error', rep.data.message);
          }
        }
      }).catch(function (error) {
          bus.emit('error', error);
      });
  })
  watch(()=> paginationState.page,()=>{
            call();
        },
        {
            deep:true
        });
  watch(()=> classId,()=>{
        if(paginationState.page == 1)
        call()
        else
        paginationState.page = 1;
    },
    {
        deep:true
    });
    return{
      school,
      statisticData,
      resources,
      total,
      paginationState,
      setClassId,
      lineData
    }
 
  }
});
</script>