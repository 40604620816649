<template>
<div class="row">
    <div class="col-12">
        <div class="card">
            <!-- Card header -->
            <div class="card-header pb-0">
                <div class="d-lg-flex">
                <div>
                    <h5 class="mb-0">All promo code</h5>
                    <p class="text-sm mb-0">
                    Promo code list
                    </p>
                </div>
                <div class="ms-auto my-auto mt-lg-0 mt-4">
                  <div class="ms-auto my-auto">
                    <button type="button" class="btn btn-outline-primary btn-sm mb-0" data-bs-toggle="modal" data-bs-target="#addUser">
                      Add promo code
                    </button>
                    <div class="modal fade" id="addUser" tabindex="-1" aria-hidden="true">
                      <div class="modal-dialog mt-lg-10">
                        <div class="modal-content">
                          <div class="modal-header">
                            <h5 class="modal-title" id="ModalLabel">Add promo code</h5>
                            <i class="fas fa-plus ms-3"></i>
                            <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
                          </div>
                          <div class="modal-body">
                            <p>You can enter promo code.</p>
                            <b-row class="my-1">
                                <b-col sm="3" class=" my-auto text-end">
                                    <label>* email:</label>
                                </b-col>
                                <b-col sm="9">
                                    <b-form-input v-model="promoCode.email" placeholder="Enter your email"></b-form-input>
                                </b-col>
                            </b-row>
                            <b-row class="my-1">
                                <b-col sm="3" class=" my-auto text-end">
                                    <label>code:</label>
                                </b-col>
                                <b-col sm="9">
                                    <b-form-input v-model="promoCode.code"
                                        :state="codeState"
                                        aria-describedby="input-live-feedback"
                                        placeholder="Enter your code"
                                        trim  class="text-uppercase" >
                                    </b-form-input>
                                    <b-form-invalid-feedback id="input-live-feedback">
                                        Code must be 8 characters(numbers or letters)
                                    </b-form-invalid-feedback>
                                </b-col>
                            </b-row>
                            <b-row class="my-1">
                                <b-col sm="3" class=" my-auto text-end">
                                    <label>* discount:</label>
                                </b-col>
                                <b-col sm="9">
                                    <b-form-input v-model="promoCode.discount" placeholder="Enter your discount"></b-form-input>
                                </b-col>
                            </b-row>
                            <b-row class="my-1">
                                <b-col sm="3" class=" my-auto text-end">
                                    <label class="my-auto">* promo type:</label>
                                </b-col>
                                <b-col sm="9">
                                    <b-form-select v-model="promoCode.promoType" :options="options" class="form-control" ></b-form-select>
                                </b-col>
                            </b-row>
                            <b-row class="my-1">
                                <b-col sm="3" class=" my-auto text-end">
                                    <label>*expiry date:</label>
                                </b-col>
                                <b-col sm="9">
                                    <b-form-input v-model="promoCode.expiryDate" placeholder="Enter your email" type="date"></b-form-input>
                                </b-col>
                            </b-row>
                          </div>
                          <div class="modal-footer">
                            <button type="button" class="btn bg-gradient-secondary btn-sm" data-bs-dismiss="modal">Cancel</button>
                            <button type="button" class="btn bg-gradient-primary btn-sm" data-bs-dismiss="modal" @click="addPromoCode">Add</button>
                          </div>
                        </div>
                      </div>
                    </div>
                    <!-- <button class="btn btn-outline-primary btn-sm export mb-0 mt-sm-0 mt-1" data-type="csv" type="button" name="button">Export</button> -->
                  </div>
                </div>
                </div>
            </div>
            <div class="card-body px-0 pb-0">
                <div class="table-responsive">
                    <div class="dataTable-wrapper dataTable-loading no-footer sortable searchable fixed-columns">
                            <div class="dataTable-top">
                                <b-col sm="6" class="dataTable-dropdown">
                                    <label><select class="dataTable-selector" v-model="paginationState.size">
                                        <option :value="5">5</option>
                                        <option :value="10">10</option>
                                        <option :value="15">15</option>
                                        <option :value="20">20</option>
                                        <option :value="25">25</option></select>
                                        entries per page</label>
                                </b-col>
                                <b-col sm="6" class="dataTable-search">
                                <b-row>
                                    <b-col sm="1">
                                    </b-col>
                                    <b-col sm="3">
                                        <b-form-input v-model="paginationState.startDate" placeholder="Enter your email" type="date" class="dataTable-input"></b-form-input>
                                    </b-col>
                                    <b-col sm="3">
                                        <b-form-input v-model="paginationState.endDate" placeholder="Enter your email" type="date" class="dataTable-input"></b-form-input>
                                    </b-col>
                                    <b-col sm="3">
                                        <input class="dataTable-input" placeholder="Search..." type="text" v-model="paginationState.email">
                                    </b-col>
                                    <b-col sm="2">
                                        <button type="button" class="btn btn-outline-primary btn-sm mb-0" @click="search">
                                        Search
                                        </button>
                                    </b-col>
                                </b-row>
                                </b-col>
                            </div>
                        <div class="dataTable-container">
                            <table class="table table-flush" id="school-list">
                                <thead class="thead-light">
                                    <tr>
                                      <th>code</th>
                                      <th>email</th>
                                      <th>promo Type</th>
                                      <th>discount</th>
                                      <th>register Count</th>
                                      <th>subscription Count</th>
                                      <th>create Date</th>
                                      <th>expiry Date</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    <tr v-for="item in list"  v-bind:key="item">
                                    <td class="text-sm cursor-pointer" title="You can click on code and copy the link"><a v-b-modal.modalUrl @click="setUrl(item.code,item.promoType)">{{item.code}}</a></td>
                                    <td class="text-sm">{{item.email}}</td>
                                    <td class="text-sm">{{item.promoType == "clazz" ? "class" : item.promoType}}</td>
                                    <td class="text-sm">{{item.discount}}</td>
                                    <td class="text-sm">{{item.registerCount}}</td>
                                    <td class="text-sm">{{item.subscriptionCount}}</td>
                                    <td class="text-sm">{{item.createTime}}</td>
                                    <td>
                                        <span class="badge badge-sm" :class="Date.parse(item.expiryDate) < new Date() ? 'badge-danger' : 'badge-success'">{{item.expiryDate}}</span>
                                    </td>
                                    </tr>
                                    <tr v-if="total == 0">
                                        <td class="dataTables-empty" colspan="8">No entries found</td>
                                    </tr>
                                </tbody>
                            </table>
                        </div>
                        <div class="dataTable-bottom" v-if="total > paginationState.size">
                        <div class="dataTable-info">
                            Showing {{(paginationState.page - 1) * paginationState.size + 1}} to {{paginationState.page * paginationState.size > total ? total : paginationState.page * paginationState.size}} of {{total}} entries
                        </div>
                        <nav class="dataTable-pagination">

                        <b-pagination
                        v-model="paginationState.page"
                        :total-rows="total"
                        :per-page="parseInt(paginationState.size)"
                        aria-controls="my-table"
                    ></b-pagination>
                        </nav>
                    </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
    <b-modal id="modalUrl" ok-only centered title="Promo code url" ok-title="Copy" @ok="doCopy"><span>{{url}}</span></b-modal>
    <b-modal v-model="modalShowTips" ok-only centered title="Tips"><span v-html="message"></span></b-modal>
</div>
</template>

<script>
import { Dashboard } from '@/apiClient/Dashboard'
import { onMounted,ref,getCurrentInstance,watch,reactive } from 'vue';
import bus from '@/plugins/bus';
import { copyText } from 'vue3-clipboard'

let requestHeader;
let promCodeUrl;
let appUrl = "";
export default {
    name:"promoCodeList",
    data() {
        return {
          promoCode:{
              email:"",
              expiryDate:"",
              discount:0,
              promoType:"",
              code:""
          },
          options:[
              { value: 'plan', text: 'plan' },
              { value: 'clazz', text: 'class' },
          ],
          url:""
        }
    },
    computed: {
      codeState() {
          if(this.promoCode.code.length == 0)
          return null;
        return this.promoCode.code.length == 8 ? true : false
      }
    },
    methods:{
      addPromoCode(){
        let api = new Dashboard();
        this.promoCode.expiryDate = new Date(this.promoCode.expiryDate);
        api.createNewPromoCodeUsingPost(this.promoCode,requestHeader).then(rep =>{
          if(rep.status == 200 && rep.data != null){
            if(rep.data.code == 0){
              bus.emit('success', rep.data.message);
            }
            else{
              bus.emit('error', rep.data.message);
            }
          }
          else{
            bus.emit('error', rep.data.message);
          }
        }).catch(function (error) {
          bus.emit('error', error);
        });
      },
      reload(){
        this.$router.go()
      },
      setUrl(code,type){
        if(type == "clazz"){
            promCodeUrl = appUrl + "/?promo=" + code;
        }
        else{
            promCodeUrl = appUrl + "/signup-new?promo=" + code;
        }
        this.url = promCodeUrl;
      }
    },
    setup(){
        appUrl = getCurrentInstance().appContext.config.globalProperties.RequestParams.appURL;
        const list=ref();
        const total = ref(0);
        const paginationState = reactive({
            size: 10,
            page: 1,
            email:"",
            startDate:'',
            endDate:''
        });
        const doCopy = () => {
            copyText(promCodeUrl, undefined, (error) => {
            if (error) {
              bus.emit('error', 'Can not copy');
            } else {
              bus.emit('tips', 'Copied');
            }
            })
        };
        requestHeader = getCurrentInstance().appContext.config.globalProperties.RequestParams
        const search = () =>{
            if(paginationState.page == 1){
                call();
            }
            else{
                paginationState.page = 1;
            }
        }
        const call = () =>{
            bus.emit('load', true);
            let api = new Dashboard();
            let params = { email:paginationState.email,startDate:paginationState.startDate,endDate:paginationState.endDate,page:paginationState.page - 1,size:paginationState.size };
            api.getAllPromoCodeUsingGet(params,requestHeader).then((response) => {
                if(response.status == 200 && response.data != null){
                    if(response.data.code == 0){
                        list.value= response.data?.data?.content;
                        total.value = parseInt(response.data?.data.totalElements)
                    }
                    else{
                      bus.emit('error', response.data.message);
                    }
                }
            }).catch((error) => { bus.emit('error', error); }).finally(()=>{ bus.emit('load', false); });
        }
        onMounted(()=>{
            call();
        });
        watch(()=> paginationState.page,()=>{
            call();
        },
        {
            deep:true
        });
         watch(()=> [paginationState.size,paginationState.startDate,paginationState.endDate],()=>{
            paginationState.page = 1;
            call();
        },
        {
            deep:true
        });
        return{
            paginationState,
            list,
            total,
            doCopy,
            search
        }
    }
}
</script>