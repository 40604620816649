<template>
<div class="container-fluid my-5 py-2">
    <div class="row">
        <div class="col-lg-7 mt-lg-0 mt-4">
            <!-- Card Profile -->
            <b-card no-body class="mb-4 h-100">
                <b-card-header class=" pb-0 p-3">
                    <p class="text-sm mb-0 text-uppercase font-weight-bold">Resource name</p>
                    <h5 class="font-weight-bolder">
                        {{studentResource.resourceName}}
                    </h5>
                </b-card-header>
                <b-card-body body-class="p3">
                    <b-row>
                        <b-col md="3">
                            <div class="numbers">
                                <p class="text-sm mb-0 text-uppercase font-weight-bold">Instructor Name</p>
                                <h6 v-if="studentResource.creator">
                                    {{studentResource.creator.accountName}}
                                </h6>
                            </div>
                        </b-col>
                        <b-col md="3" class="text-center">
                            <div class="numbers">
                                <p class="text-sm mb-0 text-uppercase font-weight-bold">Student Name</p>
                                <h6>
                                    {{ student.accountName}}
                                </h6>
                            </div>
                        </b-col>
                        <b-col md="3" class="text-center">
                            <div class="numbers">
                                <p class="text-sm mb-0 text-uppercase font-weight-bold">Course Name</p>
                                <h6>
                                    {{ course.clazzName}}
                                </h6>
                            </div>
                        </b-col>
                        <b-col class="text-end">
                            <div class="numbers">
                                <p class="text-sm mb-0 text-uppercase font-weight-bold">School Name</p>
                                <h6>
                                    {{school.schoolName}}
                                </h6>
                            </div>
                        </b-col>
                    </b-row>
                </b-card-body>
                <b-card-footer>
                    <b-row>
                        <b-col md="12">
                            <div class="numbers">
                                <p class="text-sm mb-0 text-uppercase font-weight-bold">Instructions</p>
                                <div v-html="studentResource.instructions">
                                </div>
                            </div>
                        </b-col>
                    </b-row>
                </b-card-footer>
            </b-card>
        </div>
        <div class="col-lg-5 mt-lg-0 mt-4">
            <b-card no-body class="h-100">
                <b-card-header class="pb-0 p-3">
                    <p class="text-sm mb-0 text-uppercase font-weight-bold">Overall Rating</p>
                </b-card-header>
                <b-card-body class=" pb-0 p-3">
                    <b-row class="pb-0 p-3" v-if="overallRating.scores">
                        <b-col v-for="item in overallRating.scores" v-bind:key="item">
                            <div v-if="item.count == 0">
                                <b-row>
                                <div class="rating ms-auto">
                                    <i class="fas fa-star" v-for="x in item.score" v-bind:key="x"></i>
                                </div>
                                </b-row>
                                <b-row>
                                <div>{{"0%" }}</div>
                                </b-row>
                            </div>
                            <div v-if="item.count > 0">
                                <b-row>
                                <div class="rating ms-auto" v-if="item.count != 0">
                                    <i class="fas fa-star" v-for="x in item.score" v-bind:key="x"></i>
                                </div>
                                </b-row>
                                <b-row>
                                    <div v-if="item.count != 0">{{item.count / total * 100 + "%" }}</div>
                                </b-row>
                            </div>
                        </b-col>
                    </b-row>
                  <b-row class="pb-0 p-3">
              <ul class="list-group">
                <li class="list-group-item border-0 d-flex align-items-center px-0 mb-0" v-if="overallRating.likes">
                  <div class="w-100">
                    <div class="d-flex mb-2">
                      <span class="me-2 text-sm font-weight-bold text-capitalize">Do you like the way this lesson was given?</span>
                    </div>
                    <div v-for="item in overallRating.likes" v-bind:key="item">
                        <b-row>
                            <b-col md="2" >{{ item.score == 0 ? "Yes" : item.score == 1 ? "Not Sure" : "No"}}</b-col>
                            <b-col md="2" class="text-end">{{ parseInt(item.count / totalLikes * 100) + "%"}}</b-col>
                            <b-col md="8" class="text-end">
                                <div class="progress progress-md mt-2">
                                    <div class="progress-bar bg-gradient-info" :class="'w-' + parseInt(item.count / totalLikes * 100)" role="progressbar" aria-valuenow="60" aria-valuemin="0" aria-valuemax="100"></div>
                                </div>
                            </b-col>
                      </b-row>
                    </div>
                  </div>
                </li>
                <li class="list-group-item border-0 d-flex align-items-center px-0 mb-2" v-if="overallRating.understands">
                  <div class="w-100">
                    <div class="d-flex mb-2">
                      <span class="me-2 text-sm font-weight-bold text-capitalize">Did you understand the lesson?</span>
                    </div>
                    <div v-for="item in overallRating.understands" v-bind:key="item">
                        <b-row><b-col md="12">{{ item.score == 0 ? "I totally understand it." : item.score == 1 ? "I roughly understand it." : item.score == 2 ? "Some parts need clarification." : "I don't understand it. I need more support."}}</b-col></b-row>
                        <b-row>
                            <b-col md="2">{{ parseInt(item.count / totalLikes * 100) + "%"}}</b-col>
                            <b-col md="10" class="text-end">
                                <div class="progress progress-md mt-2">
                                    <div class="progress-bar bg-gradient-info" :class="'w-' + parseInt(item.count / totalLikes * 100)" role="progressbar" aria-valuenow="60" aria-valuemin="0" aria-valuemax="100"></div>
                                </div>
                            </b-col>
                      </b-row>
                    </div>
                  </div>
                </li>
              </ul>
                  </b-row>
                </b-card-body>
            </b-card>
        </div>
    </div>
</div>
</template>
<script>
import { Dashboard } from '@/apiClient/Dashboard'
import BCard from 'bootstrap-vue-3/src/components/BCard/BCard.vue';
import { defineComponent,onMounted,ref,getCurrentInstance } from 'vue';
import { useRouter } from "vue-router";
import bus from '@/plugins/bus';

let requestParams;
let resourceId;
export default defineComponent({
  components: { BCard },
  name: 'studentResourceDetail',
  methods: {
      
  },
  setup(){
    const student = ref({});
    const course = ref({});
    const school = ref({});
    const attach = ref();
    const overallRating = ref({});
    const studentResource = ref({});
    const total = ref(0);
    const totalLikes = ref(0);
    const totalUnderstands = ref(0);
    requestParams = getCurrentInstance().appContext.config.globalProperties.RequestParams;
    const router = useRouter();
    resourceId = router.currentRoute.value.params.id;
    onMounted(()=>{
      let api = new Dashboard();
      let params = { resourceId: resourceId };

      api.getAttachmentListUsingGet(params,requestParams).then(rep=>{
        if(rep.status == 200 && rep.data != null){
          if(rep.data.code == 0){
            let data = rep.data.data;
            attach.value = data;
          }
          else{
              bus.emit('error', rep.data.message);
          }
        }
      }).catch(function (error) {
          bus.emit('error', error);
      });

      api.getStudentResourceByIdUsingGet(params,requestParams).then(rep=>{
        if(rep.status == 200 && rep.data != null){
          if(rep.data.code == 0){
            let data = rep.data.data;
            studentResource.value = data;
            getStudent(data.studentId);
            getCourse(data.classId);
            getSchool(data.schoolId);
            getStatistics(data.originSchoolResourceId);
          }
          else{
              bus.emit('error', rep.data.message);
          }
        }
      }).catch(function (error) {
          bus.emit('error', error);
      });
      
      let getStudent = (studentId) =>{ 
          api.getUserProfileUsingGet({userId:studentId},requestParams).then(rep=>{
            if(rep.status == 200 && rep.data != null){
            if(rep.data.code == 0){
                let data = rep.data.data;
                student.value = data;
            }
            else{
                bus.emit('error', rep.data.message);
            }
        }
        }).catch(function (error) {
            bus.emit('error', error);
        });
      }

      let getCourse = (classId) =>{ 
          api.getClassByIdUsingGet({classId:classId},requestParams).then(rep=>{
            if(rep.status == 200 && rep.data != null){
            if(rep.data.code == 0){
                let data = rep.data.data;
                course.value = data;
            }
            else{
                bus.emit('error', rep.data.message);
            }
        }
        }).catch(function (error) {
            bus.emit('error', error);
        });
      }
      let getSchool = (schoolId) =>{ 
          api.getSchoolByIdUsingGet({schoolId:schoolId},requestParams).then(rep=>{
            if(rep.status == 200 && rep.data != null){
            if(rep.data.code == 0){
                let data = rep.data.data;
                school.value = data;
            }
            else{
                bus.emit('error', rep.data.message);
            }
        }
        }).catch(function (error) {
            bus.emit('error', error);
        });
      }

      let getStatistics = (resourceId) =>{ 
          api.getResourceRatingStatisticsUsingGet({resourceId:resourceId},requestParams).then(rep=>{
            if(rep.status == 200 && rep.data != null){
            if(rep.data.code == 0){
                let data = rep.data.data;
                overallRating.value = data;
                data.scores.forEach(value => {
                    total.value += value.count;
                });
                data.likes.forEach(value => {
                    totalLikes.value += value.count;
                });
                data.understands.forEach(value => {
                    totalUnderstands.value += value.count;
                });
            }
            else{
                bus.emit('error', rep.data.message);
            }
        }
        }).catch(function (error) {
            bus.emit('error', error);
        });
      }

  })
    return{
      studentResource,
      student,
      course,
      school,
      attach,
      overallRating,
      total,
      totalLikes,
      totalUnderstands
    }
 
  }
});
</script>