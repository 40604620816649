<template>
<div class="container-fluid my-5 py-2">
    <div class="row" v-if="application.publishState == 'pending' && !application.beCancel">
        <div class="col-lg-12 col-md-12 col-12">
              <b-card no-body class="mb-4">
                <b-card-body>
                   <b-row>
                     <b-col class="text-end">
                        <b-button v-b-modal.modalEditApplicationCategory class="btn btn-outline-primary btn-sm mb-0" @click="setApplication(application.id,application.ageRangeType,application.categoryType)">Approve</b-button>
                        <b-button class="btn btn-outline-primary btn-sm mb-0 mx-2" v-b-modal.modalRejectConfirm>Rejecte</b-button>
                     </b-col>
                   </b-row>
                </b-card-body>
              </b-card>
            </div>
    </div>
    <div class="row">
        <div class="col-lg-7 mt-lg-0 mt-4">
            <!-- Card Profile -->
            <b-card no-body class="mb-4">
                <b-card-header>
                    <b-row>
                     <b-col md="8">
                        <p class="text-sm mb-0 text-uppercase font-weight-bold">course name</p>
                        <h5 class="font-weight-bolder">
                            {{course.clazzName}}
                        </h5>
                     </b-col>
                     <b-col md="4" class="text-end">
                        <p class="text-sm mb-0 text-uppercase font-weight-bold">publish state</p>
                        <h5 class="font-weight-bolder">
                            {{application.publishState}}
                        </h5>
                     </b-col>
                    </b-row>
                </b-card-header>
                <b-card-body body-class="p3">
                    <b-row>
                        <b-col md="3">
                            <div class="numbers">
                                <p class="text-sm mb-0 text-uppercase font-weight-bold">Instructor</p>
                                <b-avatar-group class="avatar-group">
                                    <b-link :href="'#/userProfile/' + item.userId" v-for="item in course.teachers"  v-bind:key="item.userId" :title="item.firstName + ' ' + item.lastName">
                                    <img v-if="item.avatar" alt="Image placeholder" :src="item.avatar + '?size=80x80'" class="avatar rounded-circle">
                                    <span v-if="!item.avatar" class="b-avatar bg-primary rounded-circle avatar text-uppercase"><span class="b-avatar-text text-light">{{getWord(item.firstName + ' ' + item.lastName)}}</span></span>
                                    </b-link>
                                </b-avatar-group>
                            </div>
                        </b-col>
                        <b-col md="3" class="text-center">
                            <div class="numbers">
                                <p class="text-sm mb-0 text-uppercase font-weight-bold">Price</p>
                                <h6>
                                    {{ course.free ? "Free" : course.currency + " " + (course.price / 100).toFixed(2)}}
                                </h6>
                            </div>
                        </b-col>
                        <b-col md="3" class="text-center">
                            <div class="numbers">
                                <p class="text-sm mb-0 text-uppercase font-weight-bold">Max Learners</p>
                                <h6>
                                    {{ course.maxStudents == 0 ? "Unlimited" : course.maxStudents}}
                                </h6>
                            </div>
                        </b-col>
                        <b-col class="text-end">
                            <div class="numbers">
                                <p class="text-sm mb-0 text-uppercase font-weight-bold">status</p>
                                <h6>
                                    {{course.clazzStatus}}
                                </h6>
                            </div>
                        </b-col>
                    </b-row>
                </b-card-body>
                <b-card-footer>
                    <b-row>
                        <b-col md="12">
                            <div class="numbers">
                                <p class="text-sm mb-0 text-uppercase font-weight-bold">Course Description</p>
                                <div v-html="course.introduction">
                                </div>
                            </div>
                        </b-col>
                    </b-row>
                </b-card-footer>
            </b-card>
        </div>
        <div class="col-lg-5 mt-lg-0 mt-4">
            <b-card no-body>
                <b-card-header>
                    <p class="text-sm mb-0 text-uppercase font-weight-bold">Learners</p>
                </b-card-header>
                <b-card-body>
                    <b-table-simple responsive class=" align-items-center mb-0">
                        <b-thead>
                            <b-tr>
                                <b-th class="text-uppercase text-secondary text-xxs font-weight-bolder opacity-7">name</b-th>
                                <b-th class="text-uppercase text-secondary text-xxs font-weight-bolder opacity-7 ps-2">email</b-th>
                                <b-th class="text-uppercase text-secondary text-xxs font-weight-bolder opacity-7 ps-2">progress Rate</b-th>
                            </b-tr>
                        </b-thead>
                        <b-tbody>
                            <b-tr v-for="item in students" v-bind:key="item">
                                <b-td><p class="text-sm text-secondary mb-0">{{item.firstName && item.lastName ? item.firstName + " " + item.lastName : item.accountName}}</p></b-td>
                                <b-td><p class="text-sm text-secondary mb-0">{{item.email}}</p></b-td>
                                <b-td><p class="text-sm text-secondary mb-0">{{item.progressRate + " %"}}</p></b-td>
                            </b-tr>
                        </b-tbody>
                    </b-table-simple>
                <div class="dataTable-bottom" v-if="total2 > paginationState2.size">
                        <div class="dataTable-info">
                            Showing {{(paginationState2.page - 1) * paginationState2.size + 1}} to {{paginationState2.page * paginationState2.size > total2 ? total2 : paginationState2.page * paginationState2.size}} of {{total2}} entries
                        </div>
                        <nav class="dataTable-pagination">

                        <b-pagination
                        v-model="paginationState2.page"
                        :total-rows="total2"
                        :per-page="parseInt(paginationState2.size)"
                        aria-controls="my-table"
                    ></b-pagination>
                        </nav>
                </div>
                </b-card-body>
            </b-card>
        </div>
    </div>
    <div  class="row">
        <div class="col-lg-12 mt-lg-0 mt-4">
            <b-card no-body>
                <b-card-header>
                    <p class="text-sm mb-0 text-uppercase font-weight-bold">Class Resources</p>
                </b-card-header>
                <b-card-body>
                    <b-table-simple responsive class=" align-items-center mb-0">
                        <b-thead>
                            <b-tr>
                                <b-th class="text-uppercase text-secondary text-xxs font-weight-bolder opacity-7">name</b-th>
                                <b-th class="text-uppercase text-secondary text-xxs font-weight-bolder opacity-7 ps-2">rating Setting Type</b-th>
                                <b-th class="text-uppercase text-secondary text-xxs font-weight-bolder opacity-7 ps-2">resource Type</b-th>
                                <b-th class="text-uppercase text-secondary text-xxs font-weight-bolder opacity-7 ps-2">creat Time</b-th>
                                <b-th class="text-uppercase text-secondary text-xxs font-weight-bolder opacity-7 ps-2">update Time</b-th>
                            </b-tr>
                        </b-thead>
                        <b-tbody>
                            <b-tr v-for="item in classResources" v-bind:key="item">
                                <b-td><p class="text-sm text-secondary mb-0">{{item.activityName}}</p></b-td>
                                <b-td><p class="text-sm text-secondary mb-0">{{item.ratingSettingType}}</p></b-td>
                                <b-td><p class="text-sm text-secondary mb-0">{{item.resourceType}}</p></b-td>
                                <b-td><p class="text-sm text-secondary mb-0">{{item.creatTime}}</p></b-td>
                                <b-td><p class="text-sm text-secondary mb-0">{{item.updateTime}}</p></b-td>
                            </b-tr>
                        </b-tbody>
                    </b-table-simple>
                <div class="dataTable-bottom" v-if="total > paginationState.size">
                        <div class="dataTable-info">
                            Showing {{(paginationState.page - 1) * paginationState.size + 1}} to {{paginationState.page * paginationState.size > total ? total : paginationState.page * paginationState.size}} of {{total}} entries
                        </div>
                        <nav class="dataTable-pagination">

                        <b-pagination
                        v-model="paginationState.page"
                        :total-rows="total"
                        :per-page="parseInt(paginationState.size)"
                        aria-controls="my-table"
                    ></b-pagination>
                        </nav>
                </div>
                </b-card-body>
            </b-card>
        </div>
    </div>
    <b-modal id="modalEditApplicationCategory" centered @ok="approve" title="Confirm Category.">
        <b-row class="my-1">
            <b-col sm="4" class=" my-auto text-end">
                <label class="my-auto">age range type:</label>
            </b-col>
            <b-col sm="8">
                <b-form-select v-model="age" :options="json.age" class="form-control" :state="ageState" 
                                        aria-describedby="age-feedback">
                    <b-form-select-option :value="null" v-if="age == ''">All ages</b-form-select-option>
                </b-form-select>
                <b-form-invalid-feedback id="age-feedback">
                    please select an age range
                </b-form-invalid-feedback>
            </b-col>
        </b-row>
        <b-row class="my-1">
            <b-col sm="4" class=" my-auto text-end">
                <label class="my-auto">category type:</label>
            </b-col>
            <b-col sm="8">
                <b-form-select v-model="category" :options="json.category" class="form-control"  :state="categoryState" 
                                        aria-describedby="category-feedback">
                    <b-form-select-option :value="null" v-if="category == ''">All categories</b-form-select-option>
                </b-form-select>
                <b-form-invalid-feedback id="category-feedback">
                    please select a category
                </b-form-invalid-feedback>
            </b-col>
        </b-row>
    </b-modal>
  <b-modal id="modalRejectConfirm" centered @ok="reject" title="Please confirm whether to reject the application.">
      <b-col>
          <b-form-input v-model="reason"
                                  :state="reasonState"
                                  aria-describedby="reason-feedback"
                                  placeholder="Enter reject reason"
                                  trim>
                              </b-form-input>
          <b-form-invalid-feedback id="reason-feedback">
              please enter reject reason
          </b-form-invalid-feedback>
      </b-col>
    </b-modal>
</div>
</template>
<script>
import { Dashboard } from '@/apiClient/Dashboard'
import BCard from 'bootstrap-vue-3/src/components/BCard/BCard.vue';
import { defineComponent,onMounted,ref,getCurrentInstance,watch,reactive  } from 'vue';
import { useRouter } from "vue-router";
import bus from '@/plugins/bus';
import json from '@/plugins/category.json'

let requestParams;
let id;
export default defineComponent({
  components: { BCard },
  name: 'applicationDetail',
    data() {
        return {
          reason: '',
          age:"",
          category:"",
          applicationId:0,
          json:json
        }
    },
    computed: {
      ageState() {
        return this.age == null || this.age == "" ? false : true
      },
      categoryState() {
        return this.category == null || this.category == "" ? false : true
      },
      reasonState(){
        return this.reason.length > 0 ? true : false
      }
    },
  methods: {
      getWord(f){
        f = f ? f : "";
        let arr = f.split(' ');
        let first = arr ? arr[0].slice(0,1) : "";
        let last = arr[1] ? arr[1].slice(0,1) : "";
        return first + last;
      },
      approve(){
        let api = new Dashboard();
        debugger
        let params = { ageRangeType: this.age,categoryType:this.category,id: id};
        api.approvedPublishApplicationUsingPost(params,requestParams).then((response) => {
            if(response.status == 200 && response.data != null){
              if(response.data.code == 0){
                bus.emit('success', response.data.message);
              }
              else{
                bus.emit('error', response.data.message);
              }
            }
        }).catch((error) => { bus.emit('error', error); });
      },
      reject(){
        let api = new Dashboard();
        let params = { id: id,rejectReason:this.reason};
        api.rejectedPublishApplicationUsingPost(params,requestParams).then((response) => {
            if(response.status == 200 && response.data != null){
              if(response.data.code == 0){
                bus.emit('success', response.data.message);
              }
              else{
                bus.emit('error', response.data.message);
              }
            }
        }).catch((error) => { bus.emit('error', error); });
      },
      setApplication(id,age,category){
        this.applicationId = id;
        this.age = age == null ? "" : age;
        this.category = category == null ? "" : category;
      },
      reload(){
        this.$router.go()
      }
  },
  setup(){
    const application=ref({});
    const course=ref({});
    const students = ref();
    const teacher = ref({});
    const classResources = ref();
    requestParams = getCurrentInstance().appContext.config.globalProperties.RequestParams;
    const router = useRouter();
    id = router.currentRoute.value.params.id;
    
    const total = ref(0);
    const paginationState = reactive({
        size: 10,
        page: 1,
        classId:0
    });
    const total2 = ref(0);
    const paginationState2 = reactive({
        size: 5,
        page: 1,
        classId:0
    });
    const call = (classId) =>{
      paginationState.classId = classId;
      let api = new Dashboard();
      let params = { classId: classId,page:paginationState.page - 1,size:paginationState.size };
      api.getAllClassResourceUsingGet(params,requestParams).then(rep=>{
        if(rep.status == 200 && rep.data != null){
          if(rep.data.code == 0){
            let data = rep.data.data;
            classResources.value = data.content;
            total.value = parseInt(rep.data?.data.totalElements);
          }
          else{
            bus.emit('error', rep.data.message);
          }
        }
      }).catch(function (error) {
          bus.emit('error', error);
      });
    }
    
    const call2 = (classId) =>{
      paginationState2.classId = classId;
      let api = new Dashboard();
      let params = { classId: classId,page:paginationState2.page - 1,size:paginationState2.size };
      api.getClassLearnersByClassIdUsingGet(params,requestParams).then(rep=>{
        if(rep.status == 200 && rep.data != null){
            if(rep.data.code == 0){
                let data = rep.data.data;
                students.value = data.content;
                total2.value = parseInt(rep.data?.data.totalElements);
            }
            else{
                bus.emit('error', rep.data.message);
            }
        }
      }).catch(function (error) {
          bus.emit('error',error);
      });
    }
    onMounted(()=>{
      let api = new Dashboard();
      let params = { id: id };

      api.getPublishApplicationByIdUsingGet(params,requestParams).then(rep=>{
        if(rep.status == 200 && rep.data != null){
          if(rep.data.code == 0){
            let data = rep.data.data;
            application.value = data;
            getClazz(data.clazz?.id);
            call2(data.clazz?.id);
            call(data.clazz?.id);
          }
          else{
            bus.emit('error', rep.data.message);
          }
        }
      }).catch(function (error) {
          bus.emit('error', error);
      });
    let getClazz = (classId) =>{ 
      api.getClassByIdUsingGet({classId: classId},requestParams).then(rep=>{
        if(rep.status == 200 && rep.data != null){
          if(rep.data.code == 0){
            let data = rep.data.data;
            course.value = data;
          }
          else{
            bus.emit('error', rep.data.message);
          }
        }
      }).catch(function (error) {
          bus.emit('error', error);
      });
    }
  })
      watch(()=> paginationState.page,()=>{
            call(paginationState.classId);
        },
        {
            deep:true
        });
      watch(()=> paginationState2.page,()=>{
            call2(paginationState2.classId);
        },
        {
            deep:true
        });
    return{
      application,
      course,
      students,
      teacher,
      classResources,
      paginationState,
      total,
      paginationState2,
      total2
    }
 
  }
});
</script>