<template>
  <Responsive class="w-full">
    <template #main="{ width }">
      <Chart
        :margin="margin"
        :direction="direction"
        :size="{ width, height: 520 }"
        :data="data"
      >
        <template #layers>
          <Grid strokeDasharray="2,2" />
          <Line :dataKeys="['month', 'playedDuration']" type="natural" />
          <!-- <Marker
            v-if="marker"
            :value="1000"
            label="Mean."
            color="green"
            strokeWidth="2"
            strokeDasharray="6 6"
          /> -->
        </template>

        <template #widgets>
          <Tooltip
            borderColor="#48CAE4"
            :config="{
              month: {label: 'Month' },
              playedDuration: { label: 'Watched Duration (Hrs)',color: '#0077b6',format:',.2f' },
              actualStorage: { label: 'Actual Storage (GB)',color: '#0077b6',format:',.2f' },
              awsCost: { label:'AWS Cost (USD)',color: 'red',format:',.2f' },
            }"
          />
        </template>
      </Chart>
    </template>
  </Responsive>
</template>

<script>
import { ref } from "vue";
import { Responsive, Chart, Grid, Line, Tooltip } from "vue3-charts";

export default {
  name: "ChartExample",
  props: {
    data: Array,
  },
  components: { Responsive, Chart, Grid, Line, Tooltip },
  setup() {
    const direction = ref("horizontal");
    const margin = ref({
      left: 0,
      top: 20,
      right: 20,
      bottom: 100,
    });

    return { direction, margin };
  },
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
.w-full {
  width: 100%;
}
</style>
