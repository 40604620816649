<template>
<div>
    <div class="container-fluid mt-6">
      <div class="card shadow-lg mt-4">
      <div class="card-body p-3">
        <div class="row gx-4">
          <div class="col-auto">
            <div>
              <img v-if="userProfile.avatar" :src="userProfile.avatar + '?size=80x80'" class="avatar rounded-circle avatar-xl position-relative">
              <span v-else-if="!userProfile.avatar" class="bg-primary rounded-circle avatar text-uppercase"><span class="text-light">{{getWord(userProfile.fullNameOrAccountName)}}</span></span>
            </div>
          </div>
          <div class="col-auto my-auto">
            <div class="h-100">
              <h5 class="mb-1">
                {{userProfile.fullNameOrAccountName}}
              </h5>
              <p class="mb-0 font-weight-bold text-sm" v-if="userProfile.email">
                 Email:{{userProfile.email}}
              </p>
            </div>
          </div>
          <div class="col-lg-4 col-md-6 my-sm-auto ms-sm-auto me-sm-0 mx-auto mt-3">
            <div class="nav-wrapper position-relative end-0">
              <ul class="nav nav-pills nav-fill p-1" role="tablist">
                <li class="nav-item">
                  <a class="nav-link mb-0 px-0 py-1 active d-flex align-items-center justify-content-center " :href="'#/userProfile/' + userProfile.id" role="tab" aria-selected="true">
                    <i class="ni ni-app"></i>
                    <span class="ms-2">Profile</span>
                  </a>
                </li>
                <li class="nav-item">
                  <a class="nav-link mb-0 px-0 py-1 d-flex align-items-center justify-content-center " data-bs-toggle="tab" href="javascript:;" role="tab" aria-selected="false">
                    <span class="ms-2">Payment</span>
                  </a>
                </li>
              </ul>
            </div>
          </div>
        </div>
      </div>
    </div>
    </div>
    <div class="container-fluid my-5 py-2">
      <div class="row"  v-if="tapAccount || stripeAccount">
        <div class="col-lg-12">
          <div class="row">
            <div class="col-md-12 mb-lg-0 mb-4">
              <div class="card mt-4">
                <div class="card-header pb-0 p-3">
                  <div class="row">
                    <div class="col-6 d-flex align-items-center">
                      <h6 class="mb-0">Payment Method</h6>
                    </div>
                    <div class="col-6 text-end">
                    </div>
                  </div>
                </div>
                <div class="card-body p-3">
                  <div class="row">
                    <div class="col-md-6 mb-md-0 mb-4" v-if="tapAccount">
                      <ul class="card card-body border card-plain border-radius-lg d-flex align-items-center flex-row">
                        <li class="list-group-item border-0 d-flex p-4 mb-2 border-radius-lg">
                          <div class="d-flex flex-column">
                            <h3 class="mb-3 text-sm">Tap Account</h3>
                            <h6 class="mb-2 text-xs">Legal Name : {{tapAccount.legalName}}</h6>
                            <h6 class="mb-2 text-xs">Email : {{tapAccount.email}}</h6>
                            <h6 class="mb-2 text-xs">Country Code: {{tapAccount.countryCode}}</h6>
                            <h6 class="mb-2 text-xs">IBAN Number : {{tapAccount.iban}}</h6>
                            <h6 class="mb-2 text-xs">Phone Number : {{"(" + tapAccount.phoneCountryCode + ") " + tapAccount.phoneNumber}}</h6>
                          </div>
                        </li>
                      </ul>
                    </div>
                    <div class="col-md-6" v-if="stripeAccount">
                      <ul class="card card-body border card-plain border-radius-lg d-flex align-items-center flex-row">
                        <li class="list-group-item border-0 d-flex p-4 mb-2 border-radius-lg">
                          <div class="d-flex flex-column">
                            <h3 class="mb-3 text-sm">Stripe Account</h3>
                            <h6 class="mb-2 text-xs">Account ID : {{stripeAccount.stripeId}}</h6>
                            <h6 class="mb-2 text-xs">Email : {{stripeAccount.email}}</h6>
                            <h6 class="mb-2 text-xs">Country Code : {{stripeAccount.country}}</h6>
                            <h6 class="mb-2 text-xs">Currency : {{stripeAccount.defaultCurrency}}</h6>
                          </div>
                        </li>
                      </ul>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="row mt-4">
        <b-card no-body>
                <b-card-header class="row">
                  <div class="col-md-8">
                    <h6 class="mb-0">Your Transaction's</h6>
                  </div>
                  <div class="col-md-2 d-flex justify-content-end align-items-center">
                    <b-form-input type="date" class="dataTable-input" v-model="paginationState.startDate"></b-form-input>
                  </div>
                  <div class="col-md-2 d-flex justify-content-end align-items-center">
                    <b-form-input type="date" class="dataTable-input"  placeholder="Choose a date" locale="en" v-model="paginationState.endDate"></b-form-input>
                  </div>
                </b-card-header>
                <b-card-body>
                    <b-table-simple responsive class=" align-items-center mb-0">
                        <b-thead>
                            <b-tr>
                                <b-th class="text-uppercase text-secondary text-xxs font-weight-bolder opacity-7">Plan Type</b-th>
                                <b-th class="text-uppercase text-secondary text-xxs font-weight-bolder opacity-7 ps-2">Amount</b-th>
                                <b-th class="text-uppercase text-secondary text-xxs font-weight-bolder opacity-7 ps-2">Currency</b-th>
                                <b-th class="text-uppercase text-secondary text-xxs font-weight-bolder opacity-7 ps-2">license Count</b-th>
                                <b-th class="text-uppercase text-secondary text-xxs font-weight-bolder opacity-7 ps-2">create Time</b-th>
                                <b-th class="text-uppercase text-secondary text-xxs font-weight-bolder opacity-7 ps-2">pay status</b-th>
                            </b-tr>
                        </b-thead>
                        <b-tbody>
                            <b-tr v-for="item in planBills" v-bind:key="item">
                                <b-td><p class="text-sm text-secondary mb-0">{{getPlan(item.planType)}}</p></b-td>
                                <b-td><p class="text-sm text-secondary mb-0">{{(item.amount / 100).toFixed(2)}}</p></b-td>
                                <b-td><p class="text-sm text-secondary mb-0">{{item.currency}}</p></b-td>
                                <b-td><p class="text-sm text-secondary mb-0">{{item.licenseCount}}</p></b-td>
                                <b-td><p class="text-sm text-secondary mb-0">{{item.createTime}}</p></b-td>
                                <b-td><p class="text-sm text-secondary mb-0">{{item.status}}</p></b-td>
                            </b-tr>
                        </b-tbody>
                    </b-table-simple>
                <div class="dataTable-bottom" v-if="total > paginationState.size">
                        <div class="dataTable-info">
                            Showing {{(paginationState.page - 1) * paginationState.size + 1}} to {{paginationState.page * paginationState.size > total ? total : paginationState.page * paginationState.size}} of {{total}} entries
                        </div>
                        <nav class="dataTable-pagination">

                        <b-pagination
                        v-model="paginationState.page"
                        :total-rows="total"
                        :per-page="parseInt(paginationState.size)"
                        aria-controls="my-table"
                    ></b-pagination>
                        </nav>
                </div>
                </b-card-body>
            </b-card>
      </div>
    </div>
</div>
</template>
<script>
import { Dashboard } from '@/apiClient/Dashboard'
import { defineComponent,onMounted,ref,getCurrentInstance,reactive,watch } from 'vue';
import { useRouter } from "vue-router";
import bus from '@/plugins/bus';
import json from '@/plugins/category.json'

let requestParams;
let userid;
export default defineComponent({
  name: 'userProfile',
  data() {
      return {
        planType: '',
        licenseCount:0,
        id:0,
        json:json
      }
  },
  methods: {
      getWord(f){
        f = f ? f : "";
        let arr = f.split(' ');
        let first = arr ? arr[0].slice(0,1) : "";
        let last = arr[1] ? arr[1].slice(0,1) : "";
        return first + last;
      },
      getPlan(key){
          return this.json.planType[key];
      }
  },
  setup(){
    const userProfile=ref({});
    const tapAccount=ref({});
    const stripeAccount=ref({});
    const total = ref(0);
    const paginationState = reactive({
            size: 10,
            page: 1,
            startDate:'',
            endDate:''
        });
    const planBills=ref();
    const purchaseOrders=ref();
    requestParams = getCurrentInstance().appContext.config.globalProperties.RequestParams;
    const router = useRouter();
    userid = router.currentRoute.value.params.id;
    const call = () =>{
      let api = new Dashboard();
      let orderParams = { userId: userid,startDate:paginationState.startDate,endDate:paginationState.endDate,page:paginationState.page - 1,size:paginationState.size};
        api.getUserPlanBillsUsingGet(orderParams,requestParams).then(rep=>{
        if(rep.status == 200 && rep.data != null){
          if(rep.data.code == 0){
            let data = rep.data.data;
            planBills.value = data.content;
            total.value = parseInt(rep.data?.data.totalElements);
          }
          else{
            bus.emit('error', rep.data.message);
          }
        }
      }).catch(function (error) {
          bus.emit('error', error);
      });
    };
    onMounted(()=>{
      let api = new Dashboard();
      let params = { userId: userid };

      api.getUserProfileUsingGet(params,requestParams).then(rep=>{
        if(rep.status == 200 && rep.data != null){
          if(rep.data.code == 0){
            let data = rep.data.data;
            userProfile.value = data;
          }
          else{
            bus.emit('error', rep.data.message);
          }
        }
      }).catch(function (error) {
          bus.emit('error', error);
      });

      
      call();
      api.getUserTapAccountUsingGet(params,requestParams).then(rep=>{
        if(rep.status == 200 && rep.data != null){
          if(rep.data.code == 0){
            let data = rep.data.data;
            tapAccount.value = data;
          }
          else{
            bus.emit('error', rep.data.message);
          }
        }
      }).catch(function (error) {
          bus.emit('error', error);
      });

      api.getUserStripeAccountUsingGet(params,requestParams).then(rep=>{
        if(rep.status == 200 && rep.data != null){
          if(rep.data.code == 0){
            let data = rep.data.data;
            stripeAccount.value = data;
          }
          else{
            bus.emit('error', rep.data.message);
          }
        }
      }).catch(function (error) {
          bus.emit('error', error);
      });
  });
  watch(()=> paginationState.page,()=>{
      call();
  },
  {
      deep:true
  });
    watch(()=> [paginationState.startDate,paginationState.endDate],()=>{
      paginationState.page = 1;
      call();
  },
  {
      deep:true
  });
    return{
      userProfile,
      purchaseOrders,
      planBills,
      tapAccount,
      stripeAccount,
      paginationState,
      total
    }
 
  }
});
</script>