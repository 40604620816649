<template>
    <v-select placeholder="Enter email address" :append-to-body="append_to_body"  @open="onOpen" @close="onClose" label="accountName" :options="userList" @search="searchUser" :reduce="user => user.id" v-model="userId">
        <template #list-footer>
            <li v-show="hasNextPage" ref="load" class="loader">
                &nbsp;&nbsp;&nbsp;&nbsp;Loading more options...
            </li>
        </template>
    </v-select>
</template>
<script>
import { Dashboard } from '@/apiClient/Dashboard'
import { getCurrentInstance} from 'vue';
import bus from '@/plugins/bus';
import vSelect from "vue-select";
import "vue-select/dist/vue-select.css";

let requestHeader;
export default{
    components:{vSelect},
    props:['append_to_body'],
    data() {
        return {
          userList:[],
          hasNextPage:false,
          observer: null,
          limit: 20,
          index:0,
          serach:"",
          userId:null
        }
    },
    mounted() {
        this.observer = new IntersectionObserver(this.infiniteScroll)
    },
    methods:{
      searchUser(name) {
        if(name == ""){
            this.index = 0;
            this.hasNextPage = false;
            return;
        }
        if(this.serach != name){
            this.index = 0;
            this.userList = [];
            this.hasNextPage = false;
        }
        this.serach = name;
        let api = new Dashboard();
        let params = { keyword:name,page:this.index,size:this.limit };
        api.getUserListUsingGet(params,requestHeader).then((response) => {
            if(response.status == 200 && response.data != null){
                if(response.data.code == 0){
                    this.userList = this.userList.concat(response.data?.data?.content);
                    this.hasNextPage = !response.data?.data?.last;
                    this.onOpen();
                }
            }
        }).catch((error) => { bus.emit('error', error); });
      },
      async onOpen() {
        if (this.hasNextPage) {
            await this.$nextTick()
            this.observer.observe(this.$refs.load)
        }
      },
      onClose() {
        this.observer.disconnect()
      },
      async infiniteScroll([{ isIntersecting, target }]) {
        if (isIntersecting) {
            const ul = target.offsetParent
            const scrollTop = target.offsetParent.scrollTop
            this.index += 1
            await this.$nextTick()
            ul.scrollTop = scrollTop
            this.searchUser(this.serach);
        }
      }
    },
    setup(){
        requestHeader = getCurrentInstance().appContext.config.globalProperties.RequestParams
    }
}
</script>
