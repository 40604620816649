import { createRouter, createWebHashHistory } from 'vue-router'
import DashboardLayout from "@/layout/DashboardLayout.vue";
import Dashboard from "@/views/Dashboard.vue";
import UserList from "@/views/User/UserList.vue";
import AdminUserList from "@/views/User/AdminUserList.vue";
import UserRole from "@/views/User/UserRole.vue";
import UserProfile from "@/views/User/UserProfile.vue";
import UserBilling from "@/views/User/UserBilling.vue";
import UserPlan from "@/views/User/UserPlanList.vue";
import SchoolList from "@/views/School/School.vue";
import CourseList from "@/views/Class/Courses.vue";
import SchoolDetail from "@/views/School/SchoolDetail.vue";
import SchoolOwnerList from "@/views/School/SchoolOwnerList.vue";
import SchoolOwnerDetail from "@/views/School/SchoolOwnerDetail.vue";
import SchoolAnalysis from "@/views/School/SchoolAnalysis.vue";
import CourseDetail from "@/views/Class/CourseDetail.vue";
import StudentResource from "@/views/Resource/StudentResource.vue";
import StudentResourceDetail from "@/views/Resource/StudentResourceDetail.vue";
import StudentStatistic from "@/views/Statistic/StudentStatistic.vue";
import TeacherStatistic from "@/views/Statistic/TeacherStatistic.vue";
import MarketplaceCourse from "@/views/Class/MarketplaceCourse.vue";
import TopStudentsEnrolledMarketplaceCourse from "@/views/Class/TopStudentsEnrolledMarketplaceCourse.vue";
import PublishApplication from "@/views/Class/PublishApplication.vue";
import PublishApplicationDetail from "@/views/Class/PublishApplicationDetail.vue";
import TodayActiveStudentList from "@/views/User/TodayActiveStudentList.vue";
import TodayActiveTeacherList from "@/views/User/TodayActiveTeacherList.vue";
import TodaySignupTeacherList from "@/views/User/TodaySignupTeacherList.vue";
import TodaySignupStudentList from "@/views/User/TodaySignupStudentList.vue";
import WeekSignupStudentList from "@/views/User/WeekSignupStudentList.vue";
import WeekSignupTeacherList from "@/views/User/WeekSignupTeacherList.vue";
import PromoCodeList from "@/views/PromoCode/PromoCodeList.vue";
import PaymentCodeList from "@/views/Payment/PaymentCodeList.vue";
import ClassCategory from "@/views/Class/ClassCategory.vue";
import GenerateSeo from "@/views/Class/GenerateSeo.vue";
import GenerateSeoList from "@/views/Class/GenerateSeoList.vue";
import PlanItem from "@/views/User/PlanItem.vue";
import Login from "@/views/Login.vue";
import store from '@/store';

const routes = [
  {
    path: "/",
    redirect: "/dashboard",
    component: DashboardLayout,
    children: [
      {
        path: "/dashboard",
        name: "dashboard",
        components: { default: Dashboard },
      },
      {
        path: "/user",
        name: "user",
        components: { default: UserList },
      },
      {
        path: "/adminuser",
        name: "adminuser",
        components: { default: AdminUserList },
      },
      {
        path: "/userRole/:id",
        name: "userRole",
        components: { default: UserRole },
      },
      {
        path: "/userProfile/:id",
        name: "userProfile",
        components: { default: UserProfile },
      },
      {
        path: "/userBilling/:id",
        name: "userBilling",
        components: { default: UserBilling },
      },
      {
        path: "/school",
        name: "school",
        components: { default: SchoolList },
      },
      {
        path: "/school/analysis/:id",
        name: "schoolAnalysis",
        components: { default: SchoolAnalysis },
      },
      {
        path: "/school/:id",
        name: "schoolDetail",
        components: { default: SchoolDetail },
      },
      {
        path: "/schoolOwner",
        name: "schoolOwner",
        components: { default: SchoolOwnerList },
      },
      {
        path: "/schoolOwner/:id",
        name: "schoolOwnerDetail",
        components: { default: SchoolOwnerDetail },
      },
      {
        path: "/course",
        name: "course",
        components: { default: CourseList },
      },
      {
        path: "/course/:id",
        name: "courseDetail",
        components: { default: CourseDetail },
      },
      {
        path: "/studentResource",
        name: "studentResource",
        components: { default: StudentResource },
      },
      {
        path: "/studentResource/:id",
        name: "studentResourceDetail",
        components: { default: StudentResourceDetail },
      },
      {
        path: "/teacherStatistic",
        name: "teacherStatistic",
        components: { default: TeacherStatistic },
      },
      {
        path: "/studentStatistic",
        name: "studentStatistic",
        components: { default: StudentStatistic },
      },
      {
        path: "/marketplaceCourse",
        name: "marketplaceCourse",
        components: { default: MarketplaceCourse },
      },
      {
        path: "/publishApplication",
        name: "publishApplication",
        components: { default: PublishApplication },
      },
      {
        path: "/publishApplication/:id",
        name: "publishApplicationDetail",
        components: { default: PublishApplicationDetail },
      },
      {
        path: "/activeStudent",
        name: "activeStudent",
        components: { default: TodayActiveStudentList },
      },
      {
        path: "/activeTeacher",
        name: "activeTeacher",
        components: { default: TodayActiveTeacherList },
      },
      {
        path: "/signupStudent",
        name: "signupStudent",
        components: { default: TodaySignupStudentList },
      },
      {
        path: "/signupTeacher",
        name: "signupTeacher",
        components: { default: TodaySignupTeacherList},
      },
      {
        path: "/weekSignupStudent",
        name: "weekSignupStudent",
        components: { default: WeekSignupStudentList },
      },
      {
        path: "/weekSignupTeacher",
        name: "weekSignupTeacher",
        components: { default: WeekSignupTeacherList },
      },
      {
        path: "/promoCode",
        name: "promoCode",
        components: { default: PromoCodeList },
      },
      {
        path: "/paymentCode",
        name: "paymentCode",
        components: { default: PaymentCodeList },
      },
      {
        path: "/topEnrolledClasses",
        name: "topEnrolledClasses",
        components: { default: TopStudentsEnrolledMarketplaceCourse },
      },
      {
        path: "/planItem",
        name: "planItem",
        components: { default: PlanItem },
      },
      {
        path: "/userPlan",
        name: "UserPlan",
        components: { default: UserPlan },
      },
      {
        path: "/classCategory",
        name: "ClassCategory",
        components: { default: ClassCategory },
      },
      {
        path: "/seo",
        name: "GenerateSeoList",
        components: { default: GenerateSeoList },
      },
      {
        path: "/seo/:slug",
        name: "GenerateSeo",
        components: { default: GenerateSeo },
      }
      // {
      //   path: "/schoolDetail/:id",
      //   name: "schoolDetail",
      //   components: { default: UserBilling },
      // },
    ],
    meta: {
      title: '首页',
      isLogin: true
    }
  },
  {
    path: '/login',
    name: 'login',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    components: { default: Login }
  }
  
]

const router = createRouter({
  history: createWebHashHistory(process.env.BASE_URL),
  linkActiveClass: "active",
  routes
})

export default router
router.beforeEach((to, from, next) => {
 
  const getFlag = localStorage.getItem("Flag");
  const getid = localStorage.getItem("userId");
  const getToken = localStorage.getItem("token");
  if (getFlag === "isLogin"&& getid !== null && getToken !== null ) {
      store.state.isLogin = true;
      next();
      if (!to.meta.isLogin) {
          next({
              path: '/'
          })
      }
  } else {
      if (to.meta.isLogin) {
          next({
              path: '/login',
          })
      } else {
          next()
      }

  }

});

router.afterEach(() => {
  window.scroll(0, 0);
});