<template>
<div class="container-fluid my-5 py-2">
    <div class="row mb-5">
    <div class="col-lg-12 mt-lg-0 mt-4">
        <!-- Card Profile -->
        <div class="card card-body" id="profile">
        <div class="row justify-content-center align-items-center">
            <div class="col-sm-auto col-4">
            <div class="d-flex">
              <img v-if="userProfile.avatar" :src="userProfile.avatar + '?size=80x80'" class="avatar rounded-circle avatar-xl position-relative">
              <span v-else-if="!userProfile.avatar" class="bg-primary rounded-circle avatar text-uppercase"><span class="text-light">{{getWord(userProfile.fullNameOrAccountName)}}</span></span>
            </div>
            </div>
            <div class="col-sm-auto col-8 my-auto">
            <div class="h-100">
                <h5 class="mb-1 font-weight-bolder">
                {{userProfile.fullNameOrAccountName}}
                </h5>
                <p class="mb-0 font-weight-bold text-sm" v-if="userProfile.email">
                    Email:{{userProfile.email}}
                </p>
            </div>
        </div>
            
          <div class="col-lg-4 col-md-6 my-sm-auto ms-sm-auto me-sm-0 mx-auto mt-3">
            <div class="nav-wrapper position-relative end-0">
              <ul class="nav nav-pills nav-fill p-1" role="tablist">
                <li class="nav-item">
                  <a class="nav-link mb-0 px-0 py-1 active d-flex align-items-center justify-content-center " data-bs-toggle="tab" href="javascript:;" role="tab" aria-selected="true">
                    <i class="ni ni-app"></i>
                    <span class="ms-2">Profile</span>
                  </a>
                </li>
                <li class="nav-item" v-if="userProfile.userRole == 'teacher' && hasPermission('ADMIN_USER_MANAGE')">
                  <a class="nav-link mb-0 px-0 py-1 d-flex align-items-center justify-content-center " :href="'#/userBilling/' + userProfile.id" role="tab" aria-selected="false">
                    <span class="ms-2">Payment</span>
                  </a>
                </li>
              </ul>
            </div>
          </div>
        </div>
        </div>
        <!-- Card Sessions -->
        <div class="card mt-4" id="sessions">
        <div class="card-header pb-3">
            <h5>Sessions</h5>
            <p class="text-sm">This is a list of devices that have logged into your account. Remove those that you do not recognize.</p>
        </div>
        <div class="card-body pt-0">
            <div class="d-flex align-items-center">
            <div class="text-center w-5">
                <i class="fas fa-desktop text-lg opacity-6"></i>
            </div>
            <div class="my-auto ms-3">
                <div class="h-100">
                <p class="text-sm mb-1">
                    Bucharest 68.133.163.201
                </p>
                <p class="mb-0 text-xs">
                    Your current session
                </p>
                </div>
            </div>
            <span class="badge badge-success badge-sm my-auto ms-auto me-3">Active</span>
            <p class="text-secondary text-sm my-auto me-3">EU</p>
            <a href="javascript:;" class="text-primary text-sm icon-move-right my-auto">See more
                <i class="fas fa-arrow-right text-xs ms-1" aria-hidden="true"></i>
            </a>
            </div>
            <hr class="horizontal dark">
            <div class="d-flex align-items-center">
            <div class="text-center w-5">
                <i class="fas fa-desktop text-lg opacity-6"></i>
            </div>
            <p class="my-auto ms-3">Chrome on macOS</p>
            <p class="text-secondary text-sm ms-auto my-auto me-3">US</p>
            <a href="javascript:;" class="text-primary text-sm icon-move-right my-auto">See more
                <i class="fas fa-arrow-right text-xs ms-1" aria-hidden="true"></i>
            </a>
            </div>
            <hr class="horizontal dark">
            <div class="d-flex align-items-center">
            <div class="text-center w-5">
                <i class="fas fa-mobile text-lg opacity-6"></i>
            </div>
            <p class="my-auto ms-3">Safari on iPhone</p>
            <p class="text-secondary text-sm ms-auto my-auto me-3">US</p>
            <a href="javascript:;" class="text-primary text-sm icon-move-right my-auto">See more
                <i class="fas fa-arrow-right text-xs ms-1" aria-hidden="true"></i>
            </a>
            </div>
        </div>
        </div>
    </div>
    </div>
</div>
</template>
<script>
import { Dashboard } from '@/apiClient/Dashboard'
import { defineComponent,onMounted,ref,getCurrentInstance } from 'vue';
import { useRouter } from "vue-router";
import bus from '@/plugins/bus';

let requestParams;
let userid;
export default defineComponent({
  name: 'userProfile',
  methods: {
      getWord(f){
        f = f ? f : "";
        let arr = f.split(' ');
        let first = arr ? arr[0].slice(0,1) : "";
        let last = arr[1] ? arr[1].slice(0,1) : "";
        return first + last;
      },
      hasPermission(val){
        let auth = localStorage.getItem('auth');
        if(auth){
          if(auth.indexOf(val) > -1){
            return true;
          }
          else{
            return false;
          }
        }
        else{
          return false;
        }
        
      }
  },
  setup(){
    const userProfile=ref({});
    requestParams = getCurrentInstance().appContext.config.globalProperties.RequestParams;
    const router = useRouter();
    userid = router.currentRoute.value.params.id;
    onMounted(()=>{
      let api = new Dashboard();
      let params = { userId: userid };

      api.getUserProfileUsingGet(params,requestParams).then(rep=>{
        if(rep.status == 200 && rep.data != null){
          if(rep.data.code == 0){
            let data = rep.data.data;
            userProfile.value = data;
          }
          else{
            bus.emit('error', rep.data.message);
          }
        }
      }).catch(function (error) {
          bus.emit('error', error);
      });
  })
    return{
      userProfile
    }
 
  }
});
</script>