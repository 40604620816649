<template>
<div class="row">
    <div class="col-12">
        <div class="card">
            <!-- Card header -->
            <div class="card-header pb-0">
                <div class="d-lg-flex">
                <div>
                    <h5 class="mb-0">All user plans</h5>
                    <p class="text-sm mb-0">
                    User plan list
                    </p>
                </div>
                </div>
            </div>
            <div class="card-body px-0 pb-0">
                <div class="table-responsive">
                    <div class="dataTable-wrapper dataTable-loading no-footer sortable searchable fixed-columns">
                        <div class="dataTable-top">
                            <div class="dataTable-dropdown">
                                <label><select class="dataTable-selector" v-model="paginationState.size">
                                    <option :value="5">5</option>
                                    <option :value="10">10</option>
                                    <option :value="15">15</option>
                                    <option :value="20">20</option>
                                    <option :value="25">25</option></select>
                                     entries per page</label>
                            </div>
                            <div class="dataTable-search">
                                <!-- <input class="dataTable-input" placeholder="Search..." type="text" v-model="paginationState.keyword"> -->
                            </div>
                        </div>
                        <div class="dataTable-container">
                            <table class="table table-flush" id="user-list">
                                <thead class="thead-light">
                                    <tr>
                                      <th>plan type</th>
                                      <th>Student Capacity</th>
                                      <th>Teacher Capacity</th>
                                      <th>yearly Price</th>
                                      <th>enable Register Student</th>
                                      <th>zoomType</th>
                                      <th>Action</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    <tr v-for="item in list"  v-bind:key="item">
                                    <td class="text-sm">{{getPlan(item.planType)}}</td>
                                    <td class="text-sm">{{item.totalStudentCapacity == -1 ? "unlimited" : item.totalStudentCapacity}}</td>
                                    <td class="text-sm">{{item.totalTeacherCapacity == -1 ? "unlimited" : item.totalTeacherCapacity}}</td>
                                    <td class="text-sm">{{item.yearlyPrice == 0 ? "It's Free" : (item.yearlyPrice == -1 ? "Let's Chat" : (item.yearlyPrice/ 100).toFixed(2) + " " + item.currency)}}</td>
                                    <td class="text-sm">{{item.enableRegisterStudent ? "Yes" : "No"}}</td>
                                    <td class="text-sm">{{getZoomType(item.zoomType)}}</td>
                                    <td class="text-sm">
                                        <a href="javascript:;" data-bs-toggle="tooltip" v-b-modal.modalEditUserPlan data-bs-original-title="Edit user" @click="setData(item.planType,item.enableRegisterStudent)">
                                        <i class="fas fa-user-edit text-secondary"></i>
                                        </a>
                                    </td>
                                    </tr>
                                </tbody>
                            </table>
                        </div>
                        <div class="dataTable-bottom" v-if="total > paginationState.size">
                        <div class="dataTable-info">
                            Showing {{(paginationState.page - 1) * paginationState.size + 1}} to {{paginationState.page * paginationState.size > total ? total : paginationState.page * paginationState.size}} of {{total}} entries
                        </div>
                        <nav class="dataTable-pagination">

                        <b-pagination
                        v-model="paginationState.page"
                        :total-rows="total"
                        :per-page="parseInt(paginationState.size)"
                        aria-controls="my-table"
                    ></b-pagination>
                        </nav>
                    </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
    <b-modal id="modalEditUserPlan" centered @ok="updatePlan" title="Edit plan item.">
        <b-row class="my-1">
            <b-col sm="5" class=" my-auto text-end">
                <label class="my-auto">plan type:</label>
            </b-col>
            <b-col sm="7">
                {{ getPlan(planType) }}
            </b-col>
        </b-row>
        <b-row class="my-1">
            <b-col sm="5" class=" my-auto text-end">
                <label class="my-auto">enable register student:</label>
            </b-col>
            <b-col sm="7">
                <div class="form-check form-switch">
                    <input class="form-check-input" v-model="enableRegisterStudent" type="checkbox" id="flexSwitchCheckDefault" checked="">
                </div>
            </b-col>
        </b-row>
    </b-modal>
</div>
</template>

<script>
import { Dashboard } from '@/apiClient/Dashboard'
import { onMounted,ref,getCurrentInstance,watch,reactive } from 'vue';
import bus from '@/plugins/bus';
import json from '@/plugins/category.json'

let requestHeader;
export default {
    name:"planItemList",
    data() {
        return {
          planType: '',
          enableRegisterStudent:false,
          json:json,
          options:{}
        }
    },
    computed: {
      planTypeState() {
        return this.planType == null || this.age == "" ? false : true
      },
      licenseCountState() {
        return this.licenseCount > 0 ? true : false
      }
    },
    methods:{
      getWord(f){
        f = f ? f : "";
        let arr = f.split(' ');
        let first = arr ? arr[0].slice(0,1) : "";
        let last = arr[1] ? arr[1].slice(0,1) : "";
        return first + last;
      },
      updatePlan(){
        let api = new Dashboard();
        let params = { planType:this.planType,enableRegisterStudent:this.enableRegisterStudent};
        api.updatePlanItemUsingPost(params,requestHeader).then((response) => {
            if(response.status == 200 && response.data != null){
              if(response.data.code == 0){
                bus.emit('success', response.data.message);
              }
              else{
                bus.emit('error', response.data.message);
              }
            }
        }).catch((error) => { console.log(error); });
      },
      setData(planType,enableRegisterStudent){
        this.planType = planType;
        this.enableRegisterStudent = enableRegisterStudent;
      },
      reload(){
        this.$router.go()
      },
      getPlan(key){
          return this.json.planType[key];
      },
      getZoomType(key){
          return this.json.zoomType[key];
      }
    },
    setup(){
        const list=ref();
        const total = ref(0);
        const paginationState = reactive({
            size: 10,
            page: 1,
            planType:null
        });
        requestHeader = getCurrentInstance().appContext.config.globalProperties.RequestParams
        const call = () =>{
            bus.emit('load', true);     
            let api = new Dashboard();
            let params = { page:paginationState.page - 1,size:paginationState.size };
            api.getPlanItemsUsingGet(params,requestHeader).then((response) => {
                if(response.status == 200 && response.data != null){
                    if(response.data.code == 0){
                        list.value= response.data?.data?.content;
                        total.value = parseInt(response.data?.data.totalElements)
                    }
                }
            }).catch((error) => { bus.emit('error', error); }).finally(()=>{ bus.emit('load', false); });
        }
        onMounted(()=>{
            call();
        });
        watch(()=> paginationState.page,()=>{
            call();
        },
        {
            deep:true
        });
         watch(()=> [paginationState.size,paginationState.keyword],()=>{
            paginationState.page = 1;
            call();
        },
        {
            deep:true
        });
        return{
            paginationState,
            list,
            total
        }
    }
}
</script>