<template>
<div class="row">
    <div class="col-12">
        <div class="card">
            <!-- Card header -->
            <div class="card-header pb-0">
                <div class="d-lg-flex">
                <div>
                    <h5 class="mb-0">All class category information</h5>
                    <p class="text-sm mb-0">
                    Class category information list
                    </p>
                </div>
                <div class="ms-auto my-auto mt-lg-0 mt-4">
                  <div class="ms-auto my-auto">
                    <button type="button" class="btn btn-outline-primary btn-sm mb-0" data-bs-toggle="modal" data-bs-target="#addUser" @click="clean()">
                      Add category information
                    </button>
                    <div class="modal fade" id="addUser" tabindex="1" aria-hidden="true">
                      <div class="modal-dialog mt-lg-10">
                        <b-overlay
                            id="overlay-background"
                            :show="showLoading"
                            rounded="sm"
                            >
                            <div class="modal-content">
                            <div class="modal-header">
                                <h5 class="modal-title" id="ModalLabel">Add category information</h5>
                                <i class="fas fa-plus ms-3"></i>
                                <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
                            </div>
                            <div class="modal-body">
                                <p>You can enter category information.</p>
                                <b-row class="my-1">
                                    <b-col sm="3" class=" my-auto text-end">
                                        <label>* category:</label>
                                    </b-col>
                                    <b-col sm="9">
                                        <b-form-select v-model="categoryInfo.classCategoryType" placeholder="please select a category" :options="json.category" class="form-control">
                                            <b-form-select-option :value="null" v-if="categoryInfo.classCategoryType == null">Learn space default category</b-form-select-option>
                                        </b-form-select>
                                    </b-col>
                                </b-row>
                                <b-row class="my-1">
                                    <b-col sm="3" class=" my-auto text-end">
                                        <label>* title:</label>
                                    </b-col>
                                    <b-col sm="9">
                                        <b-form-input v-model="categoryInfo.categoryTitle" :state="titleState" 
                                            aria-describedby="title-feedback"
                                            placeholder="Enter your title" >
                                        </b-form-input>
                                        <b-form-invalid-feedback id="title-feedback">
                                            please enter your title
                                        </b-form-invalid-feedback>
                                    </b-col>
                                </b-row>
                                <b-row class="my-1">
                                    <b-col sm="3" class=" my-auto text-end">
                                        <label>* description:</label>
                                    </b-col>
                                    <b-col sm="9">
                                        <b-form-textarea v-model="categoryInfo.categoryDescription" placeholder="Enter your description" :state="descriptionState" aria-describedby="description-feedback">
                                        </b-form-textarea>
                                        <b-form-invalid-feedback id="description-feedback">
                                            please enter your description
                                        </b-form-invalid-feedback>
                                    </b-col>
                                </b-row>
                                <b-row class="my-1">
                                    <b-col sm="3" class=" my-auto text-end">
                                        <label class="my-auto">* language:</label>
                                    </b-col>
                                    <b-col sm="9">
                                        <b-form-select v-model="categoryInfo.language" :options="json.language" class="form-control" :state="languageState" aria-describedby="language-feedback">
                                        </b-form-select>
                                        <b-form-invalid-feedback id="language-feedback">
                                            please select a language
                                        </b-form-invalid-feedback>
                                    </b-col>
                                </b-row>
                                <b-row class="my-1">
                                    <b-col sm="3" class=" my-auto text-end">
                                        <label class="my-auto">* background image:</label>
                                    </b-col>
                                    <b-col sm="9">
                                        <input type="File"
                                        id="file"
                                        class="form-control"
                                        @change="validyImage"
                                        />
                                    </b-col>
                                </b-row>
                            </div>
                            <div class="modal-footer">
                                <button type="button" class="btn bg-gradient-secondary btn-sm" data-bs-dismiss="modal">Cancel</button>
                                <button type="button" class="btn bg-gradient-primary btn-sm" data-bs-dismiss="modal" @click="add()">Add</button>
                            </div>
                            </div>
                        </b-overlay>
                      </div>
                    </div>
                  </div>
                </div>
                </div>
            </div>
            <div class="card-body px-0 pb-0">
                <div class="table-responsive">
                    <div class="dataTable-wrapper dataTable-loading no-footer sortable searchable fixed-columns">
                            <div class="dataTable-top">
                                <b-col sm="7" class="dataTable-dropdown">
                                    <label><select class="dataTable-selector" v-model="paginationState.size">
                                        <option :value="5">5</option>
                                        <option :value="10">10</option>
                                        <option :value="15">15</option>
                                        <option :value="20">20</option>
                                        <option :value="25">25</option></select>
                                        entries per page</label>
                                </b-col>
                                <b-col sm="5" class="dataTable-search">
                                
                                </b-col>
                            </div>
                        <div class="dataTable-container">
                            <table class="table table-flush" id="school-list">
                                <thead class="thead-light">
                                    <tr>
                                      <th>category type</th>
                                      <th>language</th>
                                      <th>title</th>
                                      <th>description</th>
                                      <th>background image</th>
                                      <th>create Date</th>
                                      <th>Action</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    <tr v-for="item in list"  v-bind:key="item">
                                    <td class="text-sm">{{getCategoryType(item.classCategoryType)}}</td>
                                    <td class="text-sm">{{getLanguage(item.language)}}</td>
                                    <td class="text-sm" :title="item.categoryTitle">{{item.categoryTitle?.length > 25 ? item.categoryTitle.slice(0,25) + '...' : item.categoryTitle}}</td>
                                    <td class="text-sm" :title="item.categoryDescription">{{item.categoryDescription?.length > 25 ? item.categoryDescription.slice(0,25) + '...' : item.categoryDescription}}</td>
                                    <td class="text-sm cursor-pointer" title="You can click on url to view this image"><a v-b-modal.modalImage @click="setImage(item.backgroundImage)">{{item.backgroundImage.substring(item.backgroundImage.lastIndexOf("/")+1)}}</a></td>
                                    <td class="text-sm">{{item.createTime}}</td>
                                    <td class="text-sm">
                                        <a href="javascript:;" data-bs-toggle="tooltip" v-b-modal.modalEditCategory data-bs-original-title="Edit category" @click="setCategory(item.id,item.classCategoryType,item.categoryTitle,item.categoryDescription,item.language,item.backgroundImage)">
                                        <i class="fas fa-user-edit text-secondary"></i>
                                        </a>
                                    </td>
                                    </tr>
                                </tbody>
                            </table>
                        </div>
                        <div class="dataTable-bottom" v-if="total > paginationState.size">
                        <div class="dataTable-info">
                            Showing {{(paginationState.page - 1) * paginationState.size + 1}} to {{paginationState.page * paginationState.size > total ? total : paginationState.page * paginationState.size}} of {{total}} entries
                        </div>
                        <nav class="dataTable-pagination">

                        <b-pagination
                        v-model="paginationState.page"
                        :total-rows="total"
                        :per-page="parseInt(paginationState.size)"
                        aria-controls="my-table"
                    ></b-pagination>
                        </nav>
                    </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
    <b-modal id="modalImage" ok-only centered title="background image" size="xl"><b-img :src="image" fluid></b-img></b-modal>
    <b-modal id="modalEditCategory" centered @ok="update" title="Edit Category.">
        <b-overlay
            id="overlay-background"
            :show="showLoading"
            rounded="sm"
            >
        <p>You can enter category information.</p>
            <b-row class="my-1">
                <b-col sm="3" class=" my-auto text-end">
                    <label>* category:</label>
                </b-col>
                <b-col sm="9">
                    <label>{{getCategoryType(updateCategoryInfo.classCategoryType)}}</label>
                </b-col>
            </b-row>
            <b-row class="my-1">
                <b-col sm="3" class=" my-auto text-end">
                    <label class="my-auto">* language:</label>
                </b-col>
                <b-col sm="9">
                    <label>{{getLanguage(updateCategoryInfo.language)}}</label>
                </b-col>
            </b-row>
            <b-row class="my-1">
                <b-col sm="3" class=" my-auto text-end">
                    <label>* title:</label>
                </b-col>
                <b-col sm="9">
                    <b-form-input v-model="updateCategoryInfo.categoryTitle" :state="updatetitleState" 
                        aria-describedby="title-feedback"
                        placeholder="Enter your title" >
                    </b-form-input>
                    <b-form-invalid-feedback id="title-feedback">
                        please enter your title
                    </b-form-invalid-feedback>
                </b-col>
            </b-row>
            <b-row class="my-1">
                <b-col sm="3" class=" my-auto text-end">
                    <label>* description:</label>
                </b-col>
                <b-col sm="9">
                    <b-form-textarea v-model="updateCategoryInfo.categoryDescription" placeholder="Enter your description" :state="updatedescriptionState" aria-describedby="description-feedback">
                    </b-form-textarea>
                    <b-form-invalid-feedback id="description-feedback">
                        please enter your description
                    </b-form-invalid-feedback>
                </b-col>
            </b-row>
            <b-row class="my-1">
                <b-col sm="3" class=" my-auto text-end">
                    <label class="my-auto">* background image:</label>
                </b-col>
                <b-col sm="9">
                    <b-img :src="image" fluid v-model="updateCategoryInfo.backgroundImage"></b-img>
                    <input type="File"
                    class="form-control"
                    @change="validyImage"
                    />
                </b-col>
            </b-row>
        </b-overlay>
    </b-modal>
</div>
</template>

<script>
import { Dashboard } from '@/apiClient/Dashboard'
import { onMounted,ref,getCurrentInstance,watch,reactive } from 'vue';
import bus from '@/plugins/bus';
import json from '@/plugins/category.json'
import SparkMD5 from 'spark-md5';
import axios  from "axios";

let requestHeader;
export default {
    name:"categoryList",
    data() {
        return {
          categoryInfo:{
              classCategoryType:null,
              categoryTitle:"",
              categoryDescription:"",
              language:"",
              backgroundImage:""
          },
          updateCategoryInfo:{
              id:0,
              classCategoryType:"",
              categoryTitle:"",
              categoryDescription:"",
              language:"",
              backgroundImage:""
          },
            category:"",
            json:json,
            uploadData:{
                type:"image",
                hash:"",
                extName:""
            },
            uploadUrl:"",
            viewUrl:"",
            uploadParams:"",
            showLoading:false,
            image:""
        }
    },
    computed: {
      languageState() {
        return this.categoryInfo.language == null || this.categoryInfo.language == "" ? false : true
      },
      titleState() {
        return this.categoryInfo.categoryTitle == null || this.categoryInfo.categoryTitle == "" ? false : true
      },
      descriptionState() {
        return this.categoryInfo.categoryDescription == null || this.categoryInfo.categoryDescription == "" ? false : true
      },
      updatetitleState() {
        return this.updateCategoryInfo.categoryTitle == null || this.updateCategoryInfo.categoryTitle == "" ? false : true
      },
      updatedescriptionState() {
        return this.updateCategoryInfo.categoryDescription == null || this.updateCategoryInfo.categoryDescription == "" ? false : true
      }
    },
    methods:{
        clean(){
            this.categoryInfo.classCategoryType = "";
            this.categoryInfo.categoryTitle = "";
            this.categoryInfo.categoryDescription = "";
            this.categoryInfo.language = "";
            this.categoryInfo.backgroundImage = "";
            let file = document.getElementById('file');
            file.value = '';
        },
      add(){
        if(this.categoryInfo.categoryDescription == "")
        {
            bus.emit('error', "category description cannot empty");
            return;
        }
        if(this.categoryInfo.language == "")
        {
            bus.emit('error', "category language cannot empty");
            return;
        }
        if(this.categoryInfo.category == "")
        {
            bus.emit('error', "category cannot empty");
            return;
        }
        if(this.categoryInfo.backgroundImage == "")
        {
            bus.emit('error', "category background image cannot empty");
            return;
        }
        let api = new Dashboard();
        api.addOrUpdateClassCategoryInfoUsingPost(this.categoryInfo,requestHeader).then(rep =>{
          if(rep.status == 200 && rep.data != null){
            if(rep.data.code == 0){
              bus.emit('success', rep.data.message);
            }
            else{
              bus.emit('error', rep.data.message);
            }
          }
          else{
            bus.emit('error', rep.data.message);
          }
        }).catch(function (error) {
          bus.emit('error', error);
        });
      },
      update(){
        if(this.updateCategoryInfo.categoryTitle == "")
        {
            bus.emit('error', "category title cannot empty");
            return;
        }
        if(this.updateCategoryInfo.categoryDescription == "")
        {
            bus.emit('error', "category description cannot empty");
            return;
        }
        if(this.updateCategoryInfo.language == "")
        {
            bus.emit('error', "category language cannot empty");
            return;
        }
        if(this.updateCategoryInfo.category == "")
        {
            bus.emit('error', "category cannot empty");
            return;
        }
        if(this.updateCategoryInfo.backgroundImage == "")
        {
            bus.emit('error', "category background image cannot empty");
            return;
        }
        let api = new Dashboard();
        api.addOrUpdateClassCategoryInfoUsingPost(this.updateCategoryInfo,requestHeader).then(rep =>{
          if(rep.status == 200 && rep.data != null){
            if(rep.data.code == 0){
              bus.emit('success', rep.data.message);
            }
            else{
              bus.emit('error', rep.data.message);
            }
          }
          else{
            bus.emit('error', rep.data.message);
          }
        }).catch(function (error) {
          bus.emit('error', error);
        });
      },
      setImage(image){
        this.image = image;
      },
      setCategory(id,category,title,description,language,image){
        this.updateCategoryInfo.id = id;
        this.updateCategoryInfo.classCategoryType = category;
        this.updateCategoryInfo.categoryTitle = title;
        this.updateCategoryInfo.categoryDescription = description;
        this.updateCategoryInfo.language = language;
        this.updateCategoryInfo.backgroundImage = image;
        this.image =image;
      },
      reload(){
        this.$router.go()
      },
      getLanguage(key){
            return this.json.language[key];
        },
        getCategoryType(key){
            if(key)
            return this.json.showCategory[key];
            else
            return "Learn space default category";
        },
        ReadFileMD5(file){
            const sliceLength = 10;
            const chunkSize = Math.ceil(file.size / sliceLength);
            const fileReader = new FileReader();
            const md5 = new SparkMD5();
            let index = 0;
            const loadFile = () => {
                const slice = file.slice(index, index + chunkSize);
                fileReader.readAsBinaryString(slice);
            }
            loadFile();
            fileReader.onload = e => {
                md5.appendBinary(e.target.result);
                if(index < file.size){
                    index += chunkSize;
                    loadFile();
                }
                else
                {
                    this.uploadData.hash = md5.end();
                    this.uploadData.extName = file.name.substring(file.name.lastIndexOf(".") + 1);
                    this.upload();
                }
            }
		},
        upload(){
            this.showLoading = true;
            let api = new Dashboard();
            let params = { data:this.uploadData };
            api.requestUploadUsingPost(params,requestHeader).then(rep =>{
            if(rep.status == 200 && rep.data != null){
                if(rep.data.code == 0){
                    this.uploadUrl = rep.data?.data?.data?.uploadUrl;
                    this.viewUrl = rep.data?.data?.data?.viewUrl;
                }
                else{
                    bus.emit('error', rep.data.message);
                    this.showLoading = false;
                }
            }
            else{
                bus.emit('error', rep.data.message);
                this.showLoading = false;
            }
            }).catch(function (error) {
                bus.emit('error', error);
                this.showLoading = false;
            }).finally(()=>{
                if(this.uploadUrl){
                    this.uploadToAWS().then(()=>{
                        this.showLoading = false;
                    });
                }
                else{
                    this.categoryInfo.backgroundImage = this.viewUrl;
                    this.updateCategoryInfo.backgroundImage = this.viewUrl;
                    this.showLoading = false;
                }
            });
        },
        async uploadToAWS(){
            axios.put(this.uploadUrl,this.uploadParams,{headers:{'content-type':this.uploadParams.type}}).then(()=>{
                this.categoryInfo.backgroundImage = this.viewUrl;
            }).catch(error=>{
                bus.emit('error', error.message);
            })
        },
        validyImage(e){
            this.uploadParams = e.target.files[0];
            var imgMime = "";
            switch(this.uploadParams.type) {
                case 'jpg':
                case 'image/jpeg':
                    imgMime = 'image/jpeg'
                    break
                case 'image/png':
                    imgMime = 'image/png'
                    break
                case 'image/svg+xml':
                    imgMime = 'image/svg+xml'
                    break
                case 'image/webp':
                    imgMime = 'image/webp'
                    break
            }
            if(imgMime == ""){
                bus.emit('error', "The image format is not correct");
                return false;
            }
            this.ReadFileMD5(e.target.files[0]);
            // let imgSrc = window.URL.createObjectURL(this.uploadParams);
            // new Promise(function (resolve, reject) {
            //     let img = new Image();
            //     img.onload = function () {
            //         let valid = img.width > img.height;
            //         valid ? resolve() : reject();
            //     };
            //     img.src = imgSrc;
            // }).then(
            //     () => {
            //         this.ReadFileMD5(e.target.files[0]);
            //     },
            //     () => {
            //         bus.emit('error', "The image size is incorrect. Please select horizontal image");
            //         return false;
            //     }
            // );
        }
    },
    setup(){
        const list=ref();
        const total = ref(0);
        const paginationState = reactive({
            size: 10,
            page: 1,
        });
        requestHeader = getCurrentInstance().appContext.config.globalProperties.RequestParams
        const call = () =>{
            bus.emit('load', true);
            let api = new Dashboard();
            let params = { page:paginationState.page - 1,size:paginationState.size };
            api.getClassCategoriesUsingPost(params,requestHeader).then((response) => {
                if(response.status == 200 && response.data != null){
                    if(response.data.code == 0){
                        list.value= response.data?.data?.content;
                        total.value = parseInt(response.data?.data.totalElements)
                    }
                    else{
                      bus.emit('error', response.data.message);
                    }
                }
            }).catch((error) => { bus.emit('error', error); }).finally(()=>{ bus.emit('load', false); });
        }
        onMounted(()=>{
            call();
        });
        watch(()=> paginationState.page,()=>{
            call();
        },
        {
            deep:true
        });
         watch(()=> [paginationState.size],()=>{
            paginationState.page = 1;
            call();
        },
        {
            deep:true
        });
        return{
            paginationState,
            list,
            total
        }
    }
}
</script>