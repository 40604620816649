<template>
<div class="row">
    <div class="col-12">
        <div class="card">
            <!-- Card header -->
            <div class="card-header pb-0">
                <div class="d-lg-flex">
                <div>
                    <h5 class="mb-0">Marketplace courses</h5>
                    <p class="text-sm mb-0">
                    Course list       
                    </p>
                </div>
                <div class="ms-auto my-auto mt-lg-0 mt-4" v-if="generateIds.length > 0">
                  <div class="ms-auto my-auto">
                    <button type="button" class="btn btn-outline-primary btn-sm mb-0" v-b-modal.modal-lg>
                      Generate
                    </button>
                    <b-modal id="modal-lg" size="lg" title="Generate SEO" scrollable @ok="add" ok-title="Add">
                      <div class="modal-body">
                        <p v-if="errorMsg" class="text-danger">These are courses' name to generate this SEO page.</p>
                        <p v-if="!errorMsg">These are courses' name to generate this SEO page.</p>
                        <b-badge pill variant="primary" v-for="item in generateIds" v-bind:key="item" class="text-sm mb-2 mx-1">{{item.name}} <a href="javascript:;" @click="removeChoice(item.id)"><i class="fas fa-trash"></i></a></b-badge>
                        <p v-if="errorMsg" class="text-danger">{{errorMsg}}</p>
                        <b-card class="mt-1">
                          <b-body>
                            <p>You can enter the title.</p>
                            <b-form-input v-model="seo.title" :state="titleState" 
                                aria-describedby="title-feedback"
                                placeholder="You can enter the title" >
                            </b-form-input>
                            <b-form-invalid-feedback id="title-feedback">
                                please enter the title
                            </b-form-invalid-feedback>
                            <p>You can enter the URL title.</p>
                            <b-form-input v-model="seo.urlTitle" :state="slugTitleState" 
                                aria-describedby="slugTitle-feedback"
                                placeholder="After generated, this title will be used to generate SEO-slug" >
                            </b-form-input>
                            <b-form-invalid-feedback id="slugTitle-feedback">
                                please enter the url title
                            </b-form-invalid-feedback>
                            <p>You can enter the introdction.</p>
                            <b-form-textarea v-model="seo.introdction" placeholder="please enter the introdction" :state="introdctionState" aria-describedby="introdction-feedback">
                                        </b-form-textarea>
                            <b-form-invalid-feedback id="introdction-feedback">
                                please enter the introdction
                            </b-form-invalid-feedback>
                                </b-body>
                              </b-card>
                              <b-card class="mt-1" v-for="faq in seo.seoFaqs" v-bind:key="faq" >
                                <b-header>
                                  <b-col  class="text-end">
                                    <a href="javascript:;" @click="removeFaq(faq)">
                                      <i class="fas fa-trash"></i>
                                    </a>
                                  </b-col>
                                </b-header>
                                <b-body>
                                <p>You can enter the question.</p>
                                <b-form-input v-model="faq.question" :state="questionState" 
                                    aria-describedby="question-feedback"
                                    placeholder="You can enter the question" >
                                </b-form-input>
                                <b-form-invalid-feedback id="question-feedback">
                                    please enter the question
                                </b-form-invalid-feedback>
                                <p>You can enter the answer.</p>
                                <b-form-textarea v-model="faq.answer" :state="answerState" 
                                    aria-describedby="answer-feedback"
                                    placeholder="You can enter the answer" >
                                </b-form-textarea>
                                <b-form-invalid-feedback id="answer-feedback">
                                    please enter the answer
                                </b-form-invalid-feedback>
                                </b-body>
                              </b-card>
                            <b-row class="my-1">
                                <b-col sm="6" class="my-auto">
                                  <button @click="addFaq" class="btn bg-gradient-primary mt-2">Add FAQ</button>
                                </b-col>
                                <b-col sm="6" class=" text-end">
                                  <button @click="removeAllFaq" class="btn bg-gradient-dangerbtn bg-gradient-danger mt-2">Remove all FAQ</button>
                                </b-col>
                            </b-row>
                          </div>
                    </b-modal>
                    <!-- <div class="modal-dialog modal-lg" id="Generate" tabindex="-1" aria-hidden="true">
                      <div class="modal-dialog mt-lg-10">
                        <div class="modal-content">
                          <div class="modal-header">
                            <h5 class="modal-title" id="ModalLabel"></h5>
                            <i class="fas fa-plus ms-3"></i>
                            <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
                          </div>
                          <div class="modal-body">
                            <p>These are courses' name to generate this SEO page.</p>
                            <b-badge pill variant="primary" v-for="item in generateIds" v-bind:key="item" class="text-sm mb-2 mx-1">{{item.name}} <a href="javascript:;" @click="removeChoice(item.id)"><i class="fas fa-trash"></i></a></b-badge>
                            <p>You can enter the URL title.</p>
                            <b-form-input v-model="seo.title" :state="titleState" 
                                aria-describedby="title-feedback"
                                placeholder="After generated, this title will be used to generate SEO-slug" >
                            </b-form-input>
                            <b-form-invalid-feedback id="title-feedback">
                                please enter the title
                            </b-form-invalid-feedback>
                            <p>You can enter the introdction.</p>
                            <b-form-textarea v-model="seo.introdction" placeholder="please enter the introdction" :state="introdctionState" aria-describedby="introdction-feedback">
                                        </b-form-textarea>
                            <b-form-invalid-feedback id="introdction-feedback">
                                please enter the introdction
                            </b-form-invalid-feedback>
                          </div>
                          <div class="modal-footer">
                            <button type="button" class="btn bg-gradient-secondary btn-sm" data-bs-dismiss="modal">Cancel</button>
                            <button type="button" class="btn bg-gradient-primary btn-sm" data-bs-dismiss="modal" @click="add">Add</button>
                          </div>
                        </div>
                      </div>
                    </div> -->
                  </div>
                </div>
                </div>
            </div>
            <div class="card-body px-0 pb-0">
                <div class="table-responsive">
                    <div class="dataTable-wrapper dataTable-loading no-footer sortable searchable fixed-columns">
                        <div class="dataTable-top">
                            <div class="dataTable-dropdown">
                                <label><select class="dataTable-selector" v-model="paginationState.size">
                                    <option :value="5">5</option>
                                    <option :value="10">10</option>
                                    <option :value="15">15</option>
                                    <option :value="20">20</option>
                                    <option :value="25">25</option></select>
                                     entries per page</label>
                            </div>
                            <div class="dataTable-search">
                                <input class="dataTable-input mx-4" placeholder="Search..." type="text" v-model="paginationState.keyword">
                                <button type="button" class="btn btn-outline-primary btn-sm mb-0" @click="search">
                                Search
                                </button>
                            </div>
                        </div>
                        <div class="dataTable-container">
                            <table class="table table-flush" id="class-list">
                                <thead class="thead-light">
                                    <tr>
                                    <th>Class Name</th>
                                    <th>School</th>
                                    <th>Teacher</th>
                                    <th>total Price</th>
                                    <th>class Schedule Type</th>
                                    <th>ageRange Type</th>
                                    <th>category Type</th>
                                    <th>Action</th>
                                    </tr>
                                </thead>
                                <draggable v-model="list" tag="tbody" item-key="id" @change="change">
                                    <template #item="{ element }">
                                        <tr class="cursor-pointer" title="You can drag this item to sort">
                                            <td>
                                                <div class="d-flex">
                                                <div class="form-check my-auto">
                                                    <input class="form-check-input" type="checkbox" :value="element" v-model="generateIds">
                                                </div>
                                                <!-- <img class="w-10 ms-3" v-bind:src="item.avatar" alt="hoodie"> -->
                                                <h6 class="ms-3 my-auto" :title="element.name">{{ element.name.length > 40 ? element.name.slice(0,40) + '...' : element.name}}</h6>
                                                </div>
                                            </td>
                                            <td class="text-sm">{{element.schoolName}}</td>
                                            <td class="text-sm">
                                              <b-avatar-group class="avatar-group">
                                                  <b-link :href="'#/userProfile/' + item.userId" v-for="item in element.teachers"  v-bind:key="item.userId" :title="item.firstName + ' ' + item.lastName">
                                                  <img v-if="item.avatar" alt="Image placeholder" :src="item.avatar + '?size=80x80'" class="avatar rounded-circle">
                                                  <span v-if="!item.avatar" class="b-avatar bg-primary rounded-circle avatar text-uppercase"><span class="b-avatar-text text-light">{{getWord(item.firstName + ' ' + item.lastName)}}</span></span>
                                                  </b-link>
                                              </b-avatar-group>
                                              <!-- {{element.teacher?.firstName && element.teacher?.lastName ? element.teacher?.firstName + " " + element.teacher?.lastName : element.teacher?.accountName}} -->
                                            </td>
                                            <td class="text-sm">{{element.free ? "Free" : element.currency + " " + (element.totalPrice / 100).toFixed(2) }}</td>
                                            <td class="text-sm">{{element.classScheduleType}}</td>
                                            <td class="text-sm">{{element.ageRangeType == null ? "All ages" : getAgeType(element.ageRangeType)}}</td>
                                            <td class="text-sm">{{element.categoryType == null ? "All categories" : getCategoryType(element.categoryType)}}</td>
                                            <!-- <td>
                                                <span class="badge badge-sm" :class="element.clazzStatus != 'ongoing' ? 'badge-danger' : 'badge-success'">{{element.clazzStatus}}</span>
                                            </td> -->
                                            <td class="text-sm">
                                                <a :href="'#/course/' + element.id" data-bs-toggle="tooltip" data-bs-original-title="Preview Class">
                                                <i class="fas fa-eye text-secondary"></i>
                                                </a>
                                                <a href="javascript:;" class="mx-3" data-bs-toggle="tooltip" v-b-modal.modalEditClassOrder data-bs-original-title="Edit order" @click="setOrderClassId(element.id,total)">
                                                <i class="fas fa-user-edit text-secondary"></i>
                                                </a>
                                                <a href="javascript:;" data-bs-toggle="tooltip"  v-b-modal.modalEditClassCategory data-bs-original-title="Edit category" @click="setClass(element.id,element.ageRangeType,element.categoryType)">
                                                <i class="ni ni-settings text-secondary"></i>
                                                </a>
                                                <a href="javascript:;" class="mx-3" data-bs-toggle="tooltip" v-b-modal.modalDeleteConfirm data-bs-original-title="delete class" @click="setDeleteId(element.id,element.schoolId)">
                                                <i class="fas fa-trash text-secondary"></i>
                                                </a>
                                            </td>
                                        </tr>
                                    </template>
                                </draggable>
                                <tbody v-if="total == 0">
                                    <tr>
                                        <td class="dataTables-empty" colspan="10">No entries found</td>
                                    </tr>
                                </tbody>
                            </table>
                        </div>
                        <div class="dataTable-bottom" v-if="total > paginationState.size">
                        <div class="dataTable-info">
                            Showing {{(paginationState.page - 1) * paginationState.size + 1}} to {{paginationState.page * paginationState.size > total ? total : paginationState.page * paginationState.size}} of {{total}} entries
                        </div>
                        <nav class="dataTable-pagination">

                        <b-pagination
                        v-model="paginationState.page"
                        :total-rows="total"
                        :per-page="parseInt(paginationState.size)"
                        aria-controls="my-table"
                    ></b-pagination>
                        </nav>
                    </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
  <b-modal id="modalDeleteConfirm" centered @ok="deleteClass(deleteClassId,deleteSchoolId)">Please confirm whether to delete the user.</b-modal>
  <b-modal id="modalEditClassOrder" centered @ok="updateClassOrder" title="Edit class order.">
        <b-row class="my-1">
            <b-col sm="4" class=" my-auto text-end">
                <label class="my-auto">order number:</label>
            </b-col>
            <b-col sm="8">
                <b-form-input v-model="newIndex" type="number" class="form-control" min="1" :max="total" :state="orderState" 
                                        aria-describedby="order-number-feedback" placeholder="please enter sort position"></b-form-input>
                <b-form-invalid-feedback id="order-number-feedback">
                    The class sort position must be greater than 0 and less than {{total + 1}}.
                </b-form-invalid-feedback>
            </b-col>
        </b-row>
    </b-modal>
    <b-modal id="modalEditClassCategory" centered @ok="edit" title="Edit Category.">
        <b-row class="my-1">
            <b-col sm="4" class=" my-auto text-end">
                <label class="my-auto">age range type:</label>
            </b-col>
            <b-col sm="8">
                <b-form-select v-model="age" :options="json.age" class="form-control" :state="ageState" 
                                        aria-describedby="age-feedback">
                    <b-form-select-option :value="null" v-if="age == ''">All ages</b-form-select-option>
                </b-form-select>
                <b-form-invalid-feedback id="age-feedback">
                    please select an age range
                </b-form-invalid-feedback>
            </b-col>
        </b-row>
        <b-row class="my-1">
            <b-col sm="4" class=" my-auto text-end">
                <label class="my-auto">category type:</label>
            </b-col>
            <b-col sm="8">
                <b-form-select v-model="category" :options="json.category" class="form-control"  :state="categoryState" 
                                        aria-describedby="category-feedback">
                    <b-form-select-option :value="null" v-if="category == ''">All categories</b-form-select-option>
                </b-form-select>
                <b-form-invalid-feedback id="category-feedback">
                    please select a category
                </b-form-invalid-feedback>
            </b-col>
        </b-row>
    </b-modal>
</div>
</template>

<script>
import { Dashboard } from '@/apiClient/Dashboard'
import { onMounted,ref,getCurrentInstance,watch,reactive } from 'vue';
import { useRouter } from "vue-router";
import bus from '@/plugins/bus';
import draggable from "vuedraggable";
import json from '@/plugins/category.json'
let requestHeader;
let currentList;
export default {
    name:"coursesList",
    components: { draggable},
    data() {
        return {
            deleteClassId:0,
            deleteSchoolId:0,
            dragging: false,
            oldClassId:0,
            newClassId:0,
            classId:0,
            totalNum:0,
            newIndex:"",
            age:"",
            category:"",
            json:json,
            isFrist:true
        }
    },
    computed: {
      orderState() {
        return this.newIndex > 0 && this.newIndex <= this.totalNum;
      },
      ageState() {
        return this.age == null || this.age == "" ? false : true
      },
      categoryState() {
        return this.category == null || this.category == "" ? false : true
      },
      titleState() {
        return this.seo.title == null || this.seo.title == "" ? false : true
      },
      slugTitleState() {
        return this.seo.urlTitle == null || this.seo.urlTitle == "" ? false : true
      },
      introdctionState() {
        return this.seo.introdction == null || this.seo.introdction == "" ? false : true
      }
    },
    methods:{
      getWord(f){
        f = f ? f : "";
        let arr = f.split(' ');
        let first = arr ? arr[0].slice(0,1) : "";
        let last = arr[1] ? arr[1].slice(0,1) : "";
        return first + last;
      },
        getCategoryType(key){
            return this.json.showCategory[key];
        },
        getAgeType(key){
            return this.json.age[key];
        },
        edit(){
        let params = { ageRangeType: this.age,categoryType:this.category,id:this.classId};
        let api = new Dashboard();
        api.updateMarketplaceCategoryUsingPost(params,requestHeader).then(rep =>{
          if(rep.status == 200 && rep.data != null){
            if(rep.data.code == 0){
                bus.emit('success', rep.data.message);
            }
            else{
              bus.emit('error', rep.data.message);
            }
          }
          else{
            bus.emit('error',rep.data.message);
          }
        }).catch(function (error) {
          bus.emit('error', error);
        });
      },
      deleteClass(classId,schoolId){
        let api = new Dashboard();
        let params = { classId: classId,schoolId:schoolId};
        api.removeLearnSpaceClassByIdUsingPost(params,requestHeader).then((response) => {
            if(response.status == 200 && response.data != null){
              if(response.data.code == 0){
                bus.emit('success', response.data.message);
              }
              else{
                bus.emit('error', response.data.message);
              }
            }
        }).catch((error) => {  bus.emit('error', error); });
      },
      setDeleteId(classId,schoolId){
        this.deleteClassId = classId;
        this.deleteSchoolId = schoolId;
      },
      reload(){
        this.$router.go()
      },
      change(event) {
        this.newClassId = event.moved.element.id;
        this.oldClassId = currentList[event.moved.newIndex].id;
        let api = new Dashboard();
        let params = { newClassId: this.newClassId,oldClassId:this.oldClassId};
        api.updateLearnSpaceClassOrderUsingGet(params,requestHeader).then((response) => {
            if(response.status == 200 && response.data != null){
              if(response.data.code == 0){
                //bus.emit('success', response.data.message);
              }
              else{
                bus.emit('error', response.data.message);
              }
            }
        }).catch((error) => {  bus.emit('error', error); });
      },
      updateClassOrder(){
        if(this.newIndex > 0 && this.newIndex <= this.totalNum){
            let api = new Dashboard();
            let params = { classId: this.classId,newIndex:this.newIndex};
            api.updateLearnSpaceClassOrderByNewIndexUsingGet(params,requestHeader).then((response) => {
                if(response.status == 200 && response.data != null){
                if(response.data.code == 0){
                    bus.emit('success', response.data.message);
                }
                else{
                    bus.emit('error', response.data.message);
                }
                }
            }).catch((error) => {  bus.emit('error', error); });
        }
        else{
            bus.emit('error', "The class sort position must be greater than 0 and less than " + (this.totalNum + 1) + ".");
        }
      },
      setOrderClassId(classId,totalNum){
        this.classId = classId;
        this.totalNum = totalNum;
      },
      setClass(id,age,category){
        debugger
        this.classId = id;
        this.age = age == null ? "" : age;
        this.category = category == null ? "" : category;
      }
    },
    setup(){
        let router = new useRouter();
        const list=ref();
        const total = ref(0);
        const generateIds = ref([]);
        const errorMsg = ref("");
        const seo = reactive({
          classIds:[],
          title:"",
          urlTitle:"",
          introdction:"",
          seoFaqs:[]
        });
        const paginationState = reactive({
            size: 10,
            page: 1,
            keyword:""
        });
        requestHeader = getCurrentInstance().appContext.config.globalProperties.RequestParams
        const addFaq = () =>{
          if (seo.seoFaqs.length < 5){
            seo.seoFaqs.push({question:"",answer:""})
          }
        }
        const removeFaq = (faq) =>{
          seo.seoFaqs.some((item,i)=>{
              if (item.question == faq.question && item.answer == faq.answer){
                seo.seoFaqs.splice(i,1);
              }
            });
        }
        const removeAllFaq = () =>{
          if (seo.seoFaqs.length > 0){
            seo.seoFaqs = [];
          }
        }
        const search = () =>{
            if(paginationState.page == 1){
                call();
            }
            else{
                paginationState.page = 1;
            }
        }
        const call = () =>{
            bus.emit('load',true);
            let api = new Dashboard();
            let params = { keyword:paginationState.keyword,page:paginationState.page - 1,size:paginationState.size };
            api.getAllLearnSpaceClassesPageUsingGet(params,requestHeader).then((response) => {
                if(response.status == 200 && response.data != null){
                    if(response.data.code == 0){
                        list.value= response.data?.data?.content;
                        total.value = parseInt(response.data?.data.totalElements)
                        currentList = response.data?.data?.content;
                    }
                    else{
                        bus.emit('error', response.data.message);
                    }
                }
            }).catch((error) => { bus.emit('error', error); }).finally(()=>{ bus.emit('load', false); });
        };
        const add = () =>{
          if(seo.title.value == ""){
            bus.emit('error', "Title can't be empty");
          }
          if(seo.urlTitle.value == ""){
            bus.emit('error', "Url title can't be empty");
          }
          if(seo.introdction.value == ""){
            bus.emit('error', "Introdction can't be empty");
          }
          let api = new Dashboard();
          seo.classIds = generateIds.value.map((m)=>m.id);
          api.courseGenerateSeoUsingPost(seo,requestHeader).then(rep =>{
            if(rep.status == 200 && rep.data != null){
              console.log(rep.data)
              if(rep.data.code == 0){
                  router.push({path:"/seo/" + rep.data?.data});
              }
              else{
                bus.emit('error', rep.data.message);
              }
            }
            else{
              bus.emit('error',rep.data.message);
            }
          }).catch(function (error) {
            bus.emit('error', error);
          });
        };
        onMounted(()=>{
            call();
        });
        watch(()=> paginationState.page,()=>{
            call();
        },
        {
            deep:true
        });
         watch(()=> [paginationState.size],()=>{
            paginationState.page = 1;
            call();
        },
        {
            deep:true
        });
        const removeChoice = (id) => {
          if(generateIds.value.length == 1){
            errorMsg.value = "At least one course is required";
            setTimeout(() => {
              errorMsg.value = "";
            }, 2000);
          }
          else{
            generateIds.value.some((item,i)=>{
              if (item.id == id){
                generateIds.value.splice(i,1);
              }
            });
          }
        }
        return{
            paginationState,
            list,
            total,
            generateIds,
            seo,
            errorMsg,
            removeChoice,
            search,
            add,
            addFaq,
            removeFaq,
            removeAllFaq
        }
    }
}
</script>