<template>
<div class="row">
    <div class="col-12">
        <div class="card">
            <!-- Card header -->
            <div class="card-header pb-0">
                <div class="d-lg-flex">
                <div>
                    <h5 class="mb-0">Today signup students</h5>
                    <p class="text-sm mb-0">
                    Today signup student list
                    </p>
                </div>
                </div>
            </div>
            <div class="card-body px-0 pb-0">
                <div class="table-responsive">
                    <div class="dataTable-wrapper dataTable-loading no-footer sortable searchable fixed-columns">
                        <div class="dataTable-top">
                            <div class="dataTable-dropdown">
                                <label><select class="dataTable-selector" v-model="paginationState.size">
                                    <option :value="5">5</option>
                                    <option :value="10">10</option>
                                    <option :value="15">15</option>
                                    <option :value="20">20</option>
                                    <option :value="25">25</option></select>
                                     entries per page</label>
                            </div>
                            <div class="dataTable-search">
                                <input class="dataTable-input" placeholder="Search..." type="text" v-model="paginationState.keyword">
                            </div>
                        </div>
                        <div class="dataTable-container">
                            <table class="table table-flush" id="user-list">
                                <thead class="thead-light">
                                    <tr>
                                      <th>User Name</th>
                                      <th>Account</th>
                                      <th>user Role</th>
                                      <th>create Time</th>
                                      <th>Blocked</th>
                                      <th>Action</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    <tr v-for="item in list"  v-bind:key="item">
                                    <td>
                                        <div class="d-flex">
                                        <img v-if="item.avatar" :src="item.avatar + '?size=80x80'" class="avatar rounded-circle">
                                        <span v-else-if="!item.avatar" class="bg-primary rounded-circle avatar text-uppercase"><span class="text-light">{{getWord(item.fullNameOrAccountName)}}</span></span>
                                        <h6 class="ms-3 my-auto">{{item.fullNameOrAccountName}}</h6>
                                        </div>
                                    </td>
                                    <td class="text-sm">{{item.accountName}}</td>
                                    <td class="text-sm">{{item.userRole}}</td>
                                    <td class="text-sm">{{item.createTime}}</td>
                                    <td>
                                        <span class="badge badge-sm" :class="item.beBlocked ? 'badge-danger' : 'badge-success'">{{item.beBlocked}}</span>
                                    </td>
                                    <td class="text-sm">
                                        <a :href="'#/userProfile/' + item.id" data-bs-toggle="tooltip" data-bs-original-title="Preview user">
                                        <i class="fas fa-eye text-secondary"></i>
                                        </a>
                                    </td>
                                    </tr>
                                </tbody>
                            </table>
                        </div>
                        <div class="dataTable-bottom" v-if="total > paginationState.size">
                        <div class="dataTable-info">
                            Showing {{(paginationState.page - 1) * paginationState.size + 1}} to {{paginationState.page * paginationState.size > total ? total : paginationState.page * paginationState.size}} of {{total}} entries
                        </div>
                        <nav class="dataTable-pagination">

                        <b-pagination
                        v-model="paginationState.page"
                        :total-rows="total"
                        :per-page="parseInt(paginationState.size)"
                        aria-controls="my-table"
                    ></b-pagination>
                        </nav>
                    </div>
                    </div>
                </div>
            </div>
        </div>

    </div>
</div>
</template>

<script>
import { Dashboard } from '@/apiClient/Dashboard'
import { onMounted,ref,getCurrentInstance,watch,reactive } from 'vue';
import bus from '@/plugins/bus';

let requestHeader;
export default {
    name:"todaySignupStudentList",
    data() {
        return {
        }
    },
    methods:{
      getWord(f){
        f = f ? f : "";
        let arr = f.split(' ');
        let first = arr ? arr[0].slice(0,1) : "";
        let last = arr[1] ? arr[1].slice(0,1) : "";
        return first + last;
      },
    },
    setup(){
        const list=ref();
        const total = ref(0);
        const paginationState = reactive({
            size: 10,
            page: 1,
            keyword:""
        });
        requestHeader = getCurrentInstance().appContext.config.globalProperties.RequestParams
        const call = () =>{
            bus.emit('load', true);
            let api = new Dashboard();
            let params = { keyword:paginationState.keyword,page:paginationState.page - 1,size:paginationState.size };
            api.getSignUpStudentTodayUsingPost(params,requestHeader).then((response) => {
                if(response.status == 200 && response.data != null){
                    if(response.data.code == 0){
                        list.value= response.data?.data?.content;
                        total.value = parseInt(response.data?.data.totalElements)
                    }
                    else{
                        bus.emit('error', response.data.message);
                    }
                }
            }).catch((error) => { bus.emit('error', error); }).finally(()=>{ bus.emit('load', false); });
        }
        onMounted(()=>{
            call();
        });
        watch(()=> paginationState.page,()=>{
            call();
        },
        {
            deep:true
        });
         watch(()=> [paginationState.size,paginationState.keyword],()=>{
            paginationState.page = 1;
            call();
        },
        {
            deep:true
        });
        return{
            paginationState,
            list,
            total
        }
    }
}
</script>