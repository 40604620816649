<template>
<div class="container-fluid my-5 py-2">
    <div class="row">
        <div class="col-lg-12 mt-2">
            <b-card no-body class="mb-4">
                <b-card-body>
                    <b-row>
                        <b-col sm="10">
                            <h5 class="mb-0">Title: {{seo?.title}}</h5>
                            <p class="text-sm mb-0">
                            Introdction: {{seo?.introdction}}
                            </p>
                        </b-col>
                        <b-col sm="2" class="mx-auto my-auto">
                            <a :href="courseUrl + '/courses/' + seo?.slug" target="_blank" class="bg-gradient-primary btn-sm text-uppercase font-weight-bold text-light">Go to seo page</a>
                        </b-col>
                    </b-row>
                </b-card-body>
            </b-card>
        </div>
    </div>
    <div class="row" v-if="seo?.seoFaqs.length > 0">
        <div class="col-lg-12 mt-2">
            <b-card no-body class="mb-4">
                <b-card-body>
                    <b-row v-for="faq in seo?.seoFaqs" :key="faq">
                        <b-col sm="10">
                            <h5 class="mb-0">Q: {{faq.question}}</h5>
                            <p class="text-sm mb-0">
                            A: {{faq.answer}}
                            </p>
                        </b-col>
                    </b-row>
                </b-card-body>
            </b-card>
        </div>
    </div>
    <div class="row">
        <div class="col-lg-12 mt-lg-0 mt-4">
            <!-- Card Profile -->
            <b-card no-body class="mb-4" v-for="course in seo?.classes" :key="course">
                <b-card-header>
                    <div class="row">
                        <div class="col-sm-auto col-4">
                            <img :src="getImage(course.thumbnail + '?size=80x80')" class="avatar rounded-circle avatar-xl position-relative" style="object-fit: cover; object-position: center;">
                        </div>
                        <div class="col-sm-auto col-7 my-auto">
                            <p class="text-sm mb-0 text-uppercase font-weight-bold">course name</p>
                            <h5 class="font-weight-bolder">
                                {{course.clazzName}}
                            </h5>
                        </div>
                        <div class="col-sm-auto col-1 ms-auto">
                            <a :href="courseUrl + '/course/' + course.slug" target="_blank" class="bg-gradient-primary btn-sm text-uppercase font-weight-bold text-light">Go to course</a>
                        </div>
                    </div>
                </b-card-header>
                <b-card-body body-class="p3">
                    <b-row>
                        <b-col md="3">
                            <div class="numbers">
                                <p class="text-sm mb-0 text-uppercase font-weight-bold">Instructor</p>
                                <b-avatar-group class="avatar-group">
                                    <b-link :href="'#/userProfile/' + item.userId" v-for="item in course.teachers"  v-bind:key="item.userId" :title="item.firstName + ' ' + item.lastName">
                                    <img v-if="item.avatar" alt="Image placeholder" :src="item.avatar + '?size=80x80'" class="avatar rounded-circle">
                                    <span v-if="!item.avatar" class="b-avatar bg-primary rounded-circle avatar text-uppercase"><span class="b-avatar-text text-light">{{getWord(item.firstName + ' ' + item.lastName)}}</span></span>
                                    </b-link>
                                </b-avatar-group>
                            </div>
                        </b-col>
                        <b-col md="3" class="text-center">
                            <div class="numbers">
                                <p class="text-sm mb-0 text-uppercase font-weight-bold">Price</p>
                                <h6>
                                    {{ course.free ? "Free" : course.currency + " " + (course.price / 100).toFixed(2)}}
                                </h6>
                            </div>
                        </b-col>
                        <b-col md="3" class="text-center">
                            <div class="numbers">
                                <p class="text-sm mb-0 text-uppercase font-weight-bold">Max Learners</p>
                                <h6>
                                    {{ course.maxStudents == 0 ? "Unlimited" : course.maxStudents}}
                                </h6>
                            </div>
                        </b-col>
                        <b-col class="text-end">
                            <div class="numbers">
                                <p class="text-sm mb-0 text-uppercase font-weight-bold">status</p>
                                <h6>
                                    {{course.clazzStatus}}
                                </h6>
                            </div>
                        </b-col>
                    </b-row>
                </b-card-body>
                <b-card-footer>
                    <b-row>
                        <b-col md="12">
                            <div class="numbers">
                                <p class="text-sm mb-0 text-uppercase font-weight-bold">Course Description</p>
                                <div v-html="course.introduction">
                                </div>
                            </div>
                        </b-col>
                    </b-row>
                </b-card-footer>
            </b-card>
        </div>
    </div>
</div>
</template>
<script>
import { Dashboard } from '@/apiClient/Dashboard'
import BCard from 'bootstrap-vue-3/src/components/BCard/BCard.vue';
import { defineComponent,onMounted,ref,getCurrentInstance  } from 'vue';
import { useRouter } from "vue-router";
import bus from '@/plugins/bus';

let requestParams;
let appUrl;
export default defineComponent({
  components: { BCard },
  name: 'generateSeo',
  methods: {
      getWord(f){
        f = f ? f : "";
        let arr = f.split(' ');
        let first = arr ? arr[0].slice(0,1) : "";
        let last = arr[1] ? arr[1].slice(0,1) : "";
        return first + last;
      },
    getImage(img){
        return img?.replace(/^oklocal:\//,appUrl);
    }
  },
  setup(){
    appUrl = getCurrentInstance().appContext.config.globalProperties.RequestParams.appURL;
    const seo=ref();
    const courseUrl = ref("");
    requestParams = getCurrentInstance().appContext.config.globalProperties.RequestParams;
    const router = useRouter();
    let slug = router.currentRoute.value.params.slug;
    const call = () =>{
      let api = new Dashboard();
      api.getMarketplaceSeoBySlugUsingPost({slug:slug},requestParams).then(rep=>{
        if(rep.status == 200 && rep.data != null){
            if(rep.data.code == 0){
                seo.value = rep.data.data;
            }
            else{
                bus.emit('error', rep.data.message);
            }
        }
      }).catch(function (error) {
          bus.emit('error',error);
      });
    }
    onMounted(()=>{
        call();
        courseUrl.value = appUrl;
    })
    return{
      seo,
      courseUrl
    }
 
  }
});
</script>