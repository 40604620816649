<template>
<div class="row">
    <div class="col-12">
        <div class="card">
            <!-- Card header -->
            <div class="card-header pb-0">
                <div class="d-lg-flex">
                <div>
                    <h5 class="mb-0">All user plans</h5>
                    <p class="text-sm mb-0">
                    User plan list
                    </p>
                </div>
                </div>
            </div>
            <div class="card-body px-0 pb-0">
                <div class="table-responsive">
                    <div class="dataTable-wrapper dataTable-loading no-footer sortable searchable fixed-columns">
                        <div class="dataTable-top">
                            <div class="dataTable-dropdown">
                                <label><select class="dataTable-selector" v-model="paginationState.size">
                                    <option :value="5">5</option>
                                    <option :value="10">10</option>
                                    <option :value="15">15</option>
                                    <option :value="20">20</option>
                                    <option :value="25">25</option></select>
                                     entries per page</label>
                            </div>
                            <div class="dataTable-dropdown mx-4">
                                <b-form-select v-model="paginationState.planType" :options="json.planType">
                                    <b-form-select-option :value="null">All plan type &nbsp;&nbsp;&nbsp;</b-form-select-option>
                                </b-form-select>
                            </div>
                            <div class="dataTable-search">
                                <input class="dataTable-input mx-4" placeholder="Search..." type="text" v-model="paginationState.keyword">
                                <button type="button" class="btn btn-outline-primary btn-sm mb-0" @click="search">
                                Search
                                </button>
                            </div>
                        </div>
                        <div class="dataTable-container">
                            <table class="table table-flush" id="user-list">
                                <thead class="thead-light">
                                    <tr>
                                      <th>User Name</th>
                                      <th>User account</th>
                                      <th>plan type</th>
                                      <th>license Count</th>
                                      <th>Student Capacity</th>
                                      <th>Student Usage</th>
                                      <th>Teacher Capacity</th>
                                      <th>Teacher Usage</th>
                                      <th>create Time</th>
                                      <th>Action</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    <tr v-for="item in list"  v-bind:key="item">
                                    <td>
                                        <div class="d-flex">
                                        <img v-if="item.avatar" :src="item.avatar + '?size=80x80'" class="avatar rounded-circle">
                                        <span v-else-if="!item.avatar" class="bg-primary rounded-circle avatar text-uppercase"><span class="text-light">{{getWord(item.userName)}}</span></span>
                                        <h6 class="ms-3 my-auto">{{item.userName}}</h6>
                                        </div>
                                    </td>
                                    <td class="text-sm">{{item.accountName}}</td>
                                    <td class="text-sm">{{getPlan(item.planType)}}</td>
                                    <td class="text-sm">{{item.licenseCount}}</td>
                                    <td class="text-sm">{{item.totalStudentCapacity == -1 ? "unlimited" : item.totalStudentCapacity}}</td>
                                    <td class="text-sm">{{item.totalStudentUsage}}</td>
                                    <td class="text-sm">{{item.totalTeacherCapacity == -1 ? "unlimited" : item.totalTeacherCapacity}}</td>
                                    <td class="text-sm">{{item.totalTeacherUsage}}</td>
                                    <td class="text-sm">{{item.createTime}}</td>
                                    <td class="text-sm">
                                        <a :href="'#/userProfile/' + item.userId" data-bs-toggle="tooltip" data-bs-original-title="Preview user">
                                        <i class="fas fa-eye text-secondary"></i>
                                        </a>
                                        <a href="javascript:;" class="mx-2" data-bs-toggle="tooltip" v-b-modal.modalEditUserPlan data-bs-original-title="Edit user" @click="setData(item.userId,item.planType,item.licenseCount,item.enableRegisterStudent,item.enableOktopiCode,item.enableBundled,item.maxPerLiveSessionCapacity)">
                                        <i class="fas fa-user-edit text-secondary"></i>
                                        </a>
                                    </td>
                                    </tr>
                                    <tr v-if="total == 0">
                                        <td class="dataTables-empty" colspan="10">No entries found</td>
                                    </tr>
                                </tbody>
                            </table>
                        </div>
                        <div class="dataTable-bottom" v-if="total > paginationState.size">
                        <div class="dataTable-info">
                            Showing {{(paginationState.page - 1) * paginationState.size + 1}} to {{paginationState.page * paginationState.size > total ? total : paginationState.page * paginationState.size}} of {{total}} entries
                        </div>
                        <nav class="dataTable-pagination">

                        <b-pagination
                        v-model="paginationState.page"
                        :total-rows="total"
                        :per-page="parseInt(paginationState.size)"
                        aria-controls="my-table"
                    ></b-pagination>
                        </nav>
                    </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
    <b-modal id="modalEditUserPlan" centered @ok="updatePlan" title="Edit user plan.">
        <b-row class="my-1">
            <b-col sm="5" class=" my-auto text-end">
                <label class="my-auto">plan type:</label>
            </b-col>
            <b-col sm="7">
                <b-form-select v-model="planType" :options="json.planType" class="form-control" :state="planTypeState" 
                                        aria-describedby="type-feedback">
                </b-form-select>
                <b-form-invalid-feedback id="type-feedback">
                    please select a plan type
                </b-form-invalid-feedback>
            </b-col>
        </b-row>
        <b-row class="my-1">
            <b-col sm="5" class=" my-auto text-end">
                <label class="my-auto">license count:</label>
            </b-col>
            <b-col sm="7">
                <b-form-input v-model="licenseCount" type="number" class="form-control" :state="licenseCountState" 
                                        aria-describedby="license-count-feedback" placeholder="please enter license count"></b-form-input>
                <b-form-invalid-feedback id="license-count-feedback">
                    The license count must be greater than 0.
                </b-form-invalid-feedback>
            </b-col>
        </b-row>
        <b-row class="my-1">
            <b-col sm="5" class=" my-auto text-end">
                <label class="my-auto">max session attendees:</label>
            </b-col>
            <b-col sm="7">
                <b-form-input v-model="max_session_attendees" type="number" class="form-control" :state="attendeesCountState" 
                                        aria-describedby="attendees-feedback" placeholder="please enter max session attendees count"></b-form-input>
                <b-form-invalid-feedback id="attendees-feedback">
                    The max session attendees count can not less than 0.
                </b-form-invalid-feedback>
            </b-col>
        </b-row>
        <b-row class="my-1">
            <b-col sm="5" class=" my-auto text-end">
                <label class="my-auto">enable register student:</label>
            </b-col>
            <b-col sm="7">
                <div class="form-check form-switch">
                    <input class="form-check-input" v-model="enableRegisterStudent" type="checkbox" checked="">
                </div>
            </b-col>
        </b-row>
        <b-row class="my-1">
            <b-col sm="5" class=" my-auto text-end">
                <label class="my-auto">enable oktopi code:</label>
            </b-col>
            <b-col sm="7">
                <div class="form-check form-switch">
                    <input class="form-check-input" v-model="enableOktopiCode" type="checkbox" checked="">
                </div>
            </b-col>
        </b-row>
        <b-row class="my-1">
            <b-col sm="5" class=" my-auto text-end">
                <label class="my-auto">enable bundle:</label>
            </b-col>
            <b-col sm="7">
                <div class="form-check form-switch">
                    <input class="form-check-input" v-model="enableBundled" type="checkbox" checked="">
                </div>
            </b-col>
        </b-row>
    </b-modal>
</div>
</template>

<script>
import { Dashboard } from '@/apiClient/Dashboard'
import { onMounted,ref,getCurrentInstance,watch,reactive } from 'vue';
import bus from '@/plugins/bus';
import json from '@/plugins/category.json'

let requestHeader;
export default {
    name:"userPlanList",
    data() {
        return {
          planType: '',
          licenseCount:0,
          max_session_attendees:0,
          enableRegisterStudent:false,
          enableOktopiCode:false,
          enableBundled:false,
          userId:0,
          json:json
        }
    },
    computed: {
      planTypeState() {
        return this.planType == null || this.age == "" ? false : true
      },
      licenseCountState() {
        return this.licenseCount > 0 ? true : false
      },
      attendeesCountState() {
        return this.max_session_attendees >= 0 ? true : false
      }
    },
    methods:{
      getWord(f){
        f = f ? f : "";
        let arr = f.split(' ');
        let first = arr ? arr[0].slice(0,1) : "";
        let last = arr[1] ? arr[1].slice(0,1) : "";
        return first + last;
      },
      updatePlan(){
        let api = new Dashboard();
        let params = { userId:this.userId,planType:this.planType,licenseCount:this.licenseCount,enableRegisterStudent:this.enableRegisterStudent,enableOktopiCode:this.enableOktopiCode,enableBundle:this.enableBundled,max_session_attendees:this.max_session_attendees};
        api.updateUserPlanUsingPost(params,requestHeader).then((response) => {
            if(response.status == 200 && response.data != null){
              if(response.data.code == 0){
                bus.emit('success', response.data.message);
              }
              else{
                bus.emit('error', response.data.message);
              }
            }
        }).catch((error) => { console.log(error); });
      },
      setData(userId,planType,licenseCount,enableRegisterStudent,enableOktopiCode,enableBundled,maxPerLiveSessionCapacity){
        this.userId = userId;
        this.planType = planType;
        this.licenseCount = licenseCount;
        this.enableRegisterStudent = enableRegisterStudent;
        this.enableOktopiCode = enableOktopiCode;
        this.enableBundled = enableBundled;
        this.max_session_attendees = maxPerLiveSessionCapacity;
      },
      reload(){
        this.$router.go()
      },
      getPlan(key){
          return this.json.planType[key];
      }
    },
    setup(){
        const list=ref();
        const total = ref(0);
        const paginationState = reactive({
            size: 10,
            page: 1,
            keyword:"",
            planType:""
        });
        requestHeader = getCurrentInstance().appContext.config.globalProperties.RequestParams
        const search = () =>{
            if(paginationState.page == 1){
                call();
            }
            else{
                paginationState.page = 1;
            }
        }
        const call = () =>{
            bus.emit('load', true);     
            let api = new Dashboard();
            let params = { keyword:paginationState.keyword,planType:paginationState.planType,page:paginationState.page - 1,size:paginationState.size };
            api.getUserPlansUsingGet(params,requestHeader).then((response) => {
                if(response.status == 200 && response.data != null){
                    if(response.data.code == 0){
                        list.value= response.data?.data?.content;
                        total.value = parseInt(response.data?.data.totalElements)
                    }
                }
            }).catch((error) => { bus.emit('error', error); }).finally(()=>{ bus.emit('load', false); });
        }
        onMounted(()=>{
            call();
        });
        watch(()=> paginationState.page,()=>{
            call();
        },
        {
            deep:true
        });
         watch(()=> [paginationState.size,paginationState.planType],()=>{
            paginationState.page = 1;
            call();
        },
        {
            deep:true
        });
        return{
            paginationState,
            list,
            total,
            search
        }
    }
}
</script>