<template>
    <aside class="sidenav bg-white navbar navbar-vertical navbar-expand-xs border-0 border-radius-xl my-3 fixed-start ms-4 " id="sidenav-main">
    <div class="sidenav-header">
      <i class="fas fa-times p-3 cursor-pointer text-secondary opacity-5 position-absolute end-0 top-0 d-none d-xl-none" aria-hidden="true" id="iconSidenav"></i>
      <a class="navbar-brand m-0" href="#/" target="_blank">
        <img :src="require('@/assets/img/favicon.png')" class="navbar-brand-img h-100" alt="main_logo">
        <span class="ms-1 font-weight-bold">Oktopi Dashboard</span>
      </a>
    </div>
    <hr class="horizontal dark mt-0">
    <div class="collapse navbar-collapse  w-auto h-auto" id="sidenav-collapse-main">
      <ul class="navbar-nav">
        <li class="nav-item">
          <a data-bs-toggle="collapse" href="#dashboardsExamples" class="nav-link active" aria-controls="dashboardsExamples" role="button" aria-expanded="false">
            <div class="icon icon-shape icon-sm text-center d-flex align-items-center justify-content-center">
              <i class="ni ni-shop text-primary text-sm opacity-10"></i>
            </div>
            <span class="nav-link-text ms-1">Dashboards</span>
          </a>
          <div class="collapse  show " id="dashboardsExamples">
            <ul class="nav ms-4">
              <li class="nav-item" :class="{active : activeTab == 'dashboard'}" v-if="hasPermission('ADMIN_USER_MANAGE')">
                <a :class="{active : activeTab == 'dashboard'}" class="nav-link" href="#/dashboard" @click="updateMenu">
                  <span class="sidenav-mini-icon"> D </span>
                  <span class="sidenav-normal"> Dashboard </span>
                </a>
              </li>
              <li class="nav-item " :class="{active : activeTab == 'user'}" v-if="hasPermission('ADMIN_USER_MANAGE')" >
                <a :class="{active : activeTab == 'user'}" class="nav-link " href="#/user" @click="updateMenu">
                  <span class="sidenav-mini-icon"> U </span>
                  <span class="sidenav-normal"> User List </span>
                </a>
              </li>
              <li class="nav-item " :class="{active : activeTab == 'adminuser'}" v-if="hasPermission('ADMIN_USER_MANAGE')" >
                <a :class="{active : activeTab == 'adminuser'}" class="nav-link " href="#/adminuser" @click="updateMenu">
                  <span class="sidenav-mini-icon"> A </span>
                  <span class="sidenav-normal"> Admin User </span>
                </a>
              </li>
              <li class="nav-item " :class="{active : activeTab == 'school'}" v-if="hasPermission('SCHOOL_MANAGE')" >
                <a :class="{active : activeTab == 'school'}" class="nav-link " href="#/school" @click="updateMenu">
                  <span class="sidenav-mini-icon"> S </span>
                  <span class="sidenav-normal"> School List </span>
                </a>
              </li>
              <li class="nav-item " :class="{active : activeTab == 'schoolOwner'}" v-if="hasPermission('SCHOOL_MANAGE')" >
                <a :class="{active : activeTab == 'schoolOwner'}" class="nav-link " href="#/schoolOwner" @click="updateMenu">
                  <span class="sidenav-mini-icon"> SOL </span>
                  <span class="sidenav-normal"> School Owner List </span>
                </a>
              </li>
              <li class="nav-item " :class="{active : activeTab == 'course'}" v-if="hasPermission('CLASS_MANAGE')" >
                <a :class="{active : activeTab == 'course'}" class="nav-link " href="#/course" @click="updateMenu">
                  <span class="sidenav-mini-icon"> C </span>
                  <span class="sidenav-normal"> Course List </span>
                </a>
              </li>
              <li class="nav-item " :class="{active : activeTab == 'studentResource'}" v-if="hasPermission('RESOURCE_MANAGE')" >
                <a :class="{active : activeTab == 'studentResource'}" class="nav-link " href="#/studentResource" @click="updateMenu">
                  <span class="sidenav-mini-icon"> SR </span>
                  <span class="sidenav-normal"> Student Resource </span>
                </a>
              </li>
              <li class="nav-item " :class="{active : activeTab == 'studentStatistic'}" v-if="hasPermission('RESOURCE_MANAGE')" >
                <a :class="{active : activeTab == 'studentStatistic'}" class="nav-link " href="#/studentStatistic" @click="updateMenu">
                  <span class="sidenav-mini-icon"> SS </span>
                  <span class="sidenav-normal"> Student Statistic </span>
                </a>
              </li>
              <li class="nav-item " :class="{active : activeTab == 'teacherStatistic'}" v-if="hasPermission('RESOURCE_MANAGE')" >
                <a :class="{active : activeTab == 'teacherStatistic'}" class="nav-link " href="#/teacherStatistic" @click="updateMenu">
                  <span class="sidenav-mini-icon"> TS </span>
                  <span class="sidenav-normal"> Teacher Statistic </span>
                </a>
              </li>
              <li class="nav-item " :class="{active : activeTab == 'marketplaceCourse'}" v-if="hasPermission('CLASS_MANAGE') || hasPermission('SEO_MANAGE')" >
                <a :class="{active : activeTab == 'marketplaceCourse'}" class="nav-link " href="#/marketplaceCourse" @click="updateMenu">
                  <span class="sidenav-mini-icon"> MC </span>
                  <span class="sidenav-normal"> Marketplace Course </span>
                </a>
              </li>
              <li class="nav-item " :class="{active : activeTab == 'seo'}" v-if="hasPermission('SEO_MANAGE')" >
                <a :class="{active : activeTab == 'seo'}" class="nav-link " href="#/seo" @click="updateMenu">
                  <span class="sidenav-mini-icon"> SP </span>
                  <span class="sidenav-normal"> Seo Pages </span>
                </a>
              </li>
              <li class="nav-item " :class="{active : activeTab == 'classCategory'}" v-if="hasPermission('CLASS_MANAGE')" >
                <a :class="{active : activeTab == 'classCategory'}" class="nav-link " href="#/classCategory" @click="updateMenu">
                  <span class="sidenav-mini-icon"> CC </span>
                  <span class="sidenav-normal"> Class Category </span>
                </a>
              </li>
              <li class="nav-item " :class="{active : activeTab == 'publishApplication'}" v-if="hasPermission('CLASS_MANAGE')" >
                <a :class="{active : activeTab == 'publishApplication'}" class="nav-link " href="#/publishApplication" @click="updateMenu">
                  <span class="sidenav-mini-icon"> PA </span>
                  <span class="sidenav-normal"> Publish Application </span>
                </a>
              </li>
              <li class="nav-item " :class="{active : activeTab == 'promoCode'}" v-if="hasPermission('ADMIN_USER_MANAGE')" >
                <a :class="{active : activeTab == 'promoCode'}" class="nav-link " href="#/promoCode" @click="updateMenu">
                  <span class="sidenav-mini-icon"> PC </span>
                  <span class="sidenav-normal"> Promo Code </span>
                </a>
              </li>
              <li class="nav-item " :class="{active : activeTab == 'paymentCode'}" v-if="hasPermission('ADMIN_USER_MANAGE')" >
                <a :class="{active : activeTab == 'paymentCode'}" class="nav-link " href="#/paymentCode" @click="updateMenu">
                  <span class="sidenav-mini-icon"> PMC </span>
                  <span class="sidenav-normal"> Payment Code </span>
                </a>
              </li>
              <li class="nav-item " :class="{active : activeTab == 'topEnrolledClasses'}" v-if="hasPermission('CLASS_MANAGE')" >
                <a :class="{active : activeTab == 'topEnrolledClasses'}" class="nav-link " href="#/topEnrolledClasses" @click="updateMenu">
                  <span class="sidenav-mini-icon"> TEC </span>
                  <span class="sidenav-normal"> Top Enrolled Classes </span>
                </a>
              </li>
              <!-- <li class="nav-item " :class="{active : activeTab == 'planItem'}" v-if="hasPermission('ADMIN_USER_MANAGE')" >
                <a :class="{active : activeTab == 'planItem'}" class="nav-link " href="#/planItem" @click="updateMenu">
                  <span class="sidenav-mini-icon"> PI </span>
                  <span class="sidenav-normal"> Plan Items </span>
                </a>
              </li> -->
              <li class="nav-item " :class="{active : activeTab == 'userPlan'}" v-if="hasPermission('PLAN_MANAGE')" >
                <a :class="{active : activeTab == 'userPlan'}" class="nav-link " href="#/userPlan" @click="updateMenu">
                  <span class="sidenav-mini-icon"> UP </span>
                  <span class="sidenav-normal"> User Plan List </span>
                </a>
              </li>
            </ul>
          </div>
        </li>
       
      </ul>
    </div>
  </aside>
</template>
<script>
export default {
  data : function () {
      return {
          activeTab : this.$route.path.substr(this.$route.path.lastIndexOf('/') + 1)
      }
  },
  methods : {
      updateMenu : function (event) {
          var str = event.target.toString();
          this.activeTab = str.substr(str.lastIndexOf('/') + 1);
      },
      hasPermission(val){
        let auth = localStorage.getItem('auth');
        if(auth){
          if(auth.indexOf(val) > -1){
            return true;
          }
          else{
            return false;
          }
        }
        else{
          return false;
        }
        
      }
  }
}
</script>