<template>
<div class="row">
    <div class="col-12">
        <div class="card">
            <!-- Card header -->
            <div class="card-header pb-0">
                <div class="d-lg-flex">
                <div>
                    <h5 class="mb-0">All publish application</h5>
                    <p class="text-sm mb-0">
                    Application list
                    </p>
                </div>
                </div>
            </div>
            <div class="card-body px-0 pb-0">
                <div class="table-responsive">
                    <div class="dataTable-wrapper dataTable-loading no-footer sortable searchable fixed-columns">
                        <div class="dataTable-top">
                            <div class="dataTable-dropdown">
                                <label><select class="dataTable-selector" v-model="paginationState.size">
                                    <option :value="5">5</option>
                                    <option :value="10">10</option>
                                    <option :value="15">15</option>
                                    <option :value="20">20</option>
                                    <option :value="25">25</option></select>
                                     entries per page</label>
                            </div>
                            <div class="dataTable-search">
                                <input class="dataTable-input mx-4" placeholder="Search..." type="text" v-model="paginationState.keyword">
                                <button type="button" class="btn btn-outline-primary btn-sm mb-0" @click="search">
                                Search
                                </button>
                            </div>
                        </div>
                        <div class="dataTable-container">
                            <table class="table table-flush" id="class-list">
                                <thead class="thead-light">
                                    <tr>
                                    <th>Class Name</th>
                                    <th>ageRange Type</th>
                                    <th>category Type</th>
                                    <th>price</th>
                                    <th>create Time</th>
                                    <th>Application state</th>
                                    <th>Action</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    <tr v-for="item in list"  v-bind:key="item">
                                    <td>
                                        <div class="d-flex">
                                        <!-- <div class="form-check my-auto">
                                            <input class="form-check-input" type="checkbox" id="customCheck1" checked>
                                        </div> -->
                                        <!-- <img class="w-10 ms-3" v-bind:src="item.avatar" alt="hoodie"> -->
                                        <h6 class="ms-3 my-auto">{{item.clazz?.clazzName}}</h6>
                                        </div>
                                    </td>
                                    <td class="text-sm">{{item.ageRangeType == null ? "All ages" : getAgeType(item.ageRangeType)}}</td>
                                    <td class="text-sm">{{item.categoryType == null ? "All categories" : getCategoryType(item.categoryType)}}</td>
                                    <td class="text-sm">{{item.clazz?.free ? "Free" : item.clazz?.currency + " " + (item.clazz?.price / 100).toFixed(2)}}</td>
                                    <td class="text-sm">{{item.createTime}}</td>
                                    <td>
                                        <span class="badge badge-sm" :class="item.publishState != 'approved' ? 'badge-danger' : 'badge-success'">{{item.publishState}}</span>
                                    </td>
                                    <td class="text-sm">
                                        <a :href="'#/publishApplication/' + item.id" data-bs-toggle="tooltip" data-bs-original-title="Preview application">
                                            <i class="fas fa-eye text-secondary"></i>
                                        </a>
                                        <a v-if="item.publishState == 'approved'" href="javascript:;" class="mx-2" data-bs-toggle="tooltip" v-b-modal.modalEditApplicationCategory data-bs-original-title="Edit category" @click="setApplicationId(item.id,item.ageRangeType,item.categoryType)">
                                        <i class="fas fa-user-edit text-secondary"></i>
                                        </a>
                                    </td>
                                    </tr>
                                    <tr v-if="total == 0">
                                        <td class="dataTables-empty" colspan="7">No entries found</td>
                                    </tr>
                                </tbody>
                            </table>
                        </div>
                        <div class="dataTable-bottom" v-if="total > paginationState.size">
                        <div class="dataTable-info">
                            Showing {{(paginationState.page - 1) * paginationState.size + 1}} to {{paginationState.page * paginationState.size > total ? total : paginationState.page * paginationState.size}} of {{total}} entries
                        </div>
                        <nav class="dataTable-pagination">

                        <b-pagination
                        v-model="paginationState.page"
                        :total-rows="total"
                        :per-page="parseInt(paginationState.size)"
                        aria-controls="my-table"
                    ></b-pagination>
                        </nav>
                    </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
    <b-modal id="modalEditApplicationCategory" centered @ok="edit" title="Edit Category.">
        <b-row class="my-1">
            <b-col sm="4" class=" my-auto text-end">
                <label class="my-auto">age range type:</label>
            </b-col>
            <b-col sm="8">
                <b-form-select v-model="age" :options="json.age" class="form-control" :state="ageState" 
                                        aria-describedby="age-feedback">
                    <b-form-select-option :value="null" v-if="age == ''">All ages</b-form-select-option>
                </b-form-select>
                <b-form-invalid-feedback id="age-feedback">
                    please select an age range
                </b-form-invalid-feedback>
            </b-col>
        </b-row>
        <b-row class="my-1">
            <b-col sm="4" class=" my-auto text-end">
                <label class="my-auto">category type:</label>
            </b-col>
            <b-col sm="8">
                <b-form-select v-model="category" :options="json.category" class="form-control"  :state="categoryState" 
                                        aria-describedby="category-feedback">
                    <b-form-select-option :value="null" v-if="category == ''">All categories</b-form-select-option>
                </b-form-select>
                <b-form-invalid-feedback id="category-feedback">
                    please select a category
                </b-form-invalid-feedback>
            </b-col>
        </b-row>
    </b-modal>
</div>
</template>

<script>
import { Dashboard } from '@/apiClient/Dashboard'
import { onMounted,ref,getCurrentInstance,watch,reactive } from 'vue';
import json from '@/plugins/category.json'
import bus from '@/plugins/bus';
let requestHeader;
export default {
    name:"applicationList",
    data(){
        return{
            age:"",
            category:"",
            applicationId:0,
            json:json
        }
    },
    computed: {
      ageState() {
        return this.age == null || this.age == "" ? false : true
      },
      categoryState() {
        return this.category == null || this.category == "" ? false : true
      }
    },
    methods:{
        getCategoryType(key){
            return this.json.showCategory[key];
        },
        getAgeType(key){
            return this.json.age[key];
        },
        edit(){
        let params = { ageRangeType: this.age,categoryType:this.category,id:this.applicationId};
        let api = new Dashboard();
        api.updateApplicationCategoryUsingPost(params,requestHeader).then(rep =>{
          if(rep.status == 200 && rep.data != null){
            if(rep.data.code == 0){
                bus.emit('success', rep.data.message);
            }
            else{
              bus.emit('error', rep.data.message);
            }
          }
          else{
            bus.emit('error',rep.data.message);
          }
        }).catch(function (error) {
          bus.emit('error', error);
        });
      },
      setApplicationId(id,age,category){
        this.applicationId = id;
        this.age = age == null ? "" : age;
        this.category = category == null ? "" : category;
      },
      reload(){
        this.$router.go()
      }
    },
    setup(){
        const list=ref();
        const total = ref(0);
        const paginationState = reactive({
            size: 10,
            page: 1,
            keyword:""
        });
        requestHeader = getCurrentInstance().appContext.config.globalProperties.RequestParams
        const search = () =>{
            if(paginationState.page == 1){
                call();
            }
            else{
                paginationState.page = 1;
            }
        }
        const call = () =>{
            bus.emit('load',true);
            let api = new Dashboard();
            let params = { keyword:paginationState.keyword,page:paginationState.page - 1,size:paginationState.size };
            api.getAllPublishApplicationsUsingGet(params,requestHeader).then((response) => {
                if(response.status == 200 && response.data != null){
                    if(response.data.code == 0){
                        list.value= response.data?.data?.content;
                        total.value = parseInt(response.data?.data.totalElements)
                    }
                    else{
                        bus.emit('error', response.data.message);
                    }
                }
            }).catch((error) => { bus.emit('error', error); }).finally(()=>{ bus.emit('load', false); });
        }
        onMounted(()=>{
            call();
        });
        watch(()=> paginationState.page,()=>{
            call();
        },
        {
            deep:true
        });
         watch(()=> [paginationState.size],()=>{
            paginationState.page = 1;
            call();
        },
        {
            deep:true
        });
        return{
            paginationState,
            list,
            total,
            search
        }
    }
}
</script>