<template>
    <div class="row mb-5">
        <div class="col-lg-12 mt-lg-0 mt-4">
          <!-- Card Profile -->
          <div class="card card-body" id="profile">
            <div class="row justify-content-center align-items-center">
              <div class="col-sm-auto col-4">
                <div>
              <img v-if="userProfile.image" :src="userProfile.image + '?size=80x80'" class="avatar rounded-circle avatar-xl position-relative">
              <span v-else-if="!userProfile.image" class="bg-primary rounded-circle avatar text-uppercase"><span class="text-light">{{getWord(userProfile.userName)}}</span></span>
                </div>
              </div>
              <div class="col-sm-auto col-8 my-auto">
                <div class="h-100">
                  <h5 class="mb-1 font-weight-bolder">
                    {{userProfile.userName}}
                  </h5>
                  <p class="mb-0 font-weight-bold text-sm" v-if="userProfile.email">
                    Email:{{userProfile.email}}
                  </p>
                </div>
              </div>
              
              <div class="col-sm-auto ms-sm-auto mt-sm-0 mt-3 d-flex">
                <label class="form-check-label mb-0">
                  <small id="profileVisibility">
                  </small>
                </label>
                <div class="form-check form-switch ms-2">
                </div>
              </div>
            </div>
          </div>
          <!-- Card Notifications -->
          <div class="card mt-4" id="notifications">
            <div class="card-header">
              <h5>Admin User Authorities</h5>
              <p class="text-sm">Choose the permissions to enable.</p>
            </div>
            <div class="card-body pt-0">
              <div class="table-responsive">
                <table class="table mb-0">
                  <thead>
                    <tr>
                      <th class="ps-1">
                        <p class="mb-0">Activity</p>
                      </th>
                      <th class="text-center">
                        <p class="mb-0">Enable/Disable</p>
                      </th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr v-for="item in roleList"  v-bind:key="item.id">
                      <td class="ps-1">
                        <div class="my-auto">
                          <p class="text-sm mb-0">{{item.desc}}</p>
                        </div>
                      </td>
                      <td>
                        <div class="form-check form-switch mb-0 d-flex align-items-center justify-content-center">
                          <input class="form-check-input" type="checkbox" name="roles" :value="item.authority" :checked="item.checked"  v-model="checkRols">
                        </div>
                      </td>
                    </tr>
                  </tbody>
                </table>
              </div>
            </div>
          </div>
          <div class="card mt-4" id="delete">
            <div class="card-header">
            </div>
            <div class="card-body d-sm-flex pt-0">
              <button class="btn btn-outline-secondary mb-0 ms-auto" type="button" name="button" @click="save">Save</button>
            </div>
          </div>
        </div>
      </div>
</template>
<script>
import { Dashboard } from '@/apiClient/Dashboard'
import { defineComponent,onMounted,ref,getCurrentInstance,reactive } from 'vue';
import { useRouter } from "vue-router";
import qs from 'qs'
import bus from '@/plugins/bus';

let requestParams;
let userid;
let checkRols=[];
export default defineComponent({
  name: 'adminUserList',
  data() {
      return {
          checkRols:checkRols
      }
  },
  methods: {
      getWord(f){
        f = f ? f : "";
        let arr = f.split(' ');
        let first = arr ? arr[0].slice(0,1) : "";
        let last = arr[1] ? arr[1].slice(0,1) : "";
        return first + last;
      },
      save(){
        var roles = [...new Set(this.checkRols)]
        let params = { userId: userid,roles:roles };
        console.log(params);
        requestParams["paramsSerializer"] = params => qs.stringify(params, {arrayFormat: 'repeat'});
        let api = new Dashboard();
        api.updateAdminUserRolesUsingPost(params,requestParams).then(rep =>{
          if(rep.status == 200 && rep.data != null){
            if(rep.data.code == 0){
              if(userid == localStorage.getItem("userId")){
                localStorage.setItem("auth",roles);
              }
              bus.emit('success', rep.data.message);
            }
            else{
              bus.emit('error', rep.data.message);
            }
          }
        }).catch(function (error) {
          bus.emit('error', error);
        });
      }
  },
  setup(){
    checkRols=[];
    const roleList=ref();
    const userProfile= reactive({
            email:"",
            image:"",
            userName:""
          });
    
    requestParams = getCurrentInstance().appContext.config.globalProperties.RequestParams;
    const router = useRouter();
    userid = router.currentRoute.value.params.id;
    roleList.value = [
      {desc:"Admin User Manage",authority: "ADMIN_USER_MANAGE",checked:false},
      {desc:"School Manage",authority: "SCHOOL_MANAGE",checked:false},
      {desc:"Student Manage",authority: "STUDENT_MANAGE",checked:false},
      {desc:"Class Manage",authority: "CLASS_MANAGE",checked:false},
      {desc:"Resource Manage",authority: "RESOURCE_MANAGE",checked:false},
      {desc:"SEO Manage",authority: "SEO_MANAGE",checked:false},
      {desc:"Plan Manage",authority: "PLAN_MANAGE",checked:false}]
    onMounted(()=>{
      let api = new Dashboard();
      let params = { userId: userid };

      api.getUserProfileUsingGet(params,requestParams).then(rep=>{
        if(rep.status == 200 && rep.data != null){
          if(rep.data.code == 0){
            let data = rep.data.data;
            userProfile.image = data.avatar;
            userProfile.email = data.email;
            userProfile.userName = data.fullNameOrAccountName;
          }
          else{
            bus.emit("error",rep.data.message)
          }
        }
      }).catch(function (error) {
          bus.emit('error', error);
      });
    
    api.getAdminUserRolesUsingGet(params,requestParams).then(rep=>{
      if(rep.status == 200 && rep.data != null){
        if(rep.data.code == 0){
          let data = rep.data.data;
          roleList.value && roleList.value.forEach(function(item){
            data && data.forEach(function(v) {
              if(v.authority === item.authority){
                item.checked = true;
                checkRols.push(v.authority)
              }
            });
          });
        }
        else{
          bus.emit('error', rep.data.message);
        }
      }
    }).catch(function (error) {
        bus.emit('error', error);
    });
  })
    return{
      roleList,
      userProfile
    }
 
  }
});
</script>
