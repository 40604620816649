/* eslint-disable */
/* tslint:disable */
/*
 * ---------------------------------------------------------------
 * ## THIS FILE WAS GENERATED VIA SWAGGER-TYPESCRIPT-API        ##
 * ##                                                           ##
 * ## AUTHOR: acacode                                           ##
 * ## SOURCE: https://github.com/acacode/swagger-typescript-api ##
 * ---------------------------------------------------------------
 */

import {
  ClassCategoryInfo,
  CreatePaymentCodeData,
  CreateSeoData,
  PromoCode,
  ReqRequestUpload,
  ResBase,
  ResBaseTBusiness,
  ResBaseTClassData,
  ResBaseTListAdminUserAuthority,
  ResBaseTListStudentResourceAttachment,
  ResBaseTListV2MarketClazz,
  ResBaseTLoginData,
  ResBaseTMarketplaceSeoData,
  ResBaseTPageActivity,
  ResBaseTPageClassCategoryInfo,
  ResBaseTPageClazz,
  ResBaseTPageClazzUser,
  ResBaseTPageEsClassActivity,
  ResBaseTPageEsMarketClass,
  ResBaseTPageFileClass,
  ResBaseTPageFileSchool,
  ResBaseTPageGenie,
  ResBaseTPageMarketplaceSeo,
  ResBaseTPagePaymentCodeData,
  ResBaseTPagePlanItem,
  ResBaseTPagePromoCode,
  ResBaseTPagePublishApplicationData,
  ResBaseTPagePurchaseOrder,
  ResBaseTPageSchoolData,
  ResBaseTPageSchoolOwnerData,
  ResBaseTPageStatisticUserData,
  ResBaseTPageStudentResourceData,
  ResBaseTPageUser,
  ResBaseTPageUserPlanBill,
  ResBaseTPageUserPlanData,
  ResBaseTPageV2MarketClazz,
  ResBaseTPageV2StudentResourceItem,
  ResBaseTPublishApplicationData,
  ResBaseTResRequestUpload,
  ResBaseTSchool,
  ResBaseTSchoolAnalysis,
  ResBaseTSchoolOwnerData,
  ResBaseTString,
  ResBaseTStripeAccount,
  ResBaseTStudentResourceDetail,
  ResBaseTUser,
  ResGetResourceRatingStatistics,
  ResIgnore,
} from "./data-contracts";
import { ContentType, HttpClient, RequestParams } from "./http-client";

export class Dashboard<SecurityDataType = unknown> extends HttpClient<SecurityDataType> {
  /**
   * No description
   *
   * @tags admin-user-api-controller
   * @name AddAdminUserUsingPost
   * @summary AddAdminUser
   * @request POST:/dashboard/admin/addAdminUser
   */
  addAdminUserUsingPost = (query: { email: string }, params: RequestParams = {}) =>
    this.request<ResBase, void>({
      path: `/dashboard/admin/addAdminUser`,
      method: "POST",
      query: query,
      type: ContentType.Json,
      ...params,
    });
  /**
   * No description
   *
   * @tags admin-user-api-controller
   * @name GetAdminListUsingGet
   * @summary getAdminList
   * @request GET:/dashboard/admin/admins
   */
  getAdminListUsingGet = (
    query: { keyword: string; page?: number; size?: number; sort?: string[] },
    params: RequestParams = {},
  ) =>
    this.request<ResBaseTPageUser, void>({
      path: `/dashboard/admin/admins`,
      method: "GET",
      query: query,
      ...params,
    });
  /**
   * No description
   *
   * @tags admin-user-api-controller
   * @name DeleteAdminUserUsingPost
   * @summary deleteAdminUser
   * @request POST:/dashboard/admin/deleteAdminUser
   */
  deleteAdminUserUsingPost = (query: { userId: number }, params: RequestParams = {}) =>
    this.request<ResBase, void>({
      path: `/dashboard/admin/deleteAdminUser`,
      method: "POST",
      query: query,
      type: ContentType.Json,
      ...params,
    });
  /**
   * No description
   *
   * @tags admin-user-api-controller
   * @name GetAdminUserRolesUsingGet
   * @summary getAdminUserRoles
   * @request GET:/dashboard/admin/getAdminUserRoles
   */
  getAdminUserRolesUsingGet = (query: { userId: number }, params: RequestParams = {}) =>
    this.request<ResBaseTListAdminUserAuthority, void>({
      path: `/dashboard/admin/getAdminUserRoles`,
      method: "GET",
      query: query,
      ...params,
    });
  /**
   * No description
   *
   * @tags admin-user-api-controller
   * @name LoginUsingPost
   * @summary login
   * @request POST:/dashboard/admin/login
   */
  loginUsingPost = (query: { accountName: string; password: string }, params: RequestParams = {}) =>
    this.request<ResBaseTLoginData, void>({
      path: `/dashboard/admin/login`,
      method: "POST",
      query: query,
      type: ContentType.Json,
      ...params,
    });
  /**
   * No description
   *
   * @tags admin-user-api-controller
   * @name UpdateAdminUserRolesUsingPost
   * @summary UpdateAdminUserRoles
   * @request POST:/dashboard/admin/updateAdminUserRoles
   */
  updateAdminUserRolesUsingPost = (query: { roles: string[]; userId: number }, params: RequestParams = {}) =>
    this.request<ResBase, void>({
      path: `/dashboard/admin/updateAdminUserRoles`,
      method: "POST",
      query: query,
      type: ContentType.Json,
      ...params,
    });
  /**
   * No description
   *
   * @tags class-api-controller
   * @name AddOrUpdateClassCategoryInfoUsingPost
   * @summary addOrUpdateClassCategoryInfo
   * @request POST:/dashboard/class/addOrUpdateClassCategoryInfo
   */
  addOrUpdateClassCategoryInfoUsingPost = (info: ClassCategoryInfo, params: RequestParams = {}) =>
    this.request<ResBase, void>({
      path: `/dashboard/class/addOrUpdateClassCategoryInfo`,
      method: "POST",
      body: info,
      type: ContentType.Json,
      ...params,
    });
  /**
   * No description
   *
   * @tags class-api-controller
   * @name ApprovedPublishApplicationUsingPost
   * @summary approvedPublishApplication
   * @request POST:/dashboard/class/approvedPublishApplication
   */
  approvedPublishApplicationUsingPost = (
    query: {
      ageRangeType: "from5to9" | "from10to11" | "from12to14" | "from15plus" | "from18plus" | "from15to18";
      categoryType:
        | "academicTutoringSupport"
        | "languages"
        | "steam"
        | "financialEconomicsLiteracy"
        | "socialSciences"
        | "physicalEducation"
        | "socialDevelopment"
        | "artMusic"
        | "itSoftware"
        | "businessEntrepreneurship"
        | "financeAccounting"
        | "officeProductivity"
        | "design"
        | "marketing"
        | "lifeStyle"
        | "photographyVideo"
        | "healthFitness";
      id: number;
    },
    params: RequestParams = {},
  ) =>
    this.request<ResBase, void>({
      path: `/dashboard/class/approvedPublishApplication`,
      method: "POST",
      query: query,
      type: ContentType.Json,
      ...params,
    });
  /**
   * No description
   *
   * @tags class-api-controller
   * @name CourseGenerateSeoUsingPost
   * @summary courseGenerateSEO
   * @request POST:/dashboard/class/courseGenerateSEO
   */
  courseGenerateSeoUsingPost = (data: CreateSeoData, params: RequestParams = {}) =>
    this.request<ResBaseTString, void>({
      path: `/dashboard/class/courseGenerateSEO`,
      method: "POST",
      body: data,
      type: ContentType.Json,
      ...params,
    });
  /**
   * No description
   *
   * @tags class-api-controller
   * @name GetAllClassesPageUsingGet
   * @summary getAllClassesPage
   * @request GET:/dashboard/class/getAllClassesPage
   */
  getAllClassesPageUsingGet = (
    query: { keyword: string; page?: number; size?: number; sort?: string[] },
    params: RequestParams = {},
  ) =>
    this.request<ResBaseTPageClazz, void>({
      path: `/dashboard/class/getAllClassesPage`,
      method: "GET",
      query: query,
      ...params,
    });
  /**
   * No description
   *
   * @tags class-api-controller
   * @name GetAllLearnSpaceClassesPageUsingGet
   * @summary getAllLearnSpaceClassesPage
   * @request GET:/dashboard/class/getAllLearnSpaceClassesPage
   */
  getAllLearnSpaceClassesPageUsingGet = (
    query: { keyword: string; page?: number; size?: number; sort?: string[] },
    params: RequestParams = {},
  ) =>
    this.request<ResBaseTPageV2MarketClazz, void>({
      path: `/dashboard/class/getAllLearnSpaceClassesPage`,
      method: "GET",
      query: query,
      ...params,
    });
  /**
   * No description
   *
   * @tags class-api-controller
   * @name GetAllPublishApplicationsUsingGet
   * @summary getAllPublishApplications
   * @request GET:/dashboard/class/getAllPublishApplications
   */
  getAllPublishApplicationsUsingGet = (
    query: { keyword: string; page?: number; size?: number; sort?: string[] },
    params: RequestParams = {},
  ) =>
    this.request<ResBaseTPagePublishApplicationData, void>({
      path: `/dashboard/class/getAllPublishApplications`,
      method: "GET",
      query: query,
      ...params,
    });
  /**
   * No description
   *
   * @tags class-api-controller
   * @name GetClassByIdUsingGet
   * @summary getClassById
   * @request GET:/dashboard/class/getClassById
   */
  getClassByIdUsingGet = (query: { classId: number }, params: RequestParams = {}) =>
    this.request<ResBaseTClassData, void>({
      path: `/dashboard/class/getClassById`,
      method: "GET",
      query: query,
      ...params,
    });
  /**
   * No description
   *
   * @tags class-api-controller
   * @name GetClassCategoriesUsingPost
   * @summary getClassCategories
   * @request POST:/dashboard/class/getClassCategories
   */
  getClassCategoriesUsingPost = (
    query?: { page?: number; size?: number; sort?: string[] },
    params: RequestParams = {},
  ) =>
    this.request<ResBaseTPageClassCategoryInfo, void>({
      path: `/dashboard/class/getClassCategories`,
      method: "POST",
      query: query,
      type: ContentType.Json,
      ...params,
    });
  /**
   * No description
   *
   * @tags class-api-controller
   * @name GetClassLearnersByClassIdUsingGet
   * @summary getClassLearnersByClassId
   * @request GET:/dashboard/class/getClassLearnersByClassId
   */
  getClassLearnersByClassIdUsingGet = (
    query: { classId: number; page?: number; size?: number; sort?: string[] },
    params: RequestParams = {},
  ) =>
    this.request<ResBaseTPageClazzUser, void>({
      path: `/dashboard/class/getClassLearnersByClassId`,
      method: "GET",
      query: query,
      ...params,
    });
  /**
   * No description
   *
   * @tags class-api-controller
   * @name GetMarketplaceSeoBySlugUsingPost
   * @summary getMarketplaceSeoBySlug
   * @request POST:/dashboard/class/getMarketplaceSeoBySlug
   */
  getMarketplaceSeoBySlugUsingPost = (query: { slug: string }, params: RequestParams = {}) =>
    this.request<ResBaseTMarketplaceSeoData, void>({
      path: `/dashboard/class/getMarketplaceSeoBySlug`,
      method: "POST",
      query: query,
      type: ContentType.Json,
      ...params,
    });
  /**
   * No description
   *
   * @tags class-api-controller
   * @name GetNewCoursesTodayUsingGet
   * @summary getNewCoursesToday
   * @request GET:/dashboard/class/getNewCoursesToday
   */
  getNewCoursesTodayUsingGet = (
    query?: { page?: number; size?: number; sort?: string[] },
    params: RequestParams = {},
  ) =>
    this.request<ResBaseTPageClazz, void>({
      path: `/dashboard/class/getNewCoursesToday`,
      method: "GET",
      query: query,
      ...params,
    });
  /**
   * No description
   *
   * @tags class-api-controller
   * @name GetNewCoursesWeekUsingGet
   * @summary getNewCoursesWeek
   * @request GET:/dashboard/class/getNewCoursesWeek
   */
  getNewCoursesWeekUsingGet = (query?: { page?: number; size?: number; sort?: string[] }, params: RequestParams = {}) =>
    this.request<ResBaseTPageClazz, void>({
      path: `/dashboard/class/getNewCoursesWeek`,
      method: "GET",
      query: query,
      ...params,
    });
  /**
   * No description
   *
   * @tags class-api-controller
   * @name GetNewLearnSpaceClassesByTodayUsingGet
   * @summary getNewLearnSpaceClassesByToday
   * @request GET:/dashboard/class/getNewLearnSpaceClassesByToday
   */
  getNewLearnSpaceClassesByTodayUsingGet = (
    query?: { page?: number; size?: number; sort?: string[] },
    params: RequestParams = {},
  ) =>
    this.request<ResBaseTPageEsMarketClass, void>({
      path: `/dashboard/class/getNewLearnSpaceClassesByToday`,
      method: "GET",
      query: query,
      ...params,
    });
  /**
   * No description
   *
   * @tags class-api-controller
   * @name GetPublishApplicationByIdUsingGet
   * @summary getPublishApplicationById
   * @request GET:/dashboard/class/getPublishApplicationById
   */
  getPublishApplicationByIdUsingGet = (query: { id: number }, params: RequestParams = {}) =>
    this.request<ResBaseTPublishApplicationData, void>({
      path: `/dashboard/class/getPublishApplicationById`,
      method: "GET",
      query: query,
      ...params,
    });
  /**
   * No description
   *
   * @tags class-api-controller
   * @name GetSeoPagesUsingPost
   * @summary getSeoPages
   * @request POST:/dashboard/class/getSeoPages
   */
  getSeoPagesUsingPost = (
    query: { keyword: string; page?: number; size?: number; sort?: string[] },
    params: RequestParams = {},
  ) =>
    this.request<ResBaseTPageMarketplaceSeo, void>({
      path: `/dashboard/class/getSeoPages`,
      method: "POST",
      query: query,
      type: ContentType.Json,
      ...params,
    });
  /**
   * No description
   *
   * @tags class-api-controller
   * @name GetTopStudentEnrolledLearnSpaceClassesPageUsingGet
   * @summary getTopStudentEnrolledLearnSpaceClassesPage
   * @request GET:/dashboard/class/getTopStudentEnrolledLearnSpaceClassesPage
   */
  getTopStudentEnrolledLearnSpaceClassesPageUsingGet = (
    query: { keyword: string; page?: number; size?: number; sort?: string[] },
    params: RequestParams = {},
  ) =>
    this.request<ResBaseTListV2MarketClazz, void>({
      path: `/dashboard/class/getTopStudentEnrolledLearnSpaceClassesPage`,
      method: "GET",
      query: query,
      ...params,
    });
  /**
   * No description
   *
   * @tags class-api-controller
   * @name RejectedPublishApplicationUsingPost
   * @summary rejectedPublishApplication
   * @request POST:/dashboard/class/rejectedPublishApplication
   */
  rejectedPublishApplicationUsingPost = (query: { id: number; rejectReason: string }, params: RequestParams = {}) =>
    this.request<ResBase, void>({
      path: `/dashboard/class/rejectedPublishApplication`,
      method: "POST",
      query: query,
      type: ContentType.Json,
      ...params,
    });
  /**
   * No description
   *
   * @tags class-api-controller
   * @name RemoveCourseByIdUsingPost
   * @summary removeCourseById
   * @request POST:/dashboard/class/removeCourseById
   */
  removeCourseByIdUsingPost = (query: { id: number }, params: RequestParams = {}) =>
    this.request<ResBase, void>({
      path: `/dashboard/class/removeCourseById`,
      method: "POST",
      query: query,
      type: ContentType.Json,
      ...params,
    });
  /**
   * No description
   *
   * @tags class-api-controller
   * @name RemoveLearnSpaceClassByIdUsingPost
   * @summary removeLearnSpaceClassById
   * @request POST:/dashboard/class/removeLearnSpaceClassById
   */
  removeLearnSpaceClassByIdUsingPost = (query: { classId: number; schoolId: number }, params: RequestParams = {}) =>
    this.request<ResBase, void>({
      path: `/dashboard/class/removeLearnSpaceClassById`,
      method: "POST",
      query: query,
      type: ContentType.Json,
      ...params,
    });
  /**
   * No description
   *
   * @tags class-api-controller
   * @name RemoveSeoPageByIdUsingPost
   * @summary removeSeoPageById
   * @request POST:/dashboard/class/removeSeoPageById
   */
  removeSeoPageByIdUsingPost = (query: { id: number }, params: RequestParams = {}) =>
    this.request<ResBase, void>({
      path: `/dashboard/class/removeSeoPageById`,
      method: "POST",
      query: query,
      type: ContentType.Json,
      ...params,
    });
  /**
   * No description
   *
   * @tags class-api-controller
   * @name RequestUploadUsingPost
   * @summary requestUpload
   * @request POST:/dashboard/class/requestUpload
   */
  requestUploadUsingPost = (reqRequestUpload: ReqRequestUpload, params: RequestParams = {}) =>
    this.request<ResBaseTResRequestUpload, void>({
      path: `/dashboard/class/requestUpload`,
      method: "POST",
      body: reqRequestUpload,
      type: ContentType.Json,
      ...params,
    });
  /**
   * No description
   *
   * @tags class-api-controller
   * @name UpdateApplicationCategoryUsingPost
   * @summary updateApplicationCategory
   * @request POST:/dashboard/class/updateApplicationCategory
   */
  updateApplicationCategoryUsingPost = (
    query: {
      ageRangeType: "from5to9" | "from10to11" | "from12to14" | "from15plus" | "from18plus" | "from15to18";
      categoryType:
        | "academicTutoringSupport"
        | "languages"
        | "steam"
        | "financialEconomicsLiteracy"
        | "socialSciences"
        | "physicalEducation"
        | "socialDevelopment"
        | "artMusic"
        | "itSoftware"
        | "businessEntrepreneurship"
        | "financeAccounting"
        | "officeProductivity"
        | "design"
        | "marketing"
        | "lifeStyle"
        | "photographyVideo"
        | "healthFitness";
      id: number;
    },
    params: RequestParams = {},
  ) =>
    this.request<ResBase, void>({
      path: `/dashboard/class/updateApplicationCategory`,
      method: "POST",
      query: query,
      type: ContentType.Json,
      ...params,
    });
  /**
   * No description
   *
   * @tags class-api-controller
   * @name UpdateLearnSpaceClassOrderUsingGet
   * @summary updateLearnSpaceClassOrder
   * @request GET:/dashboard/class/updateLearnSpaceClassOrder
   */
  updateLearnSpaceClassOrderUsingGet = (
    query: { newClassId: number; oldClassId: number },
    params: RequestParams = {},
  ) =>
    this.request<ResBase, void>({
      path: `/dashboard/class/updateLearnSpaceClassOrder`,
      method: "GET",
      query: query,
      ...params,
    });
  /**
   * No description
   *
   * @tags class-api-controller
   * @name UpdateLearnSpaceClassOrderByNewIndexUsingGet
   * @summary updateLearnSpaceClassOrderByNewIndex
   * @request GET:/dashboard/class/updateLearnSpaceClassOrderByNewIndex
   */
  updateLearnSpaceClassOrderByNewIndexUsingGet = (
    query: { classId: number; newIndex: number },
    params: RequestParams = {},
  ) =>
    this.request<ResBase, void>({
      path: `/dashboard/class/updateLearnSpaceClassOrderByNewIndex`,
      method: "GET",
      query: query,
      ...params,
    });
  /**
   * No description
   *
   * @tags class-api-controller
   * @name UpdateMarketplaceCategoryUsingPost
   * @summary updateMarketplaceCategory
   * @request POST:/dashboard/class/updateMarketplaceCategory
   */
  updateMarketplaceCategoryUsingPost = (
    query: {
      ageRangeType: "from5to9" | "from10to11" | "from12to14" | "from15plus" | "from18plus" | "from15to18";
      categoryType:
        | "academicTutoringSupport"
        | "languages"
        | "steam"
        | "financialEconomicsLiteracy"
        | "socialSciences"
        | "physicalEducation"
        | "socialDevelopment"
        | "artMusic"
        | "itSoftware"
        | "businessEntrepreneurship"
        | "financeAccounting"
        | "officeProductivity"
        | "design"
        | "marketing"
        | "lifeStyle"
        | "photographyVideo"
        | "healthFitness";
      id: number;
    },
    params: RequestParams = {},
  ) =>
    this.request<ResBase, void>({
      path: `/dashboard/class/updateMarketplaceCategory`,
      method: "POST",
      query: query,
      type: ContentType.Json,
      ...params,
    });
  /**
   * No description
   *
   * @tags payment-api-controller
   * @name CreatePaymentCodeUsingPost
   * @summary createPaymentCode
   * @request POST:/dashboard/payment/createPaymentCode
   */
  createPaymentCodeUsingPost = (paymentCode: CreatePaymentCodeData, params: RequestParams = {}) =>
    this.request<ResBase, void>({
      path: `/dashboard/payment/createPaymentCode`,
      method: "POST",
      body: paymentCode,
      type: ContentType.Json,
      ...params,
    });
  /**
   * No description
   *
   * @tags payment-api-controller
   * @name GetPaymentCodsUsingGet
   * @summary getPaymentCods
   * @request GET:/dashboard/payment/getPaymentCods
   */
  getPaymentCodsUsingGet = (
    query?: { code?: string; page?: number; size?: number; sort?: string[]; status?: boolean; userId?: number },
    params: RequestParams = {},
  ) =>
    this.request<ResBaseTPagePaymentCodeData, void>({
      path: `/dashboard/payment/getPaymentCods`,
      method: "GET",
      query: query,
      ...params,
    });
  /**
   * No description
   *
   * @tags payment-api-controller
   * @name GetPlanItemsUsingGet
   * @summary getPlanItems
   * @request GET:/dashboard/payment/getPlanItems
   */
  getPlanItemsUsingGet = (query?: { page?: number; size?: number; sort?: string[] }, params: RequestParams = {}) =>
    this.request<ResBaseTPagePlanItem, void>({
      path: `/dashboard/payment/getPlanItems`,
      method: "GET",
      query: query,
      ...params,
    });
  /**
   * No description
   *
   * @tags payment-api-controller
   * @name GetUserPlanBillsUsingGet
   * @summary getUserPlanBills
   * @request GET:/dashboard/payment/getUserPlanBills
   */
  getUserPlanBillsUsingGet = (
    query: { endDate: string; page?: number; size?: number; sort?: string[]; startDate: string; userId: number },
    params: RequestParams = {},
  ) =>
    this.request<ResBaseTPageUserPlanBill, void>({
      path: `/dashboard/payment/getUserPlanBills`,
      method: "GET",
      query: query,
      ...params,
    });
  /**
   * No description
   *
   * @tags payment-api-controller
   * @name GetUserPlansUsingGet
   * @summary getUserPlans
   * @request GET:/dashboard/payment/getUserPlans
   */
  getUserPlansUsingGet = (
    query: {
      keyword: string;
      page?: number;
      planType: "starter" | "basic" | "intermediate" | "pro" | "enterprise";
      size?: number;
      sort?: string[];
    },
    params: RequestParams = {},
  ) =>
    this.request<ResBaseTPageUserPlanData, void>({
      path: `/dashboard/payment/getUserPlans`,
      method: "GET",
      query: query,
      ...params,
    });
  /**
   * No description
   *
   * @tags payment-api-controller
   * @name GetUserPurchaseOrdersUsingGet
   * @summary getUserPurchaseOrders
   * @request GET:/dashboard/payment/getUserPurchaseOrders
   */
  getUserPurchaseOrdersUsingGet = (
    query: { endDate: string; page?: number; size?: number; sort?: string[]; startDate: string; userId: number },
    params: RequestParams = {},
  ) =>
    this.request<ResBaseTPagePurchaseOrder, void>({
      path: `/dashboard/payment/getUserPurchaseOrders`,
      method: "GET",
      query: query,
      ...params,
    });
  /**
   * No description
   *
   * @tags payment-api-controller
   * @name GetUserStripeAccountUsingGet
   * @summary getUserStripeAccount
   * @request GET:/dashboard/payment/getUserStripeAccount
   */
  getUserStripeAccountUsingGet = (query: { userId: number }, params: RequestParams = {}) =>
    this.request<ResBaseTStripeAccount, void>({
      path: `/dashboard/payment/getUserStripeAccount`,
      method: "GET",
      query: query,
      ...params,
    });
  /**
   * No description
   *
   * @tags payment-api-controller
   * @name GetUserTapAccountUsingGet
   * @summary getUserTapAccount
   * @request GET:/dashboard/payment/getUserTapAccount
   */
  getUserTapAccountUsingGet = (query: { userId: number }, params: RequestParams = {}) =>
    this.request<ResBaseTBusiness, void>({
      path: `/dashboard/payment/getUserTapAccount`,
      method: "GET",
      query: query,
      ...params,
    });
  /**
   * No description
   *
   * @tags payment-api-controller
   * @name UpdatePlanItemUsingPost
   * @summary updatePlanItem
   * @request POST:/dashboard/payment/updatePlanItem
   */
  updatePlanItemUsingPost = (
    query: { enableRegisterStudent: boolean; planType: "starter" | "basic" | "intermediate" | "pro" | "enterprise" },
    params: RequestParams = {},
  ) =>
    this.request<ResBase, void>({
      path: `/dashboard/payment/updatePlanItem`,
      method: "POST",
      query: query,
      type: ContentType.Json,
      ...params,
    });
  /**
   * No description
   *
   * @tags payment-api-controller
   * @name UpdateUserPlanUsingPost
   * @summary updateUserPlan
   * @request POST:/dashboard/payment/updateUserPlan
   */
  updateUserPlanUsingPost = (
    query: {
      enableBundle: boolean;
      enableOktopiCode: boolean;
      enableRegisterStudent: boolean;
      licenseCount: number;
      max_session_attendees: number;
      planType: "starter" | "basic" | "intermediate" | "pro" | "enterprise";
      userId: number;
    },
    params: RequestParams = {},
  ) =>
    this.request<ResBase, void>({
      path: `/dashboard/payment/updateUserPlan`,
      method: "POST",
      query: query,
      type: ContentType.Json,
      ...params,
    });
  /**
   * No description
   *
   * @tags promo-code-api-controller
   * @name CreateNewPromoCodeUsingPost
   * @summary createNewPromoCode
   * @request POST:/dashboard/promoCode/createNewPromoCode
   */
  createNewPromoCodeUsingPost = (promoCode: PromoCode, params: RequestParams = {}) =>
    this.request<ResIgnore, void>({
      path: `/dashboard/promoCode/createNewPromoCode`,
      method: "POST",
      body: promoCode,
      type: ContentType.Json,
      ...params,
    });
  /**
   * No description
   *
   * @tags promo-code-api-controller
   * @name GetAllPromoCodeUsingGet
   * @summary getAllPromoCode
   * @request GET:/dashboard/promoCode/getAllPromoCode
   */
  getAllPromoCodeUsingGet = (
    query: { email: string; endDate: string; page?: number; size?: number; sort?: string[]; startDate: string },
    params: RequestParams = {},
  ) =>
    this.request<ResBaseTPagePromoCode, void>({
      path: `/dashboard/promoCode/getAllPromoCode`,
      method: "GET",
      query: query,
      ...params,
    });
  /**
   * No description
   *
   * @tags resource-api-controller
   * @name GetAllClassResourceUsingGet
   * @summary getAllClassResource
   * @request GET:/dashboard/resource/getAllClassResource
   */
  getAllClassResourceUsingGet = (
    query: { classId: number; page?: number; size?: number; sort?: string[] },
    params: RequestParams = {},
  ) =>
    this.request<ResBaseTPageEsClassActivity, void>({
      path: `/dashboard/resource/getAllClassResource`,
      method: "GET",
      query: query,
      ...params,
    });
  /**
   * No description
   *
   * @tags resource-api-controller
   * @name GetAllSchoolResourceUsingGet
   * @summary getAllSchoolResource
   * @request GET:/dashboard/resource/getAllSchoolResource
   */
  getAllSchoolResourceUsingGet = (
    query: { keyword: string; page?: number; schoolId: number; size?: number; sort?: string[] },
    params: RequestParams = {},
  ) =>
    this.request<ResBaseTPageActivity, void>({
      path: `/dashboard/resource/getAllSchoolResource`,
      method: "GET",
      query: query,
      ...params,
    });
  /**
   * No description
   *
   * @tags resource-api-controller
   * @name GetAllStudentResourceUsingGet
   * @summary getAllStudentResource
   * @request GET:/dashboard/resource/getAllStudentResource
   */
  getAllStudentResourceUsingGet = (
    query: { keyword: string; page?: number; size?: number; sort?: string[] },
    params: RequestParams = {},
  ) =>
    this.request<ResBaseTPageStudentResourceData, void>({
      path: `/dashboard/resource/getAllStudentResource`,
      method: "GET",
      query: query,
      ...params,
    });
  /**
   * No description
   *
   * @tags resource-api-controller
   * @name GetAllStudentResourceByStudentIdUsingGet
   * @summary getAllStudentResourceByStudentId
   * @request GET:/dashboard/resource/getAllStudentResourceByStudentId
   */
  getAllStudentResourceByStudentIdUsingGet = (
    query: { page?: number; size?: number; sort?: string[]; studentId: number },
    params: RequestParams = {},
  ) =>
    this.request<ResBaseTPageV2StudentResourceItem, void>({
      path: `/dashboard/resource/getAllStudentResourceByStudentId`,
      method: "GET",
      query: query,
      ...params,
    });
  /**
   * No description
   *
   * @tags resource-api-controller
   * @name GetAttachmentListUsingGet
   * @summary getAttachmentList
   * @request GET:/dashboard/resource/getAttachmentList
   */
  getAttachmentListUsingGet = (query: { resourceId: number }, params: RequestParams = {}) =>
    this.request<ResBaseTListStudentResourceAttachment, void>({
      path: `/dashboard/resource/getAttachmentList`,
      method: "GET",
      query: query,
      ...params,
    });
  /**
   * No description
   *
   * @tags resource-api-controller
   * @name GetGenieListUsingGet
   * @summary getGenieList
   * @request GET:/dashboard/resource/getGenieList
   */
  getGenieListUsingGet = (
    query: {
      creator: number;
      genieType?:
        | "assessment"
        | "video"
        | "audio"
        | "pdf"
        | "image"
        | "word"
        | "powerPoint"
        | "excel"
        | "link"
        | "youtube";
      page?: number;
      size?: number;
      sort?: string[];
    },
    params: RequestParams = {},
  ) =>
    this.request<ResBaseTPageGenie, void>({
      path: `/dashboard/resource/getGenieList`,
      method: "GET",
      query: query,
      ...params,
    });
  /**
   * No description
   *
   * @tags resource-api-controller
   * @name GetResourceRatingStatisticsUsingGet
   * @summary getResourceRatingStatistics
   * @request GET:/dashboard/resource/getResourceRatingStatistics
   */
  getResourceRatingStatisticsUsingGet = (query: { resourceId: number }, params: RequestParams = {}) =>
    this.request<ResGetResourceRatingStatistics, void>({
      path: `/dashboard/resource/getResourceRatingStatistics`,
      method: "GET",
      query: query,
      ...params,
    });
  /**
   * No description
   *
   * @tags resource-api-controller
   * @name GetStudentResourceByIdUsingGet
   * @summary getStudentResourceById
   * @request GET:/dashboard/resource/getStudentResourceById
   */
  getStudentResourceByIdUsingGet = (query: { resourceId: number }, params: RequestParams = {}) =>
    this.request<ResBaseTStudentResourceDetail, void>({
      path: `/dashboard/resource/getStudentResourceById`,
      method: "GET",
      query: query,
      ...params,
    });
  /**
   * No description
   *
   * @tags resource-api-controller
   * @name RemoveStudentResourceByIdUsingPost
   * @summary removeStudentResourceById
   * @request POST:/dashboard/resource/removeStudentResourceById
   */
  removeStudentResourceByIdUsingPost = (query: { id: number }, params: RequestParams = {}) =>
    this.request<ResBase, void>({
      path: `/dashboard/resource/removeStudentResourceById`,
      method: "POST",
      query: query,
      type: ContentType.Json,
      ...params,
    });
  /**
   * No description
   *
   * @tags school-api-controller
   * @name GetAllSchoolByPageUsingGet
   * @summary getAllSchoolByPage
   * @request GET:/dashboard/school/getAllSchoolByPage
   */
  getAllSchoolByPageUsingGet = (
    query: { keyword: string; page?: number; size?: number; sort?: string[] },
    params: RequestParams = {},
  ) =>
    this.request<ResBaseTPageSchoolData, void>({
      path: `/dashboard/school/getAllSchoolByPage`,
      method: "GET",
      query: query,
      ...params,
    });
  /**
   * No description
   *
   * @tags school-api-controller
   * @name GetClassFilesUsingGet
   * @summary getClassFiles
   * @request GET:/dashboard/school/getClassFiles
   */
  getClassFilesUsingGet = (
    query: { classId: number; page?: number; size?: number; sort?: string[] },
    params: RequestParams = {},
  ) =>
    this.request<ResBaseTPageFileClass, void>({
      path: `/dashboard/school/getClassFiles`,
      method: "GET",
      query: query,
      ...params,
    });
  /**
   * No description
   *
   * @tags school-api-controller
   * @name GetSchoolAnalysisByIdUsingGet
   * @summary getSchoolAnalysisById
   * @request GET:/dashboard/school/getSchoolAnalysisById
   */
  getSchoolAnalysisByIdUsingGet = (query: { schoolId: number }, params: RequestParams = {}) =>
    this.request<ResBaseTSchoolAnalysis, void>({
      path: `/dashboard/school/getSchoolAnalysisById`,
      method: "GET",
      query: query,
      ...params,
    });
  /**
   * No description
   *
   * @tags school-api-controller
   * @name GetSchoolByIdUsingGet
   * @summary getSchoolById
   * @request GET:/dashboard/school/getSchoolById
   */
  getSchoolByIdUsingGet = (query: { schoolId: number }, params: RequestParams = {}) =>
    this.request<ResBaseTSchool, void>({
      path: `/dashboard/school/getSchoolById`,
      method: "GET",
      query: query,
      ...params,
    });
  /**
   * No description
   *
   * @tags school-api-controller
   * @name GetSchoolFilesUsingGet
   * @summary getSchoolFiles
   * @request GET:/dashboard/school/getSchoolFiles
   */
  getSchoolFilesUsingGet = (
    query: { page?: number; schoolId: number; size?: number; sort?: string[] },
    params: RequestParams = {},
  ) =>
    this.request<ResBaseTPageFileSchool, void>({
      path: `/dashboard/school/getSchoolFiles`,
      method: "GET",
      query: query,
      ...params,
    });
  /**
   * No description
   *
   * @tags school-api-controller
   * @name GetSchoolOwnerByIdUsingGet
   * @summary getSchoolOwnerById
   * @request GET:/dashboard/school/getSchoolOwnerById
   */
  getSchoolOwnerByIdUsingGet = (query: { ownerId: number }, params: RequestParams = {}) =>
    this.request<ResBaseTSchoolOwnerData, void>({
      path: `/dashboard/school/getSchoolOwnerById`,
      method: "GET",
      query: query,
      ...params,
    });
  /**
   * No description
   *
   * @tags school-api-controller
   * @name GetSchoolOwnersUsingGet
   * @summary getSchoolOwners
   * @request GET:/dashboard/school/getSchoolOwners
   */
  getSchoolOwnersUsingGet = (
    query: { keyword: string; page?: number; size?: number; sort?: string[] },
    params: RequestParams = {},
  ) =>
    this.request<ResBaseTPageSchoolOwnerData, void>({
      path: `/dashboard/school/getSchoolOwners`,
      method: "GET",
      query: query,
      ...params,
    });
  /**
   * No description
   *
   * @tags school-api-controller
   * @name GetTeachersBySchoolIdUsingGet
   * @summary getTeachersBySchoolId
   * @request GET:/dashboard/school/getTeachersBySchoolId
   */
  getTeachersBySchoolIdUsingGet = (
    query: { page?: number; schoolId: number; size?: number; sort?: string[] },
    params: RequestParams = {},
  ) =>
    this.request<ResBaseTPageUser, void>({
      path: `/dashboard/school/getTeachersBySchoolId`,
      method: "GET",
      query: query,
      ...params,
    });
  /**
   * No description
   *
   * @tags school-api-controller
   * @name RemoveSchoolBySchoolIdUsingPost
   * @summary removeSchoolBySchoolId
   * @request POST:/dashboard/school/removeSchoolBySchoolId
   */
  removeSchoolBySchoolIdUsingPost = (query: { schoolId: number }, params: RequestParams = {}) =>
    this.request<ResBase, void>({
      path: `/dashboard/school/removeSchoolBySchoolId`,
      method: "POST",
      query: query,
      type: ContentType.Json,
      ...params,
    });
  /**
   * No description
   *
   * @tags user-api-controller
   * @name BlockUserUsingPost
   * @summary blockUser
   * @request POST:/dashboard/user/blockUser
   */
  blockUserUsingPost = (query: { userId: number }, params: RequestParams = {}) =>
    this.request<ResBase, void>({
      path: `/dashboard/user/blockUser`,
      method: "POST",
      query: query,
      type: ContentType.Json,
      ...params,
    });
  /**
   * No description
   *
   * @tags user-api-controller
   * @name GetActiveStudentTodayUsingPost
   * @summary getActiveStudentToday
   * @request POST:/dashboard/user/getActiveStudentToday
   */
  getActiveStudentTodayUsingPost = (
    query?: { page?: number; size?: number; sort?: string[] },
    params: RequestParams = {},
  ) =>
    this.request<ResBaseTPageUser, void>({
      path: `/dashboard/user/getActiveStudentToday`,
      method: "POST",
      query: query,
      type: ContentType.Json,
      ...params,
    });
  /**
   * No description
   *
   * @tags user-api-controller
   * @name GetActiveTeacherTodayUsingPost
   * @summary getActiveTeacherToday
   * @request POST:/dashboard/user/getActiveTeacherToday
   */
  getActiveTeacherTodayUsingPost = (
    query?: { page?: number; size?: number; sort?: string[] },
    params: RequestParams = {},
  ) =>
    this.request<ResBaseTPageUser, void>({
      path: `/dashboard/user/getActiveTeacherToday`,
      method: "POST",
      query: query,
      type: ContentType.Json,
      ...params,
    });
  /**
   * No description
   *
   * @tags user-api-controller
   * @name GetSignUpStudentTodayUsingPost
   * @summary getSignUpStudentToday
   * @request POST:/dashboard/user/getSignUpStudentToday
   */
  getSignUpStudentTodayUsingPost = (
    query?: { page?: number; size?: number; sort?: string[] },
    params: RequestParams = {},
  ) =>
    this.request<ResBaseTPageUser, void>({
      path: `/dashboard/user/getSignUpStudentToday`,
      method: "POST",
      query: query,
      type: ContentType.Json,
      ...params,
    });
  /**
   * No description
   *
   * @tags user-api-controller
   * @name GetSignUpStudentWeekUsingPost
   * @summary getSignUpStudentWeek
   * @request POST:/dashboard/user/getSignUpStudentWeek
   */
  getSignUpStudentWeekUsingPost = (
    query?: { page?: number; size?: number; sort?: string[] },
    params: RequestParams = {},
  ) =>
    this.request<ResBaseTPageUser, void>({
      path: `/dashboard/user/getSignUpStudentWeek`,
      method: "POST",
      query: query,
      type: ContentType.Json,
      ...params,
    });
  /**
   * No description
   *
   * @tags user-api-controller
   * @name GetSignUpTeacherTodayUsingPost
   * @summary getSignUpTeacherToday
   * @request POST:/dashboard/user/getSignUpTeacherToday
   */
  getSignUpTeacherTodayUsingPost = (
    query?: { page?: number; size?: number; sort?: string[] },
    params: RequestParams = {},
  ) =>
    this.request<ResBaseTPageUser, void>({
      path: `/dashboard/user/getSignUpTeacherToday`,
      method: "POST",
      query: query,
      type: ContentType.Json,
      ...params,
    });
  /**
   * No description
   *
   * @tags user-api-controller
   * @name GetSignUpTeacherWeekUsingPost
   * @summary getSignUpTeacherWeek
   * @request POST:/dashboard/user/getSignUpTeacherWeek
   */
  getSignUpTeacherWeekUsingPost = (
    query?: { page?: number; size?: number; sort?: string[] },
    params: RequestParams = {},
  ) =>
    this.request<ResBaseTPageUser, void>({
      path: `/dashboard/user/getSignUpTeacherWeek`,
      method: "POST",
      query: query,
      type: ContentType.Json,
      ...params,
    });
  /**
   * No description
   *
   * @tags user-api-controller
   * @name GetStudentResourceCompletionUsingPost
   * @summary getStudentResourceCompletion
   * @request POST:/dashboard/user/getStudentResourceCompletion
   */
  getStudentResourceCompletionUsingPost = (
    query: { keyword: string; page?: number; size?: number; sort?: string[] },
    params: RequestParams = {},
  ) =>
    this.request<ResBaseTPageStatisticUserData, void>({
      path: `/dashboard/user/getStudentResourceCompletion`,
      method: "POST",
      query: query,
      type: ContentType.Json,
      ...params,
    });
  /**
   * No description
   *
   * @tags user-api-controller
   * @name GetTeacherResourceRateUsingPost
   * @summary getTeacherResourceRate
   * @request POST:/dashboard/user/getTeacherResourceRate
   */
  getTeacherResourceRateUsingPost = (
    query: { keyword: string; page?: number; size?: number; sort?: string[] },
    params: RequestParams = {},
  ) =>
    this.request<ResBaseTPageStatisticUserData, void>({
      path: `/dashboard/user/getTeacherResourceRate`,
      method: "POST",
      query: query,
      type: ContentType.Json,
      ...params,
    });
  /**
   * No description
   *
   * @tags user-api-controller
   * @name GetUserProfileUsingGet
   * @summary getUserProfile
   * @request GET:/dashboard/user/getUserProfile
   */
  getUserProfileUsingGet = (query: { userId: number }, params: RequestParams = {}) =>
    this.request<ResBaseTUser, void>({
      path: `/dashboard/user/getUserProfile`,
      method: "GET",
      query: query,
      ...params,
    });
  /**
   * No description
   *
   * @tags user-api-controller
   * @name UnBlockUserUsingPost
   * @summary unBlockUser
   * @request POST:/dashboard/user/unBlockUser
   */
  unBlockUserUsingPost = (query: { userId: number }, params: RequestParams = {}) =>
    this.request<ResBase, void>({
      path: `/dashboard/user/unBlockUser`,
      method: "POST",
      query: query,
      type: ContentType.Json,
      ...params,
    });
  /**
   * No description
   *
   * @tags user-api-controller
   * @name GetUserListUsingGet
   * @summary getUserList
   * @request GET:/dashboard/user/users
   */
  getUserListUsingGet = (
    query: { keyword: string; page?: number; size?: number; sort?: string[] },
    params: RequestParams = {},
  ) =>
    this.request<ResBaseTPageUser, void>({
      path: `/dashboard/user/users`,
      method: "GET",
      query: query,
      ...params,
    });
}
