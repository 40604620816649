<template>
<div class="row">
    <div class="col-12">
        <div class="card">
            <!-- Card header -->
            <div class="card-header pb-0">
                <div class="d-lg-flex">
                <div>
                    <h5 class="mb-0">All School Owner</h5>
                    <p class="text-sm mb-0">
                    School Owner list
                    </p>
                </div>
                </div>
            </div>
            <div class="card-body px-0 pb-0">
                <div class="table-responsive">
                    <div class="dataTable-wrapper dataTable-loading no-footer sortable searchable fixed-columns">
                        <div class="dataTable-top">
                            <div class="dataTable-dropdown">
                                <label><select class="dataTable-selector" v-model="paginationState.size">
                                    <option :value="5">5</option>
                                    <option :value="10">10</option>
                                    <option :value="15">15</option>
                                    <option :value="20">20</option>
                                    <option :value="25">25</option></select>
                                     entries per page</label>
                            </div>
                            <div class="dataTable-dropdown mx-4">
                                <label>sort by <select class="dataTable-selector" v-model="paginationState.sort">
                                    <option value="duration">Video total duration</option>
                                    <option value="playedDuration">Video watched duration</option>
                                    <option value="usedStorage">Used storage</option>
                                    <option value="storage">Total storage</option>
                                    </select>
                                    <select class="dataTable-selector mx-2" v-model="paginationState.direction">
                                    <option value="ASC">ASC</option>
                                    <option value="DESC">DESC</option>
                                    </select>
                                     </label>
                            </div>
                            <div class="dataTable-search">
                                <input class="dataTable-input mx-4" placeholder="Search..." type="text" v-model="paginationState.keyword">
                                <button type="button" class="btn btn-outline-primary btn-sm mb-0" @click="search">
                                Search
                                </button>
                            </div>
                        </div>
                        <div class="dataTable-container">
                            <table class="table table-flush" id="school-list">
                                <thead class="thead-light">
                                    <tr>
                                    <th>User Name</th>
                                    <th>account Name</th>
                                    <th>Storage (Used / Total)</th>
                                    <th>Video duration (watched / Total)</th>
                                    <th>AWS Cost</th>
                                    <th>Actual Storage</th>
                                    <th>Action</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    <tr v-for="item in list"  v-bind:key="item">
                                    <td>
                                        <div class="d-flex">
                                        <img v-if="item.user?.avatar" :src="item.user?.avatar + '?size=80x80'" class="avatar rounded-circle">
                                        <span v-else-if="!item.user?.avatar" class="bg-primary rounded-circle avatar text-uppercase"><span class="text-light">{{getWord(item.user?.fullNameOrAccountName)}}</span></span>
                                        <h6 class="ms-3 my-auto">{{item.user?.fullNameOrAccountName}}</h6>
                                        </div>
                                    </td>
                                    <td class="text-sm">{{item.user?.accountName}}</td>
                                    <td class="text-sm">{{formatSize(item.usedStorage) + " / " + formatSize(item.storage)}}</td>
                                    <td class="text-sm">{{formatToHours(item.playedDuration) + " / " + formatToHours(item.duration)}}</td>
                                    <td class="text-sm" :title="(item.playedDuration * 0.35 * 0.085 / 3600)">{{item.playedDuration != 0 ? (item.playedDuration * 0.35 * 0.085 / 3600).toFixed(2) + " USD" : "0"}}</td>
                                    <td class="text-sm" :title="(item.playedDuration * 0.35 / 3600)">{{ item.playedDuration != 0 ? (item.playedDuration * 0.35 / 3600).toFixed(2) + " GB" : "0"}}</td>
                                    <td class="text-sm">
                                        <a :href="'#/schoolOwner/' + item.ownerId" class="mx-3" data-bs-toggle="tooltip" data-bs-original-title="Preview school">
                                        <i class="ni ni-chart-bar-32"></i>
                                        </a>
                                    </td>
                                    </tr>
                                    <tr v-if="total == 0">
                                        <td class="dataTables-empty" colspan="7">No entries found</td>
                                    </tr>
                                </tbody>
                            </table>
                        </div>
                        <div class="dataTable-bottom" v-if="total > paginationState.size">
                        <div class="dataTable-info">
                            Showing {{(paginationState.page - 1) * paginationState.size + 1}} to {{paginationState.page * paginationState.size > total ? total : paginationState.page * paginationState.size}} of {{total}} entries
                        </div>
                        <nav class="dataTable-pagination">

                        <b-pagination
                        v-model="paginationState.page"
                        :total-rows="total"
                        :per-page="parseInt(paginationState.size)"
                        aria-controls="my-table"
                    ></b-pagination>
                        </nav>
                    </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>
</template>

<script>
import { Dashboard } from '@/apiClient/Dashboard'
import { onMounted,ref,getCurrentInstance,watch,reactive } from 'vue';
import bus from '@/plugins/bus';
let requestHeader;
export default {
    name:"schoolOwnerList",
    data() {
        return {
          deleteId:0
        }
    },
    methods:{
      getWord(f){
        f = f ? f : "";
        let arr = f.split(' ');
        let first = arr ? arr[0].slice(0,1) : "";
        let last = arr[1] ? arr[1].slice(0,1) : "";
        return first + last;
      },
      formatToHours(seconds){
        if (isNaN(seconds)) {
            return '';
        }
        let result = parseInt(seconds)
        let symbols = ['s', 'm', 'h'];
        let h = result / 3600;
        if (h >= 1) {
           return h.toFixed(2) + ' ' + symbols[2]
        }
        let m = Math.floor((result / 60 % 60));
        let s = Math.floor((result % 60));
        let res = '';
        if(m !== 0) res += `${m}${symbols[1]} `;
        res += `${s}${symbols[0]}`;
        return res;
      },
       formatSeconds(value) {
        let result = parseInt(value)
        //let d = Math.floor(result / (3600 * 24));
        let h = Math.floor(result / 3600);
        let m = Math.floor((result / 60 % 60));
        let s = Math.floor((result % 60));
        let res = '';
        //if(d !== 0) res += `${d}d `;
        if(h !== 0) res += `${h}h `;
        if(m !== 0) res += `${m}min `;
        res += `${s}s`;
        return res;
      },
      formatSize(bytes) {
        if (isNaN(bytes)) {
            return '';
        }
        let symbols = ['B', 'KB', 'MB', 'GB', 'TB', 'PB', 'EB', 'ZB', 'YB'];
        let exp = Math.floor(Math.log(bytes)/Math.log(2));
        if (exp < 1) {
            exp = 0;
        }
        let i = Math.floor(exp / 10);
        bytes = bytes / Math.pow(2, 10 * i);

        if (bytes.toString().length > bytes.toFixed(2).toString().length) {
            bytes = bytes.toFixed(2);
        }
        return bytes + ' ' + symbols[i];
      },
      reload(){
        this.$router.go()
      }
    },
    setup(){
        const list=ref();
        const total = ref(0);
        const paginationState = reactive({
            size: 10,
            page: 1,
            keyword:"",
            sort:"usedStorage",
            direction:"DESC"
        });
        requestHeader = getCurrentInstance().appContext.config.globalProperties.RequestParams
        const search = () =>{
            if(paginationState.page == 1){
                call();
            }
            else{
                paginationState.page = 1;
            }
        }
        const call = () =>{
            bus.emit('load',true);
            let api = new Dashboard();
            let params = { keyword:paginationState.keyword,page:paginationState.page - 1,size:paginationState.size,sort:paginationState.sort + ',' + paginationState.direction };
            api.getSchoolOwnersUsingGet(params,requestHeader).then((response) => {
                if(response.status == 200 && response.data != null){
                    if(response.data.code == 0){
                        list.value= response.data?.data?.content;
                        total.value = parseInt(response.data?.data.totalElements)
                    }
                    else{
                        bus.emit('error', response.data.message);
                    }
                }
            }).catch((error) => { bus.emit('error', error); }).finally(()=>{ bus.emit('load', false); });
        }
        onMounted(()=>{
            call();
        });
        watch(()=> paginationState.page,()=>{
            call();
        },
        {
            deep:true
        });
         watch(()=> [paginationState.size,paginationState.sort,paginationState.direction],()=>{
            if(paginationState.page == 1)
                call();
            else
                paginationState.page = 1;
        },
        {
            deep:true
        });
        return{
            paginationState,
            list,
            total,
            search
        }
    }
}
</script>