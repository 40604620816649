import { createStore } from 'vuex'

export default createStore({
  state: {
    isLogin: false,
  },

  getters: {
    isLogin: state => state.isLogin,
  },

  mutations: {
    userStatus(state, flag) {
      state.isLogin = flag
    },
  },

  actions: {
    setUser({commit}, flag) {
      commit("userStatus", flag)
    },
  }
})
